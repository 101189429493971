<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    :expand-on-hover="$vuetify.breakpoint.smAndUp"
    :permanent="$vuetify.breakpoint.smAndUp"
    :mini-variant.sync="miniNavDrawer"
    clipped
  >
    <v-divider />
    <v-list expand nav>
      <template v-for="navigationItem in navigationItems">
        <v-list-item
          v-if="navigationItem.isVisible"
          :key="navigationItem.page"
          :exact="true"
          :to="navigationItem.navigateTo"
          :class="getCssClasses(navigationItem.page)"
        >
          <v-list-item-action>
            <v-icon>{{ navigationItem.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ navigationItem.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-divider class="mb-4" />
      <v-list-item
        v-if="isSubscribedToMeishi"
        :href="linkToMeishi"
        target="_blank"
      >
        <v-list-item-action>
          <v-icon>mdi-contacts</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('navDrawer.openMeishi') }}
            <v-icon small class="ml-2">mdi-open-in-new</v-icon>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <template #append>
      <v-row justify="center">
        <v-col cols="8">
          <v-btn
            v-if="miniNavDrawer === false"
            text
            small
            :to="{ name: 'releases' }"
            class="text-none"
          >
            {{ $t('common.appName') }} v{{ $appVersion }}
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      miniNavDrawer: this.$vuetify.breakpoint.smAndUp,
    };
  },

  computed: {
    ...mapGetters(['user', 'currentTenant']),

    drawer: {
      get() {
        return this.$store.getters.drawer;
      },
      set(value) {
        this.$store.commit('setDrawer', value);
      },
    },

    navigationItems() {
      const navigationItems = [
        {
          icon: 'mdi-calendar',
          text: this.$t('navDrawer.calendar'),
          navigateTo: { name: 'week', params: this.$route.params },
          page: 'calendar',
          isVisible: true,
        },
        {
          icon: 'mdi-account-multiple-check',
          text: this.$t('navDrawer.attendance'),
          navigateTo: { name: 'attendance', params: this.$route.params },
          page: 'attendance',
          isVisible: true,
        },
        {
          icon: 'mdi-seat',
          text: this.$t('navDrawer.facilities'),
          navigateTo: {
            name: 'facilities-week',
            params: this.$route.params,
          },
          page: 'facilities',
          isVisible: true,
        },
        {
          icon: 'mdi-account-clock',
          text: this.$t('navDrawer.meetings'),
          navigateTo: { name: 'meeting-scheduler' },
          page: 'meeting-scheduler',
          isVisible: true,
        },
        {
          icon: 'mdi-bullhorn',
          text: this.$t('navDrawer.informations'),
          page: 'informations',
          navigateTo: { name: 'informations' },
          isVisible: true,
        },
        {
          icon: 'mdi-account-group',
          text: this.$t('navDrawer.manageTeams'),
          navigateTo: { name: 'manage-teams' },
          page: 'manage-teams',
          isVisible: true,
        },
        {
          icon: 'mdi-cog',
          text: this.$t('navDrawer.userPreferences'),
          navigateTo: { name: 'preferences' },
          page: 'preferences',
          isVisible: true,
        },
        {
          icon: 'mdi-account-cog',
          text: this.$t('navDrawer.adminSettings'),
          navigateTo: { name: 'admin-settings' },
          page: 'admin-settings',
          isVisible: !!this.user.isAdmin,
        },
      ];
      return navigationItems;
    },

    linkToMeishi() {
      switch (process.env.VUE_APP_BUILD_FOR) {
        case 'staging':
          return `${window.location.origin}/staging-meishi`;
        case 'production':
          return `${window.location.origin}/meishi`;
        default:
          return `${window.location.origin}/meishi`;
      }
    },

    isSubscribedToMeishi() {
      return this.currentTenant.tenant_user.is_user_subscribed_to_meishi;
    },
  },

  methods: {
    getCssClasses(page) {
      return this.$route.meta.page === page
        ? ['primary--text', 'v-list-item--active']
        : [];
    },
  },
};
</script>
