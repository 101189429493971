<template>
  <v-autocomplete
    v-model="selectedItems"
    :search-input.sync="query"
    :items="items"
    :label="label"
    multiple
    return-object
    prepend-icon="mdi-account-multiple"
    dense
    :hide-details="hideDetails"
    :outlined="outlined"
    :rules="rules"
    :filter="filterItems"
    :menu-props="{
      maxHeight: $vuetify.breakpoint.smAndDown ? 150 : 304,
      offsetY: true,
    }"
  >
    <template #selection="{ item }">
      <v-chip
        class="my-1"
        small
        close
        @click:close="removeUserFromSelectedItems(item)"
      >
        {{ item.name }}
      </v-chip>
    </template>

    <template #item="{ item }">
      <v-list-item
        :input-value="isItemSelected(item)"
        color="primary"
        @click.stop="
          isItemSelected(item) ? unselectItem(item) : selectItem(item)
        "
      >
        <template v-if="item.type === 'group'">
          <v-list-item-content>
            <v-list-item-title class="text-subtitle-1">
              {{ item.name }}
            </v-list-item-title>
          </v-list-item-content>

          <span class="text-subtitle-2">
            {{
              $tc('meetingScheduler.membersCount', item.users.length, [
                item.users.length,
              ])
            }}
          </span>

          <v-simple-checkbox
            :value="isItemSelected(item)"
            :ripple="false"
            @click.stop="
              isItemSelected(item) ? unselectItem(item) : selectItem(item)
            "
          />
        </template>

        <template v-else>
          <v-list-item-content class="pl-4">
            <v-list-item-title>
              {{ item.name }}
            </v-list-item-title>
          </v-list-item-content>

          <v-simple-checkbox
            :value="isItemSelected(item)"
            :ripple="false"
            @click.stop="
              isItemSelected(item) ? unselectItem(item) : selectItem(item)
            "
          />
        </template>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import { toAscii } from 'japanese-string-utils';

export default {
  props: {
    value: {
      type: Array,
      required: true,
    },

    items: {
      type: Array,
      required: true,
    },

    label: {
      type: String,
      required: true,
    },

    rules: {
      type: Array,
      default: () => [],
    },

    // Assumed members are those members
    // which appear as selected in dropdown
    // but do not display as chips
    // These also do not appear in selectedItems
    assumedMembers: {
      type: Array,
      default: () => [],
    },

    outlined: {
      type: Boolean,
      default: false,
    },

    hideDetails: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      query: '',
    };
  },

  computed: {
    selectedItems: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },

  methods: {
    filterItems(item, query) {
      if (item.type === 'user') {
        return doesUserOrTheirGroupMatchesQuery(item, query);
      }
      if (item.type === 'group') {
        return doesGroupMatchesQuery(item, query);
      }
      return false;
    },

    isItemSelected(item) {
      if (this.isAssumedMember(item)) {
        return true;
      }
      if (item.type === 'group') {
        return this.areAllGroupUsersSelectedItems(item);
      }
      return this.isUserASelectedItem(item);
    },

    isAssumedMember(item) {
      if (item.type === 'user') {
        return this.assumedMembers.some(
          (assumedMember) => assumedMember.id === item.id,
        );
      }
      return false;
    },

    areAllGroupUsersSelectedItems(item) {
      return item.users.every(this.isUserASelectedItem);
    },

    isUserASelectedItem(user) {
      return this.selectedItems.some((item) => item.id === user.id);
    },

    selectItem(item) {
      if (item.type === 'group') {
        this.addUsersToSelectedItems(item.users);
      } else {
        this.addUserToSelectedItem(item);
      }
      this.clearQuery(item);
    },

    clearQuery(item) {
      if (item.type === 'group' || doesUserMatchesQuery(item, this.query)) {
        this.query = '';
      }
    },

    addUsersToSelectedItems(users) {
      users.forEach(this.addUserToSelectedItem);
    },

    addUserToSelectedItem(user) {
      if (this.isUserASelectedItem(user)) {
        return;
      }

      const userItem = this.items.find(
        (item) => item.id === user.id && item.type === 'user',
      );

      if (this.isAssumedMember(userItem)) {
        return;
      }

      this.selectedItems.push(userItem);
    },

    unselectItem(item) {
      if (item.type === 'group') {
        this.removeUsersFromSelectedItems(item.users);
      } else {
        this.removeUserFromSelectedItems(item);
      }
      this.clearQuery(item);
    },

    removeUsersFromSelectedItems(users) {
      let { selectedItems } = this;
      users.forEach((user) => {
        selectedItems = removeUserFromSelectedItemsFunctional(
          user,
          selectedItems,
        );
      });
      this.selectedItems = selectedItems;
    },

    removeUserFromSelectedItems(item) {
      this.selectedItems = removeUserFromSelectedItemsFunctional(
        item,
        this.selectedItems,
      );
    },
  },
};

function doesUserOrTheirGroupMatchesQuery(user, query) {
  query = toAscii(query).toLowerCase();

  const groupName = toAscii(user.groupName).toLowerCase();
  if (groupName.indexOf(query) > -1) {
    return true;
  }

  // Following check helps avoid duplicate names in dropdown
  if (user.isMainDepartment === true) {
    return doesUserMatchesQuery(user, query);
  }
  return false;
}

function doesUserMatchesQuery(user, query) {
  query = toAscii(query).toLowerCase();
  const email = user.email.toLowerCase();
  const userName = toAscii(user.name).toLowerCase();
  if (userName.indexOf(query) > -1 || email.indexOf(query) > -1) {
    return true;
  }
  return false;
}

function doesGroupMatchesQuery(group, query) {
  query = toAscii(query).toLowerCase();
  const groupName = toAscii(group.name).toLowerCase();
  return groupName.indexOf(query) > -1;
}

function removeUserFromSelectedItemsFunctional(user, selectedItems) {
  return selectedItems.filter(
    (selectedItem) => !isSelectedItemTheSameUser(selectedItem, user),
  );
}

function isSelectedItemTheSameUser(selectedItem, user) {
  return selectedItem.type === 'user' && selectedItem.id === user.id;
}
</script>
