<template>
  <v-list-item-subtitle>
    <span class="text-wrap">
      {{ notificationMessage }}
    </span>
  </v-list-item-subtitle>
</template>

<script>
export default {
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },

  computed: {
    isEventAllDay() {
      return this.notification.data.notification.event.all_day === 1;
    },

    dateMessage() {
      const date = App.helpers.getDateObject(this.notification.data.notification.event.start);
      if (this.isEventAllDay) {
        return (
          this.$i18n.d(date, 'allDayEvent') + this.$t('notifications.allDay')
        );
      }
      return this.$i18n.d(date, 'eventTime');
    },

    notificationMessage() {
      switch (this.notification.data.notification.type) {
        case 'Invited':
          return this.$t('notifications.invite', {
            name: this.notification.data.notification.from,
            date: this.dateMessage,
          });
        case 'Response':
          return this.notificationMessageDisplay(
            this.notification.data.notification.event.decision,
            this.notification.data.notification.from,
            this.dateMessage,
          );
        case 'Edited':
          return this.$t('notifications.editedMeeting', {
            name: this.notification.data.notification.from,
            date: this.dateMessage,
          });
        case 'DeletedMeeting':
          return this.$t('notifications.deletedMeeting', {
            name: this.notification.data.notification.from,
            date: this.dateMessage,
          });
        case 'BookingDeleted':
          return this.$t('notifications.deletedBooking', {
            name: this.notification.data.notification.from,
            date: this.dateMessage,
          });
        default:
          return this.$t(`notifications.${this.notification.data.notification.type}`, {
            name: this.notification.data.notification.from,
            date: this.dateMessage,
          });
      }
    },
  },

  methods: {
    notificationMessageDisplay(decision, name, date) {
      let message = 'notifications.responseUndecided';
      if (decision === true || decision === 'going') {
        message = 'notifications.responseAccepted';
      }
      if (decision === false || decision === 'not_going') {
        message = 'notifications.responseRejected';
      }
      return this.$t(message, { name, date });
    },
  },
};
</script>
