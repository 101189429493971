<template>
  <v-autocomplete
    :value="selectedGroupId"
    hide-details
    :items="choices"
    :placeholder="$t('deptTeamSelector.selectDepartmentOrTeam')"
    dense
    align="center"
    prepend-inner-icon="mdi-view-list"
    item-text="name"
    item-value="uniqueId"
    return-object
    outlined
    :style="style"
    :filter="customFilter"
    :menu-props="{ maxHeight: 500 }"
    @input="updateSelectedGroup"
  >
    <template #item="data">
      <v-hover v-slot="{ hover }">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title v-text="data.item.name" />
          </v-list-item-content>

          <v-list-item-icon>
            <v-icon
              v-if="data.item.isStarred"
              outlined
              @click.native.stop="unstarGroup(data.item)"
            >
              mdi-star
            </v-icon>
            <v-icon
              v-if="hover && !data.item.isStarred"
              @click.native.stop="starGroup(data.item)"
            >
              mdi-star-outline
            </v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-hover>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapGetters } from 'vuex';
import { toAscii } from 'japanese-string-utils';
import { sendGoogleAnalyticsEvent } from '../plugins/vue-gtag';

export default {
  props: {
    isNullDepartmentShown: {
      type: Boolean,
      default: true,
    },

    width: {
      type: String,
      default: '350px',
    },
  },
  computed: {
    ...mapGetters('groups', [
      'selectedGroup',
      'starredGroups',
      'teams',
      'departments',
      'getGroupByTypeAndId',
    ]),
    selectedGroupId() {
      if (this.selectedGroup === null) {
        return null;
      }
      return `${this.selectedGroup.id}-${this.selectedGroup.type}`;
    },
    style() {
      return `width:${this.width};`;
    },
    choices() {
      let choices = [];
      choices = choices.concat(
        this.createSectionInChoices(
          this.starredGroups,
          this.$t('departmentTeamSelectionModal.starredGroups'),
          'starred',
        ),
      );
      choices = choices.concat(
        this.createSectionInChoices(
          this.teams,
          this.$t('departmentTeamSelectionModal.teams'),
          'team',
        ),
      );
      choices = choices.concat(
        this.createSectionInChoices(
          this.departments,
          this.$t('departmentTeamSelectionModal.departments'),
          'department',
        ),
      );

      if (this.isNullDepartmentShown === false) {
        choices = choices.filter((choice) => choice.id !== null);
      }
      return choices;
    },
  },

  methods: {
    starGroup(group) {
      this.$store.dispatch('groups/markGroupAsStarred', group);
    },
    unstarGroup(group) {
      this.$store.dispatch('groups/markGroupAsNotStarred', group);
    },
    createSectionInChoices(initialGroups, header, section) {
      const groups = [{ header }];
      return groups.concat(
        initialGroups.map((group) => ({
          ...group,
          ...{ uniqueId: `${group.id}-${section}` },
        })),
      );
    },
    customFilter(item, queryText, itemText) {
      const itemTextHalfWidth = toAscii(itemText);
      const queryTextHalfWidth = toAscii(queryText);
      if (!('id' in item)) {
        return true;
      }
      return (
        itemTextHalfWidth
          .toLowerCase()
          .indexOf(queryTextHalfWidth.toLowerCase()) > -1
      );
    },
    updateSelectedGroup(selectedGroup) {
      sendGoogleAnalyticsEvent('updateSelectedGroup', selectedGroup.name);
      const group = this.getGroupByTypeAndId(
        selectedGroup.type,
        selectedGroup.id,
      );
      this.$store.dispatch('groups/setSelectedGroup', group);
      this.$emit('update-selected-group', selectedGroup.type, selectedGroup.id);
    },
  },
};
</script>
