<template>
  <v-col>
    <v-dialog v-model="isDialogOpen" scrollable>
      <v-card>
        <v-card-title>
          <v-text-field
            v-model="searchText"
            :label="$t('departmentTeamSelectionModal.search')"
            dense
            outlined
            hide-details
          />
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-0" style="height: 500px">
          <v-list class="pt-0">
            <v-list-item-group>
              <v-container class="py-0">
                <v-row v-for="(group, index) in choices" :key="index">
                  <v-divider
                    v-if="group.divider === true"
                    :class="group.class"
                  />
                  <v-col
                    v-else-if="group.header"
                    class="px-6 text-caption font-weight-light my-1"
                  >
                    {{ group.header }}
                  </v-col>
                  <v-list-item
                    v-else-if="checkSearchTextWithGroup(searchText, group.name)"
                    :color="
                      selectedGroup !== null &&
                      selectedGroup.id === group.id &&
                      selectedGroup.type === group.type
                        ? 'primary darken-2'
                        : ''
                    "
                    class="px-2"
                    @click="updateSelectedGroup(group)"
                  >
                    <v-list-item-content class="px-6">
                      {{ group.name }}
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon
                        v-if="group.isStarred"
                        outlined
                        @click.native.stop="unstarGroup(group)"
                      >
                        mdi-star
                      </v-icon>
                      <v-icon
                        v-if="!group.isStarred"
                        @click.native.stop="starGroup(group)"
                      >
                        mdi-star-outline
                      </v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </v-row>
              </v-container>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-divider />
        <v-card-actions class="d-flex flex-row-reverse">
          <v-btn text @click="close">
            {{ $t('common.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn text block outlined class="text-none" @click="isDialogOpen = true">
      {{ departmentTeamSelectionButtonText }}
      <v-spacer />
      <v-icon> mdi-menu-down </v-icon>
    </v-btn>
  </v-col>
</template>

<script>
import dialogMixin from 'vue-dialog-mixin'; // https://github.com/vuetifyjs/vuetify/issues/1793#issuecomment-667843918
import { mapGetters } from 'vuex';
import { toAscii } from 'japanese-string-utils';
import { sendGoogleAnalyticsEvent } from '../../../plugins/vue-gtag';

export default {
  mixins: [dialogMixin],
  data: () => ({
    isDialogOpen: false,
    searchText: '',
  }),
  computed: {
    ...mapGetters('groups', [
      'selectedGroup',
      'getGroupByTypeAndId',
      'starredGroups',
      'teams',
      'departments',
    ]),
    departmentTeamSelectionButtonText() {
      if (this.selectedGroup === null) {
        return this.$t('deptTeamSelector.selectDepartmentOrTeam');
      }
      return this.selectedGroup.name;
    },
    choices() {
      let choices = [];
      choices = choices.concat(
        this.createSectionInChoices(
          this.starredGroups,
          this.$t('departmentTeamSelectionModal.starredGroups'),
          'starred',
        ),
      );
      choices = choices.concat(
        this.createSectionInChoices(
          this.teams,
          this.$t('departmentTeamSelectionModal.teams'),
          'team',
        ),
      );
      choices = choices.concat(
        this.createSectionInChoices(
          this.departments,
          this.$t('departmentTeamSelectionModal.departments'),
          'department',
        ),
      );
      choices.shift();
      return choices;
    },
  },
  methods: {
    close() {
      this.isDialogOpen = false;
    },
    starGroup(group) {
      this.$store.dispatch('groups/markGroupAsStarred', group);
    },
    unstarGroup(group) {
      this.$store.dispatch('groups/markGroupAsNotStarred', group);
    },
    checkSearchTextWithGroup(text, name) {
      const halfWidthName = toAscii(name);
      const halfWidthText = toAscii(text);
      if (
        halfWidthName.toLowerCase().indexOf(halfWidthText.toLowerCase()) >= 0
      ) {
        return true;
      }
      return false;
    },
    createSectionInChoices(initialGroups, header, section) {
      const groups = [
        { divider: true, class: 'mt-2' },
        { header },
        { divider: true, class: 'pb-2' },
      ];
      return groups.concat(
        initialGroups.map((group) => ({
          ...group,
          ...{ uniqueId: `${group.id}-${section}` },
        })),
      );
    },
    updateSelectedGroup(selectedGroup) {
      sendGoogleAnalyticsEvent(
        'updateSelectedGroupFromModal',
        selectedGroup.name,
      );
      const group = this.getGroupByTypeAndId(
        selectedGroup.type,
        selectedGroup.id,
      );
      this.$store.dispatch('groups/setSelectedGroup', group);
      this.close();
    },
  },
};
</script>
