<template>
  <v-menu
    v-model="addEventQuickMenu"
    scrollable
    :transition="false"
    :position-x="eventMenuPosition.x"
    :position-y="eventMenuPosition.y"
    :close-on-content-click="false"
    :close-on-click="!isWriting"
    absolute
  >
    <v-card width="500px">
      <AddEditToolbar
        :title="isTaskOptionSelected ? $t('tasks.add') : $t('event.addEvent')"
        :is-edit-mode="false"
        :is-paste-possible="eventOnClipboard !== null"
        :is-submit-disabled="disabled || isSubmitting"
        @clicked-paste="pasteEvent"
        @clicked-close="close"
      />

      <v-card-text class="pb-0">
        <v-form ref="form" lazy-validation>
          <v-row no-gutters>
            <v-col>
              <v-text-field
                v-model="form.subject"
                prepend-icon="mdi-calendar-edit"
                :label="$t('event.subject')"
                maxlength="255"
              />
            </v-col>
          </v-row>

          <v-checkbox
            v-model="form.all_day"
            class="mt-0 ml-8"
            :label="$t('event.all_day')"
            hide-details
            @change="specifyTime"
          />
          <v-row no-gutters>
            <v-col>
              <DateTimeRangePicker
                v-model="dateTimeRange"
                :only-date="form.all_day == 1"
                @error="setSubmitBtnStatus"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col :cols="owner && owner.type !== 'user' ? 12 : 6" class="pt-5">
              <EventTypeSelector
                v-model="form.event_type_id"
                :disabled="isTaskOptionSelected"
              />
            </v-col>
            <v-col v-if="owner && owner.type === 'user'" cols="3" class="ml-4">
              <v-checkbox
                v-model="isTaskOptionSelected"
                hide-details
                :label="$t('tasks.title')"
                @change="setAllDayIfTaskOptionIsSelected"
              >
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row no-gutters class="py-2">
            <v-col>
              <v-textarea
                v-model="form.description"
                :label="$t('event.description')"
                prepend-icon="mdi-text"
                auto-grow
                hide-details
                class="ma-0 pa-0 pt-2"
                :rows="1"
              />
            </v-col>
          </v-row>
          <v-row no-gutters class="py-2">
            <v-col>
              <MultipleUsersSelector
                v-if="isInvitingMembersEnabled"
                v-model="form.members"
                :items="items"
                :assumed-members="assumedMembers"
                :label="$t('meetingScheduler.members')"
              />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-select
                :value="form.calendar_id"
                :items="calendars"
                item-text="name"
                item-value="id"
                prepend-icon="mdi-calendar-question"
                dense
                :append-icon="''"
                class="select-no-underline"
                disabled
              />
            </v-col>
          </v-row>
          <v-row v-if="isAddingOnOwnCalendar" no-gutters>
            <v-col class="pl-1" cols="4">
              <v-checkbox
                v-model="isPrivate"
                hide-details
                :label="$t('common.makePrivate')"
              >
              </v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="!isTaskOptionSelected"
          text
          :disabled="isSubmitting"
          @click="openMoreOptions"
          >{{ $t('common.moreOptions') }}</v-btn
        >
        <v-btn
          :loading="isSubmitting"
          :disabled="disabled || isSubmitting"
          color="primary"
          @click="submit"
        >
          {{ $t('common.submit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex';
import DateTimeRangePicker from './DateTimePicker/DateTimeRangePicker.vue';
import EventTypeSelector from './EventTypeSelector.vue';
import AddEditToolbar from './Calendar/AddEditToolbar.vue';
import MultipleUsersSelector from './MultipleUsersSelector.vue';

import dateTimeHelpers from '../mixins/dateTimeHelpers';
import tasksMixins from '../mixins/tasksMixins';
import meetingMembersSelectorItems from '../mixins/meetingMembersSelectorItems';
import { createEvent } from '../api/events.api';
import { createTask } from '../api/tasks.api';
import { createMeeting } from '../api/meetings.api';
import { sendGoogleAnalyticsEvent } from '../plugins/vue-gtag';
import calendarAddEdit from '../mixins/calendarAddEdit';

export default {
  components: {
    DateTimeRangePicker,
    EventTypeSelector,
    AddEditToolbar,
    MultipleUsersSelector,
  },
  mixins: [
    dateTimeHelpers,
    tasksMixins,
    meetingMembersSelectorItems,
    calendarAddEdit,
  ],
  data() {
    return {
      disabled: false,
      isPrivate: false,
      form: {
        id: 0,
        event_type_id: null,
        calendar_id: null,
        subject: '',
        description: '',
        all_day: false,
        is_private: 0,
        members: [],
      },
      dateTimeRange: {
        start: null,
        end: null,
      },
      initialDateTimeRange: {},
      lastSelectedDateTimeRange: {},
      owner: null,
      calendars: [],
      isTaskOptionSelected: false,
      isSubmitting: false,
    };
  },
  computed: {
    ...mapGetters([
      'name',
      'id',
      'email',
      'eventTypes',
      'isAddEventQuickOpen',
      'formExtras',
      'event',
      'eventMenuPosition',
      'eventOnClipboard',
    ]),

    addEventQuickMenu: {
      get() {
        return this.isAddEventQuickOpen;
      },
      set(value) {
        if (value === true) {
          this.$store.dispatch('openAddEventQuick');
        } else {
          this.$store.dispatch('closeAddEventQuick');
        }
      },
    },

    isWriting() {
      // eslint-disable-next-line camelcase
      const { subject, description, all_day, event_type_id } = this.form;

      return (
        JSON.stringify(this.$options.data().form) !==
        JSON.stringify({
          id: this.$options.data().form.id,
          event_type_id,
          calendar_id: this.$options.data().form.calendar_id,
          subject,
          description,
          all_day,
        })
      );
    },

    isAddingOnOwnCalendar() {
      if (this.calendars === null) {
        return false;
      }
      return this.calendars.length > 1;
    },

    privateCalendarId() {
      let calendarId = null;
      this.calendars.forEach((calendar) => {
        if (
          calendar.my_department === Calendar.PRIVATE &&
          calendar.other_department === Calendar.PRIVATE
        ) {
          calendarId = calendar.id;
        }
      });
      return calendarId;
    },

    publicCalendarId() {
      return this.formExtras.defaultCalendar;
    },

    isInvitingMembersEnabled() {
      return (
        this.isTaskOptionSelected === false &&
        this.form.all_day === false &&
        this.isMultiday === false &&
        this.isPrivate === false &&
        this.isAddingOnOwnCalendar === true
      );
    },

    assumedMembers() {
      // If a meeting is created, these members will be automatically added to the meeting
      const authUser = this.items.find(
        (item) => item.id === this.id && item.type === 'user',
      );
      return [authUser];
    },

    isMultiday() {
      return this.dateTimeRangeStartDate !== this.dateTimeRangeEndDate;
    },

    dateTimeRangeStartDate() {
      if (this.dateTimeRange.start === null) {
        return null;
      }
      return this.dateTimeRange.start.split(' ')[0];
    },

    dateTimeRangeStartTime() {
      if (this.dateTimeRange.start === null) {
        return null;
      }
      return this.dateTimeRange.start.split(' ')[1];
    },

    dateTimeRangeEndDate() {
      if (this.dateTimeRange.end === null) {
        return null;
      }
      return this.dateTimeRange.end.split(' ')[0];
    },

    dateTimeRangeEndTime() {
      if (this.dateTimeRange.end === null) {
        return null;
      }
      return this.dateTimeRange.end.split(' ')[1];
    },
  },
  watch: {
    addEventQuickMenu: {
      handler(value) {
        if (value) {
          this.open();
        } else {
          this.close();
        }
      },
    },

    isPrivate(newVal) {
      if (this.isTaskOptionSelected) {
        this.form.is_private = newVal ? 1 : 0;
        return;
      }
      this.form.calendar_id = newVal
        ? this.privateCalendarId
        : this.publicCalendarId;
    },

    dateTimeRange: {
      deep: true,
      handler(value) {
        if (!this.form.all_day) {
          this.lastSelectedDateTimeRange = {
            ...this.lastSelectedDateTimeRange,
            ...value,
          };
        }
      },
    },
  },
  methods: {
    setSubmitBtnStatus(value) {
      this.disabled = value;
    },

    open() {
      this.assignFormExtras();
      this.assignFormData();
    },

    assignFormExtras() {
      this.calendars = this.injectCalendarName(this.formExtras.calendars);
      this.dateTimeRange = {
        ...this.dateTimeRange,
        ...this.formExtras.formDates,
      };
      this.initialDateTimeRange = this.dateTimeRange;
      this.owner = this.formExtras.owner;
      if (this.formExtras.hasTime) {
        this.dateTimeRange.start = `${this.dateTimeRangeStartDate} ${this.formExtras.timeRange[0]}`;
        this.dateTimeRange.end = `${this.dateTimeRangeEndDate} ${this.formExtras.timeRange[1]}`;
      }
    },

    assignFormData() {
      this.form = { ...this.form, ...this.event };
      this.form.calendar_id = this.formExtras.defaultCalendar;
      this.form.members = [];
      this.isPrivate = this.isCalendarPrivate(this.form.calendar_id);
      if (this.formExtras.hasTime) {
        this.form.all_day = false;
      }
      this.$store.dispatch('updateEventFacilities', []);
      this.facilities = [];
    },

    isCalendarPrivate(calendarId) {
      let isPrivate = false;
      this.calendars.forEach((calendar) => {
        if (
          calendar.id === calendarId &&
          calendar.my_department === Calendar.PRIVATE &&
          calendar.other_department === Calendar.PRIVATE
        ) {
          isPrivate = true;
        }
      });
      return isPrivate;
    },

    close() {
      this.$emit('close');
      this.addEventQuickMenu = false;
      this.isTaskOptionSelected = false;
      this.$data.dateTimeRange = {
        ...this.$data.dateTimeRange,
        ...this.$options.data().dateTimeRange,
      };
    },

    setDefaultCalendar(calendarId) {
      this.form.calendar_id = calendarId;
    },

    openMoreOptions() {
      const data = {
        form: this.form,
        dateTimeRange: this.dateTimeRange,
        calendar_id: this.form.calendar_id,
      };
      this.$emit('open-detailed-form', data);
      this.close();
    },

    submit() {
      this.isSubmitting = true;
      if (this.form.all_day === true) {
        this.dateTimeRange.start = `${this.dateTimeRange.start.slice(
          0,
          10,
        )} 00:00:00`;
        this.dateTimeRange.end = `${this.dateTimeRange.end.slice(
          0,
          10,
        )} 00:00:00`;
      }

      if (this.isTaskOptionSelected && this.owner.type === 'user') {
        this.addTask();
      } else if (
        this.isInvitingMembersEnabled &&
        this.form.members.length > 0
      ) {
        this.addMeeting();
      } else {
        this.addEvent();
      }
    },

    addEvent() {
      createEvent(
        this.form.calendar_id,
        this.dateTimeRange.start,
        this.dateTimeRange.end,
        this.form.event_type_id,
        this.form.all_day,
        this.form.subject,
        this.form.description,
        null,
        false,
        !this.form.all_day,
        this.facilities,
        'App\\Event',
        0,
        this.id,
      )
        .then((response) => {
          const msg = this.$t('event.addResponseSuccess');
          this.showSuccessSnackbar(msg);
          this.$emit('added:event', response.data);
          this.isSubmitting = false;
          this.close();

          sendGoogleAnalyticsEvent(
            'createEventFromAddEventQuick',
            this.form.subject,
          );
        })
        .catch((error) => {
          if (error.response.status === 403) {
            this.$store.dispatch('openSnackbar', {
              color: 'error',
              message: this.$t('event.cannotAdd'),
            });
          } else {
            this.$store.dispatch('openSnackbar', {
              color: 'error',
              message: this.$t('common.somethingWentWrong'),
            });
          }
          this.isSubmitting = false;
          this.close();
        });
    },

    async addMeeting() {
      const members = this.getMeetingMembers();
      const response = await createMeeting(
        this.form.subject,
        this.form.description,
        this.form.event_type_id,
        this.dateTimeRange.start,
        this.dateTimeRange.end,
        members,
        [],
      );

      sendGoogleAnalyticsEvent(
        'createMeetingFromAddEventQuick',
        this.form.subject,
        members.length,
      );

      this.showSuccessSnackbar(this.$t('event.addResponseSuccess'));
      this.$emit('added:meeting', response.data);
      this.isSubmitting = false;
      this.close();
    },

    getMeetingMembers() {
      const members = this.form.members.map((member) => member.id);
      this.assumedMembers.forEach((member) => members.push(member.id));
      return members;
    },

    addTask() {
      createTask(
        this.owner.id,
        this.form.subject,
        this.form.description,
        false,
        this.dateTimeRange.start,
        this.dateTimeRange.end,
        this.form.all_day,
        this.form.is_private,
      )
        .then((response) => {
          const msg = this.$t('event.addResponseSuccess');
          this.showSuccessSnackbar(msg);
          this.$emit('added:task', response.data);
          this.refreshTasksDrawer();
          this.isSubmitting = false;
          this.close();

          sendGoogleAnalyticsEvent(
            'createTaskFromAddEventQuick',
            this.form.subject,
          );
        })
        .catch((error) => {
          if (error.response.status === 403) {
            this.$store.dispatch('openSnackbar', {
              color: 'error',
              message: this.$t('event.cannotAdd'),
            });
          } else {
            this.$store.dispatch('openSnackbar', {
              color: 'error',
              message: this.$t('common.somethingWentWrong'),
            });
          }
          this.isSubmitting = false;
        });
    },

    pasteEvent() {
      this.$emit('paste', this.form.calendar_id, this.dateTimeRange.start);
      this.close();
    },

    specifyTime() {
      this.dateTimeRange.start = `${this.dateTimeRange.start.slice(
        0,
        10,
      )} 10:00`;
      this.dateTimeRange.end = this.getDefaultEndTimeAsPerUserPreference(
        `${this.dateTimeRange.end.slice(0, 10)} 10:00`,
      );
    },

    showSuccessSnackbar(message) {
      this.$store.dispatch('openSnackbar', {
        color: 'success',
        message,
      });
    },

    setAllDayIfTaskOptionIsSelected() {
      if (this.isTaskOptionSelected) {
        if (this.form.all_day) {
          return;
        }
        if (this.hasDateTimeBeenAltered()) {
          return;
        }
        this.form.all_day = true;
        return;
      }
      this.form.all_day = false;
      this.dateTimeRange = {
        ...this.dateTimeRange,
        ...this.lastSelectedDateTimeRange,
      };
    },

    hasDateTimeBeenAltered() {
      return (
        JSON.stringify(this.initialDateTimeRange) !==
        JSON.stringify(this.dateTimeRange)
      );
    },
  },
};
</script>

<style>
.select-no-underline.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.select-no-underline.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}
</style>
