const state = {
  allFacilities: [],
  allFacilityTypes: [],
};

const getters = {
  facility: (state) => (id) =>
    state.allFacilities.find((facility) => facility.id === id),
  facilityType: (state) => (id) =>
    state.allFacilityTypes.find((facilityType) => facilityType.id === id),
  allFacilities: (state) => state.allFacilities,
  facilitiesViewableByUser: (state, getters, context) =>
    state.allFacilities.filter(
      (facility) =>
        facility.departments.some(
          (department) => department.id === context.user.user.department,
        ) || facility.is_visible_to_all_departments,
    ),
  allFacilityTypes: (state) => state.allFacilityTypes,
};

const mutations = {
  setAllFacilities: (state, payload) => {
    state.allFacilities = payload;
  },
  setAllFacilityTypes: (state, payload) => {
    state.allFacilityTypes = payload;
  },
};

const actions = {
  async fetchAndSetAllFacilitiesAndFacilityTypes({ dispatch }) {
    dispatch('fetchAndSetFacilityTypes');
    dispatch('fetchAndSetFacilities');
  },
  async fetchAndSetFacilityTypes({ commit }) {
    const { data } = await axios.get('/api/facility-types');
    commit('setAllFacilityTypes', data);
  },
  async fetchAndSetFacilities({ commit }) {
    const { data } = await axios.get('/api/facility');
    commit('setAllFacilities', data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
