var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"text-center text-truncate body-2 header-borders",attrs:{"tile":"","dark":"","color":_vm.backgroundColor,"min-width":40,"height":"44px"}},[_c('div',{style:({
      fontSize: _vm.dayTextFontSize,
      color: _vm.textColor,
    })},[_vm._v(" "+_vm._s(_vm.getDayText)+" ")]),_c('div',{style:({
      fontSize: _vm.dateTextFontSize,
      color: _vm.textColor,
    })},[_vm._v(" "+_vm._s(_vm.getDateText)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }