<template>
  <v-menu offset-y>
    <template #activator="{ on, attrs }">
      <v-chip
        class="ml-4"
        :color="statusTypes[userStatus].color"
        small
        v-bind="attrs"
        v-on="on"
      >
        {{ $t(`status.${statusTypes[userStatus].name}`) }}
        <v-icon small right> mdi-menu-down </v-icon>
      </v-chip>
    </template>
    <v-list>
      <v-list-item
        v-for="(status, index) in statusTypes"
        :key="index"
        dense
        @click="updateStatus(index)"
      >
        <v-list-item-title>
          <v-icon x-small :color="statusTypes[index].color" class="mr-1">
            mdi-circle
          </v-icon>
          <span>
            {{ $t(`status.${status.name}`) }}
          </span>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { format } from 'date-fns';
import { mapGetters } from 'vuex';
import { sendGoogleAnalyticsEvent } from '../plugins/vue-gtag';

export default {
  computed: {
    ...mapGetters('groups', ['selectedGroup']),
    ...mapGetters(['statusTypes', 'userStatus']),
  },
  methods: {
    async updateStatus(status) {
      const dateTime = App.helpers.getDateObject();
      sendGoogleAnalyticsEvent('updateUserStatus');
      await this.$store.dispatch('patchAndSetUserStatus', {
        status,
        dateTime: format(dateTime, 'yyyy-MM-dd HH:mm:ss'),
      });

      if (this.selectedGroup === null)
        this.$store.dispatch('groups/resetSelectedGroup');
      else
        this.$store.dispatch('groups/setSelectedGroup', {
          ...this.selectedGroup,
        });
    },
  },
};
</script>
