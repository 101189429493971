<template>
  <v-container class="pa-0 ma-0 pb-5" fluid>
    <WeekViewPrintPageHeader :week="selectedWeek" />
    <MaintenanceMessage />
    <WeekViewToolbar
      @first-load="isFirstLoad = true"
      @update-users="processUpdatedUsers"
    />
    <WeekViewCalendarGrid
      :users-list="usersList"
      :current-week="currentWeek"
      :is-loading="isLoading"
      :is-loading-few="isLoadingFew"
      :is-first-load="isFirstLoad"
      :selected-users-num="selectedUsers.length"
      :highlight-rows-cells="highlightedRowsCells"
      @single-click="processSingleClick"
      @drag-select="processDragSelect"
      @double-click="processDoubleClick"
      @event-click="openViewScheduleDetailsMenu"
      @drop-event="dropEvent"
    />

    <AddEventQuick
      ref="addEventQuick"
      @added:event="refreshCalendar"
      @added:meeting="refreshCalendar"
      @added:task="refreshCalendar"
      @paste="pasteEvent"
      @open-detailed-form="openAddEditEvent"
      @close="setDefaultHighlightedRowsCells"
    />
    <AddEditEvent
      @added:event="refreshCalendar"
      @added:meeting="refreshCalendar"
      @added:task="refreshCalendar"
      @updated:event="refreshCalendar"
      @paste="pasteEvent"
      @close="setDefaultHighlightedRowsCells"
    />
    <ViewScheduleModal
      ref="viewScheduleModal"
      @copy="copyToClipboard"
      @close="refreshCalendar"
      @open-edit-form="openEditEvent"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { startOfWeek } from 'date-fns';
import WeekViewPrintPageHeader from './components/WeekViewPrintPageHeader.vue';
import MaintenanceMessage from '../../components/MaintenanceMessage.vue';
import WeekViewToolbar from './components/WeekViewToolbar.vue';
import WeekViewCalendarGrid from './components/WeekViewCalendarGrid.vue';
import AddEventQuick from '../../components/AddEventQuick.vue';
import AddEditEvent from '../../components/AddEditEvent.vue';
import ViewScheduleModal from '../../components/ViewScheduleModal.vue';
import eventDragHandler from '../../mixins/eventDragHandler';
import eventPasteHandler from '../../mixins/eventPasteHandler';

import axiosCancelRequests from '../../mixins/axiosCancelRequest';
import dataRefresher from '../../mixins/dataRefresher';

export default {
  components: {
    WeekViewPrintPageHeader,
    MaintenanceMessage,
    WeekViewToolbar,
    WeekViewCalendarGrid,
    AddEventQuick,
    AddEditEvent,
    ViewScheduleModal,
  },
  mixins: [
    axiosCancelRequests,
    dataRefresher,
    eventDragHandler,
    eventPasteHandler,
  ],

  async beforeRouteEnter(to, from, next) {
    const scheduleType = Object.keys(to.query)[0];
    const eventId = Object.values(to.query)[0];

    if (eventId) {
      try {
        const event = await getEventDetails(scheduleType, eventId);
        const startTime =
          scheduleType === 'recurring_event' ? event.event_start : event.start;
        next((vm) => {
          vm.$store.dispatch('setCurrentDate', startTime.split(' ')[0]);
        });
      } catch (error) {
        next((vm) => {
          vm.$store.dispatch('openSnackbar', {
            color: 'warning',
            message: vm.$t('messages.eventDoesNotExist'),
          });
        });
      }
    }
    next();
  },

  beforeRouteLeave(from, to, next) {
    this.$store.dispatch('resetAddEditEventFormState');
    this.$store.dispatch('emptySelectedUsers');
    next();
  },

  data: () => ({
    isLoading: false,
    isLoadingFew: false,
    isFirstLoad: true,
    users: [],
    usersList: [],
    currentWeek: [],
    eventMove: null,
    clickDateRange: null,
    userCalendars: null,
    userDefaultCalendar: null,
    dateFromCalendar: null,
    to: null,
    from: null,
    error: false,
    selectedCalendarId: null,
    eventToEdit: null,
    refresher: null,
    highlightedRowsCells: [],
  }),

  computed: {
    ...mapGetters({
      id: 'id',
      name: 'name',
      department: 'department',
      selectedUsers: 'selectedUsers',
      preferences: 'preferences',
      eventOnClipboard: 'eventOnClipboard',
      isNationalHoliday: 'isNationalHoliday',
      userData: 'userData',
      isAddEventQuickOpen: 'isAddEventQuickOpen',
      isAddEditEventSheetOpen: 'isAddEditEventSheetOpen',
      addEventQuickTimeoutIds: 'addEventQuickTimeoutIds',
      event: 'event',
      user: 'user',
      currentDate: 'currentDate',
      teamUsers: 'teamUsers',
      departmentUsers: 'departmentUsers',
      firstDayOfTheWeek: 'firstDayOfTheWeek',
    }),

    selectedWeek() {
      return this.getWeek(this.currentDate);
    },
  },

  watch: {
    currentDate(newVal) {
      if (this.isAddEventQuickOpen) {
        this.closeAddEventQuick();
      }
      this.getWeek(newVal);
      this.isLoading = true;
      this.isFirstLoad = false;
      this.clearEvents();
      this.fetchEvents();
    },
  },

  mounted() {
    this.isLoading = true;
    this.getWeek(this.currentDate);
    this.$root.$on('update-user-calendar', (userId) =>
      this.updateCalendarForUser(userId),
    );
    this.setUpRefresher(this.fetchEvents);
  },

  methods: {
    ...mapActions([
      'fetchNationalHolidays',
      'setEventOnClipboard',
      'closeAddEventQuick',
    ]),

    processUpdatedUsers(users) {
      this.users = [...users];
      this.getTeamViewList();
      this.fetchEvents();
    },

    getTeamViewList() {
      this.isLoading = true;
      const teamViewList = new Array(this.users.length);
      this.usersList = teamViewList;
    },

    clearEvents() {
      this.usersList.forEach((userList, index) => {
        for (let i = 1; i < userList.length; i += 1) {
          userList[i] = [];
        }
        this.$set(this.usersList, index, userList);
      });
    },

    async fetchEvents() {
      this.cancelExistingRequests();
      const { data } = await axios.get(`api/events/calendar-owners`, {
        params: {
          calendarOwners: this.users,
          startDate: App.helpers.getISODateString(this.selectedWeek[0]),
          endDate: App.helpers.getISODateString(this.selectedWeek[6]),
        },
        cancelToken: this.getCancelToken(),
      });
      data.forEach((weeklyEventsOfCalendarOwner, index) => {
        this.$set(
          this.usersList,
          index,
          this.fixMultiDayEvents(weeklyEventsOfCalendarOwner),
        );
      });
      this.isLoading = false;
    },

    fixMultiDayEvents(weekEvents) {
      return weekEvents.map((dayEvents, index) => {
        if (index === 0 || index === 1) {
          return dayEvents;
        }
        return dayEvents.filter((event) => {
          const weekday = this.currentWeek[index - 1];
          return this.isEventStartingOnDay(event, weekday);
        });
      });
    },

    isEventStartingOnDay(event, weekday) {
      weekday = App.helpers.getISODateString(weekday);
      const startDate = event.start.split(' ')[0];
      return weekday === startDate;
    },

    async dropEvent(data) {
      const calendarOwner = this.usersList[data.finalRow][0];
      const newDate = this.currentWeek[data.finalCell];
      let usersList = [...this.usersList];
      usersList = this.removeEventFromExistingPosition(
        usersList,
        data.draggedEvent,
        data.initialRow,
        data.initialCell,
      );
      usersList = this.injectEventIntoNewPosition(
        usersList,
        data.droppedEvent,
        data.finalRow,
        data.finalCell,
      );
      this.usersList = [...usersList];

      if (data.droppedEvent.isTask) {
        await this.handleDragTask(data.droppedEvent, calendarOwner, newDate);
      } else if (data.droppedEvent.meeting_id) {
        await this.handleDragMeetingEvent(data.droppedEvent, calendarOwner);
      } else if (data.droppedEvent.recurrence_id) {
        await this.handleDragRecurringEvent(
          data.droppedEvent,
          calendarOwner,
          newDate,
        );
      } else {
        await this.handleDragEvent(data.droppedEvent, calendarOwner, newDate);
      }
    },

    async pasteEvent(calendarId, date) {
      if (this.eventOnClipboard.isTask) {
        const eventId = this.eventOnClipboard.id;
        await this.handlePasteTaskEvent(eventId, calendarId, date);
      } else if (this.eventOnClipboard.recurrence_id) {
        const eventId = this.eventOnClipboard.recurrence_id;
        await this.handlePasteRecurringEvent(eventId, calendarId, date);
      } else {
        const eventId = this.eventOnClipboard.id;
        await this.handlePasteEvent(eventId, calendarId, date);
      }
    },

    copyToClipboard(schedule) {
      this.$store.dispatch('setEventOnClipboard', schedule);
    },

    clearClipboard() {
      this.$store.dispatch('setEventOnClipboard', null);
    },

    openViewScheduleDetailsMenu(eventDetails, user, event) {
      this.$store.dispatch('resetAddEditEventFormState');
      if (!('subject' in eventDetails)) {
        eventDetails.subject = undefined; // forcibly update subject as undefined in the store event, to mark as restricted
      }
      this.$store.dispatch('updateEvent', eventDetails);
      this.updateFormExtras(eventDetails, user);
      const menuPosition = this.getMenuPosition(event);
      this.$store.dispatch('updateEventMenuPosition', { ...menuPosition });
      setTimeout(() => {
        this.$store.dispatch('openViewScheduleDetailsMenu');
      }, 10);
    },

    updateFormExtras(eventDetails, user) {
      const calendarOptions = this.filterCalendarsBasedOnPermission(
        user.calendars,
      );
      const formExtras = {
        calendars: calendarOptions,
        formDates: {
          start: eventDetails.start,
          end: eventDetails.end,
        },
        owner: user,
      };
      this.$store.dispatch('updateFormExtras', { ...formExtras });
    },

    getMenuPosition(event) {
      if (event instanceof PointerEvent || event instanceof MouseEvent) {
        return {
          x: event.x,
          y: event.y,
        };
      }

      return {
        x: event.x + event.width / 2,
        y: event.y + event.height / 2,
      };
    },

    refreshCalendar(response) {
      if (response !== null) {
        if (response.calendars) {
          this.updateCalendarsInUsersList(response.calendars);
        } else if (response.userId) {
          this.updateCalendarForUser(response.userId);
        } else {
          this.updateCalendarForUser(this.id);
        }
      }
      // this.$store.dispatch('resetAddEditEventFormState');
    },

    updateCalendarForUser(id) {
      this.isLoadingFew = true;
      const dates = [
        App.helpers.getISODateString(this.selectedWeek[0]),
        App.helpers.getISODateString(this.selectedWeek[6]),
      ];
      this.usersList.forEach(async (userList, index) => {
        if (userList[0].id === id) {
          const response = await axios.get(
            `api/events/user/${id}/${JSON.stringify(dates)}`,
          );
          let events = response.data;
          events = this.fixMultiDayEvents(events);
          this.$set(this.usersList, index, events);
          this.isLoadingFew = false;
        }
      });
    },

    updateCalendarsInUsersList(calendars) {
      this.isLoadingFew = true;
      calendars.forEach(async (calendar) => {
        const cal = await this.getCalendarDetails(calendar);
        let id;
        let type;
        if (cal.user_id == null) {
          if (cal.team_id === null) {
            id = cal.department_id;
            type = 'department';
          } else {
            id = cal.team_id;
            type = 'team';
          }
        } else {
          id = cal.user_id;
          type = 'user';
        }
        const dates = [
          App.helpers.getISODateString(this.selectedWeek[0]),
          App.helpers.getISODateString(this.selectedWeek[6]),
        ];
        this.usersList.forEach(async (userList, index) => {
          if (userList[0].id === id && userList[0].type === type) {
            let promisedEvents;
            if (userList[0].type === 'user') {
              promisedEvents = axios.get(
                `api/events/user/${id}/${JSON.stringify(dates)}`,
              );
            } else if (userList[0].type === 'department') {
              promisedEvents = axios.get(
                `api/events/department/${id}/${JSON.stringify(dates)}`,
              );
            } else {
              promisedEvents = axios.get(
                `api/events/team/${id}/${JSON.stringify(dates)}`,
              );
            }
            promisedEvents
              .then((response) => {
                let events = response.data;
                events = this.fixMultiDayEvents(events);
                this.$set(this.usersList, index, events);
                this.isLoadingFew = false;
              })
              .catch();
          }
        });
      });
    },

    getCalendarDetails(id) {
      return axios
        .get(`api/calendar/${id}`)
        .then((response) => response.data.calendar)
        .catch(() => {});
    },

    getWeek(dateArg) {
      const week = [];
      const weekStartsOn = this.firstDayOfTheWeek === 'sunday' ? 0 : 1;
      let date = App.helpers.getDateObject(dateArg);
      date = startOfWeek(date, { weekStartsOn });

      for (let i = 0; i < 7; i += 1) {
        week.push(App.helpers.getDateObject(date));
        date.setDate(date.getDate() + 1);
      }

      this.currentWeek = week;
      return week;
    },

    openAddEditEvent(data) {
      const formExtras = {
        formDates: data.dateTimeRange,
        defaultCalendar: data.form.calendar_id,
      };
      this.$store.dispatch('updateFormExtras', formExtras);
      this.$store.dispatch('updateEvent', data.form);
      this.$store.dispatch('openAddEditEventSheet');
    },

    openEditEvent(data) {
      const formExtras = {
        formDates: data.dateTimeRange,
        defaultCalendar: data.defaultCalendar,
        isEditing: true,
      };
      this.$store.dispatch('updateFormExtras', formExtras);
      this.$store.dispatch('updateEventFacilities', data.facilities);
      data.form.start = data.dateTimeRange.start;
      data.form.end = data.dateTimeRange.end;
      this.$store.dispatch('updateEvent', data.form);
      this.$store.dispatch('openAddEditEventSheet');
    },

    setDefaultHighlightedRowsCells() {
      this.highlightedRowsCells = [];
    },

    processSingleClick({ row, cell, mouseEvent }) {
      this.openAddEvent(row, cell, cell, mouseEvent);
      this.highlightedRowsCells = [{ row, cells: [cell] }];
    },

    processDragSelect({ row, startCell, endCell, mouseEvent }) {
      this.openAddEvent(row, startCell, endCell, mouseEvent);
      const minCell = Math.min(startCell, endCell);
      const maxCell = Math.max(startCell, endCell);

      const highlightedCells = [];
      for (let i = minCell; i <= maxCell; i++) {
        highlightedCells.push(i);
      }
      this.highlightedRowsCells = [{ row, cells: [...highlightedCells] }];
    },

    processDoubleClick({ row, cell }) {
      this.openAddEvent(row, cell, cell, null);
    },

    openAddEvent(row, startIndex, endIndex, mouseEvent = null) {
      const calendarOwner = this.usersList[row][0];
      this.$store.dispatch('resetEventData');
      this.$store.dispatch('resetFormExtras');

      const { type, calendars } = calendarOwner;
      const calendarOptions = this.filterCalendarsBasedOnPermission(calendars);
      const calendar =
        type === 'user' ? calendarOwner.defaultCalendar : calendars[0];

      if (!this.isAddEventPossibleForCalendar(type, calendarOwner, calendar)) {
        this.$store.dispatch('openSnackbar', {
          color: 'error',
          message: this.$t('event.cannotAdd'),
        });
        return;
      }

      const formDates = this.getStartAndEndInWeekForEvent(startIndex, endIndex);
      this.$store.dispatch('updateFormExtras', {
        calendars: calendarOptions,
        defaultCalendar: calendar.id,
        formDates,
        owner: calendarOwner,
      });

      if (this.$vuetify.breakpoint.smAndUp && mouseEvent !== null) {
        this.$store.dispatch('updateEventMenuPosition', {
          x: mouseEvent.clientX,
          y: mouseEvent.clientY,
        });
        this.$store.dispatch('openAddEventQuick');
      } else {
        this.$store.dispatch('openAddEditEventSheet');
      }
    },

    filterCalendarsBasedOnPermission(calendars) {
      return calendars.filter((calendar) => {
        if (calendar.user_id === this.id) return true;
        if (
          calendar.my_department === 'private' &&
          calendar.other_department === 'private'
        ) {
          return false;
        }
        return true;
      });
    },

    isAddEventPossibleForCalendar(type, info, calendar) {
      if (type === 'user') {
        const userInfo = this.userData(info.id);
        userInfo.type = 'user';
        return this.$root.canLoggedInUserWriteToUserCalendar(
          userInfo,
          calendar,
        );
      }
      if (type === 'department') {
        return this.$root.canLoggedInUserWriteToDepartmentCalendar(
          info.id,
          calendar,
        );
      }
      return true;
    },

    getStartAndEndInWeekForEvent(startIndex, endIndex) {
      let date1 = App.helpers.getDateObject(this.currentWeek[startIndex]);
      let date2 = App.helpers.getDateObject(this.currentWeek[endIndex]);
      date1 = App.helpers.getISODateString(date1);
      date2 = App.helpers.getISODateString(date2);
      const start = date1 < date2 ? date1 : date2;
      const end = date1 < date2 ? date2 : date1;
      return { start: `${start} 10:00`, end: `${end} 11:00` };
    },
  },
};

async function getEventDetails(scheduleType, eventId) {
  let event;
  if (scheduleType === 'task') {
    const { data } = await axios.get(`/api/task/${eventId}`);
    event = { ...data.task };
  } else if (scheduleType === 'recurring_event') {
    const { data } = await axios.get(`/api/event-recur/${eventId}`);
    event = { ...data.eventRecur };
  } else {
    const { data } = await axios.get(`/api/events/${eventId}`);
    event = { ...data.event };
  }
  return event;
}
</script>