import {
  parseISO,
  addDays,
  subDays,
  format,
  addMonths,
  subMonths,
  setDate,
  setMonth,
  setYear,
} from 'date-fns';

function getTodayFormatted() {
  let date = new Date();
  date = format(date, 'yyyy-MM-dd');
  return date;
}

const state = {
  currentDate: getTodayFormatted(),
  currentCalendarOwner: null,
};

const getters = {
  currentDate: (state) => state.currentDate,
  currentDateObject: (_, getters) => parseISO(getters.currentDate),
  currentCalendarOwner: (state, getters) => {
    if (!state.currentCalendarOwner) {
      const { user } = getters;
      user.uid = `user ${user.id}`;
      return user;
    }
    return state.currentCalendarOwner;
  },
};

const mutations = {
  setCurrentDate: (state, payload) => {
    state.currentDate = payload;
  },
  setCurrentCalendarOwner: (state, payload) => {
    state.currentCalendarOwner = payload;
  },
};

const actions = {
  setCurrentDate: ({ commit }, payload) => {
    commit('setCurrentDate', payload);
  },
  setCurrentCalendarOwner: ({ commit }, payload) => {
    commit('setCurrentCalendarOwner', payload);
  },
  setYearOfCurrentDate: ({ commit, getters }, toYear) => {
    let date = getters.currentDateObject;
    date = setYear(date, toYear);
    date = format(date, 'yyyy-MM-dd');
    commit('setCurrentDate', date);
  },
  setMonthOfCurrentDate: ({ commit, getters }, toMonth) => {
    let date = getters.currentDateObject;
    date = setMonth(date, toMonth);
    date = format(date, 'yyyy-MM-dd');
    commit('setCurrentDate', date);
  },
  setDayOfCurrentDate: ({ commit, getters }, toDay) => {
    let date = getters.currentDateObject;
    date = setDate(date, toDay);
    date = format(date, 'yyyy-MM-dd');
    commit('setCurrentDate', date);
  },
  updateCurrentDateToToday: ({ commit }) => {
    commit('setCurrentDate', getTodayFormatted());
  },
  updateCurrentDateToNextDay: ({ commit, getters }) => {
    let date = getters.currentDateObject;
    date = addDays(date, 1);
    date = format(date, 'yyyy-MM-dd');
    commit('setCurrentDate', date);
  },
  updateCurrentDateToPreviousDay: ({ commit, getters }) => {
    let date = getters.currentDateObject;
    date = subDays(date, 1);
    date = format(date, 'yyyy-MM-dd');
    commit('setCurrentDate', date);
  },
  updateCurrentDateToNextWeek: ({ commit, getters }) => {
    let date = getters.currentDateObject;
    date = addDays(date, 7);
    date = format(date, 'yyyy-MM-dd');
    commit('setCurrentDate', date);
  },
  updateCurrentDateToPreviousWeek: ({ commit, getters }) => {
    let date = getters.currentDateObject;
    date = subDays(date, 7);
    date = format(date, 'yyyy-MM-dd');
    commit('setCurrentDate', date);
  },
  updateCurrentDateToNextMonth: ({ commit, getters }) => {
    let date = getters.currentDateObject;
    date = addMonths(date, 1);
    date = format(date, 'yyyy-MM-dd');
    commit('setCurrentDate', date);
  },
  updateCurrentDateToPreviousMonth: ({ commit, getters }) => {
    let date = getters.currentDateObject;
    date = subMonths(date, 1);
    date = format(date, 'yyyy-MM-dd');
    commit('setCurrentDate', date);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
