<template>
  <v-skeleton-loader :type="getRandomChips()" class="pr-2" />
</template>

<script>
export default {
  methods: {
    getRandomChips() {
      const chipsNum = this.getRandomNumBetween0And3();
      switch (chipsNum) {
        case 0:
          return '';
        case 1:
          return 'text';
        case 2:
          return 'text@2';
        default:
          return 'text@3';
      }
    },
    getRandomNumBetween0And3() {
      return Math.floor(Math.random() * 4);
    },
  },
};
</script>
