<template>
  <v-row no-gutters justify="center">
    <v-alert
      v-model="alert"
      dismissible
      prominent
      color="cyan"
      border="left"
      elevation="2"
      colored-border
      icon="mdi-cogs"
      class="mb-2"
      @input="alertChanged"
    >
      <span v-html="reformatMessageByAddingHyperlink" />
    </v-alert>
  </v-row>
</template>

<script>
import linkifyHtml from 'linkify-html';
import xss from 'xss';
import { mapGetters } from 'vuex';
import { getMaintenanceMessage } from '../api/miscellaneous.api';

export default {
  data() {
    return {
      alert: false,
      message: null,
    };
  },

  computed: {
    ...mapGetters(['isMaintenanceMessageDismissed']),

    reformatMessageByAddingHyperlink() {
      if (this.message === null) {
        return null;
      }
      const sanitizedMessage = xss(this.message);

      return linkifyHtml(sanitizedMessage, {
        defaultProtocol: 'https',
        target: '_blank',
      });
    },
  },

  created() {
    this.fetchMaintenanceMessage();
  },

  methods: {
    async fetchMaintenanceMessage() {
      if (this.isMaintenanceMessageDismissed === true) {
        return;
      }
      const { data } = await getMaintenanceMessage();
      this.setMaintenanceMessage(data);
    },

    setMaintenanceMessage(maintenanceMessage) {
      if (maintenanceMessage !== null && maintenanceMessage !== '') {
        this.message = maintenanceMessage.message;
        this.alert = true;
      }
    },

    alertChanged(value) {
      if (value === false) {
        this.$store.dispatch('updateIsMaintenanceMessageDismissed', true);
      }
    },
  },
};
</script>
