<template>
  <v-tooltip
    bottom
    :open-delay="500"
    color="#000000"
    :disabled="$vuetify.breakpoint.xsOnly"
    :open-on-hover="$vuetify.breakpoint.smAndUp"
  >
    <template #activator="{ on }">
      <v-card
        :id="'event' + schedule.id"
        ref="eventChip"
        flat
        :style="getEventChipStyle(schedule)"
        :class="{
          shake: isEventToBeOpened,
          caption: true,
        }"
        v-on="on"
        @mousedown="$event.stopPropagation()"
        @click="displayEvent"
      >
        <div
          :class="[
            $vuetify.breakpoint.xsOnly ? 'pl-0' : 'pl-1',
            'noselect',
            isAttendingStatusNotGoing(schedule) ||
            isAttendingStatusMaybe(schedule)
              ? 'isNotGoingOrMaybe'
              : '',
          ]"
          :style="cardDivStyle"
        >
          <v-icon v-if="isHighPriority" x-small> mdi-star </v-icon>
          <v-icon v-if="isTaskDone" small>
            mdi-checkbox-marked-circle-outline
          </v-icon>
          <v-icon v-if="isTaskNotDone" small> mdi-circle-outline </v-icon>
          <strong
            v-if="isTimeDisplayed"
            :class="{
              'text-decoration-line-through':
                isAttendingStatusNotGoing(schedule),
            }"
          >
            {{ schedule.timeString }}
          </strong>
          <span
            :class="{
              'text-decoration-line-through':
                isAttendingStatusNotGoing(schedule),
            }"
            :style="{ fontSize: subjectFontSize }"
          >
            {{ subjectString }}
          </span>
        </div>
      </v-card>
    </template>
    {{ timeDurationString(schedule) }}
    <br />
    {{ schedule.subject }}
    <span v-if="!!schedule.place">({{ schedule.place }})</span>
  </v-tooltip>
</template>

<script>
import calendarEventChip from '../mixins/calendarEventChip';

export default {
  mixins: [calendarEventChip],
  props: {
    schedule: {
      type: Object,
      required: true,
    },
  },

  computed: {
    cardDivStyle() {
      return {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        cursor: 'pointer',
      };
    },

    isEventToBeOpened() {
      if (this.$route.query.event)
        return parseInt(this.$route.query.event, 10) === this.schedule.id;
      if (this.$route.query.task)
        return parseInt(this.$route.query.task, 10) === this.schedule.id;
      if (this.$route.query.recurring_event)
        return (
          parseInt(this.$route.query.recurring_event, 10) ===
          this.schedule.recurrence_id
        );
      return false;
    },

    isTaskDone() {
      return this.schedule.isTask && this.schedule.is_done;
    },

    isTaskNotDone() {
      return this.schedule.isTask && !this.schedule.is_done;
    },

    isHighPriority() {
      return this.schedule.priority;
    },

    isTimeDisplayed() {
      return this.schedule.timeString && this.$vuetify.breakpoint.smAndUp;
    },

    subjectFontSize() {
      const breakpoint = this.$vuetify.breakpoint.name;
      return breakpoint === 'xs' ? '10px' : '12px';
    },

    subjectString() {
      const { subject } = this.schedule;

      if (subject) {
        return subject;
      }

      if (subject === '' || subject === null) {
        return `(${this.noSubjectString})`;
      }

      // in case of undefined
      return this.restrictedEventSubjectString;
    },

    noSubjectString() {
      return this.getEventType(this.schedule.event_type_id).id
        ? this.getEventType(this.schedule.event_type_id).name
        : this.$t('event.noSubject');
    },

    restrictedEventSubjectString() {
      const allDay = this.schedule.all_day;
      return !allDay ? this.$t('common.busy') : this.$t('event.busyAllDay');
    },
  },
  watch: {
    '$route.query': {
      handler(query) {
        if (query.event || query.task || query.recurring_event)
          this.emitEventToOpenViewScheduleDetails();
      },
      deep: true,
    },
  },

  mounted() {
    this.emitEventToOpenViewScheduleDetails();
  },

  methods: {
    displayEvent(mouseEvent) {
      window.event.stopPropagation();
      this.$emit('event-chip-clicked', mouseEvent);
    },

    emitEventToOpenViewScheduleDetails() {
      if (this.isEventToBeOpened) {
        this.$nextTick().then(() => {
          const e = this.$refs.eventChip.$el.getBoundingClientRect();
          this.$emit('event-chip-clicked', e);
        });
        this.$router.replace('/');
      }
    },

    timeDurationString(schedule) {
      let timeString;
      if (
        schedule.all_day &&
        schedule.start.split(' ')[0] === schedule.end.split(' ')[0]
      ) {
        timeString = this.$i18n.d(
          App.helpers.getDateObject(schedule.start),
          'allDayEvent',
        );
      } else if (
        schedule.all_day &&
        schedule.start.split(' ')[0] !== schedule.end.split(' ')[0]
      ) {
        timeString = `${this.$i18n.d(
          App.helpers.getDateObject(schedule.start),
          'allDayEvent',
        )}~${this.$i18n.d(
          App.helpers.getDateObject(schedule.end),
          'allDayEvent',
        )}`;
      } else {
        timeString = `${this.$i18n.d(
          App.helpers.getDateObject(schedule.start),
          'eventTime',
        )} ~ ${this.$i18n.d(
          App.helpers.getDateObject(schedule.end),
          'eventTime',
        )}`;
      }
      return timeString;
    },
  },
};
</script>

<style>
.isNotGoingOrMaybe {
  background-color: white;
  box-shadow: inset 0px 0px 0px 2px var(--shadow-color);
}

.noselect {
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.shake {
  animation: shake 0.7s;
  animation-iteration-count: 3;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
