/* eslint-disable no-unused-vars */
import VDragged from 'v-dragged';
import Vue from './vue';
import './plugins/axios';
import { setUserInGoogleAnalytics } from './plugins/vue-gtag';
import AppUi from './AppUi.vue';
import './globalAppObject';
import './calendarAttributes';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import './echo';
import fgColourMixin from './mixins/foregroundColour';
import calendarPermissionMixin from './mixins/calendarPermissions';
import './registerServiceWorker';
import setUserInSentry from './plugins/sentry';
import accountsAppBaseUrl from './accountsAppBaseUrl';

function configureVue() {
  Vue.use(VDragged);
  Vue.config.productionTip = false;
  Vue.config.performance = true;
  Vue.config.devtools = true;
  Vue.prototype.App = window.App;
}

async function initialize() {
  const { data } = await axios.get('api/user');
  await store.dispatch('setUser', data);
  setUserInGoogleAnalytics();
  await store.dispatch('groups/fetchGroups');
  await store.dispatch('fetchAndSetUsers');
  await store.dispatch('fetchAndSetGroupsUsers');
  await store.dispatch('fetchEventTypes');
  setUserInSentry();
  new Vue({
    router,
    store,
    vuetify,
    mixins: [fgColourMixin, calendarPermissionMixin],
    i18n,
    render(h) {
      return h(AppUi);
    },
  }).$mount('#app');

  store.dispatch('fetchNationalHolidays');
  store.dispatch('fetchAndSetAllFacilitiesAndFacilityTypes');
}

configureVue();
initialize();
