import httpClient from './httpClient';

const getMaintenanceMessage = () => httpClient.get('maintenance-message');
const getManualUrl = () => httpClient.get('manual');

const getReleases = (locale) =>
  httpClient.get(`releases?locale=${locale}`, {
    headers: { 'Cache-Control': 'no-cache' },
  });

const storeFeedBack = (feedback) => httpClient.post('feedback', { feedback });

export { getMaintenanceMessage, getManualUrl, getReleases, storeFeedBack };
