<template>
  <v-menu close-on-click close-on-content-click offset-x>
    <template #activator="{ on: onMenu }">
      <v-tooltip bottom :disabled="$vuetify.breakpoint.xsOnly">
        <template #activator="{ on: onTooltip }">
          <v-btn icon class="mx-1" v-on="{ ...onMenu, ...onTooltip }">
            <v-icon size="20">mdi-pencil-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('viewScheduleCard.edit') }}</span>
      </v-tooltip>
    </template>
    <v-card class="pa-1">
      <v-btn
        color="primary"
        class="mr-1"
        outlined
        small
        @click="editMeetingPersonal"
      >
        {{ $t('meetingScheduler.editSelfMeeting') }}
      </v-btn>
      <v-btn color="green" class="mx-1" outlined small @click="editMeetingAll">
        {{ $t('meetingScheduler.editEveryoneMeeting') }}
      </v-btn>
      <v-btn small icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  methods: {
    editMeetingPersonal() {
      this.$emit('personal-edit', this.id);
    },
    editMeetingAll() {
      this.$router.push({
        name: 'schedule-meeting',
        params: { id: this.id, isEditing: true },
      });
    },
  },
};
</script>
