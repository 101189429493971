import httpClient from './httpClient';

const createTask = (
  userId,
  subject,
  description,
  isDone,
  start,
  end,
  allDay,
  isPrivate,
) => {
  const endpoint = 'task';
  return httpClient.post(endpoint, {
    user_id: userId,
    subject,
    description,
    is_done: isDone,
    start,
    end,
    all_day: allDay,
    is_private: isPrivate,
  });
};

const dragDropTask = (info, event, startDate, endDate) => {
  const endpoint = `task/${event.id}/move`;
  return httpClient.put(endpoint, {
    id: info.id,
    type: info.type,
    newStartDate: startDate,
    newEndDate: endDate,
  });
};

const pasteTask = (eventId, calendarId, date) => {
  const endpoint = `task/${eventId}/copy`;
  return httpClient.post(endpoint, { calendarId, date });
};

export { createTask, dragDropTask, pasteTask };
