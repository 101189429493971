import httpClient from './httpClient';

const updateUserPreferences = (data) => {
  const endPoint = 'user/preferences';
  return httpClient.patch(endPoint, { ...data });
};

const getCalendarSharingSettings = () => {
  const endpoint = 'calendar/sharing-settings';
  return httpClient.get(endpoint);
};

const updatePublicCalendarSharingSetting = (isEnabled) => {
  const endpoint = 'calendar/public-sharing';
  return httpClient.patch(endpoint, { isEnabled });
};

const updatePrivateCalendarSharingSetting = (isEnabled) => {
  const endpoint = 'calendar/private-sharing';
  return httpClient.patch(endpoint, { isEnabled });
};

const getRegenaratedPublicCalendarSharingUrl = () => {
  const endpoint = 'reset-public-calendar-shareable-url';
  return httpClient.get(endpoint);
};

const getRegenaratedPrivateCalendarSharingUrl = () => {
  const endpoint = 'reset-private-calendar-shareable-url';
  return httpClient.get(endpoint);
};

export {
  updateUserPreferences,
  getCalendarSharingSettings,
  updatePublicCalendarSharingSetting,
  updatePrivateCalendarSharingSetting,
  getRegenaratedPublicCalendarSharingUrl,
  getRegenaratedPrivateCalendarSharingUrl,
};
