import Vue from 'vue';
import Vuex from 'vuex';
import navdrawer from './Modules/navdrawer';
import user from './Modules/user';
import groups from './Modules/groups';
import selectedUsers from './Modules/selectedUsers';
import nationalHolidays from './Modules/nationalHolidays';
import users from './Modules/users';
import events from './Modules/events';
import eventTypes from './Modules/eventTypes';
import tasksNavDrawer from './Modules/tasksNavDrawer';
import snackbar from './Modules/snackbar';
import booking from './Modules/booking';
import addEditEventForms from './Modules/addEditEventForms';
import meetingScheduler from './Modules/meetingScheduler';
import facilities from './Modules/facilities';
import eventModalDisplay from './Modules/eventModalDisplay';
import eventRefresher from './Modules/eventRefresher';
import sendFeedback from './Modules/sendFeedback';
import app from './Modules/app';
import gridFacilityPage from './Modules/gridFacilityPage';
import recurringDateSelector from './Modules/recurringDateSelector';
import displayMenu from './Modules/displayMenu';
import information from './Modules/information';
import status from './Modules/status';
import maintenanceMessage from './Modules/maintenanceMessage';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    navdrawer,
    user,
    users,
    events,
    groups,
    selectedUsers,
    nationalHolidays,
    eventTypes,
    tasksNavDrawer,
    snackbar,
    booking,
    addEditEventForms,
    meetingScheduler,
    facilities,
    eventModalDisplay,
    eventRefresher,
    sendFeedback,
    app,
    gridFacilityPage,
    recurringDateSelector,
    displayMenu,
    information,
    status,
    maintenanceMessage,
  },
});

export default store;
