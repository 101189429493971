<template>
  <v-menu
    v-if="event != null"
    v-model="isViewEventDetailsMenuOpen"
    absolute
    :position-x="eventMenuPosition.x"
    :position-y="eventMenuPosition.y"
    :close-on-content-click="false"
  >
    <DeleteOptionsDialog ref="deleteOptions" @selection="deleteGroupedEvent" />
    <v-card width="500px">
      <v-progress-linear v-if="isLoading" indeterminate />
      <DetailsToolbar
        :is-editable="isEditable"
        :is-deletable="isDeletable"
        :can-be-copied="canBeCopied"
        :can-be-duplicated="canBeDuplicated"
        :meeting-id="event.meeting_id"
        @clicked-copy="copyEvent"
        @clicked-duplicate="duplicateMeeting"
        @clicked-edit="editEvent"
        @clicked-delete="deleteEvent"
        @clicked-close="isViewEventDetailsMenuOpen = false"
      />

      <v-card-text>
        <div class="px-2">
          <EventTypeChip
            v-if="event.event_type_id != null"
            :id="event.event_type_id"
          />
          <span
            class="text-h6 font-weight-bold"
            style="text-align: justify; vertical-align: middle"
          >
            {{ getDisplaySubject(event) }}
          </span>
        </div>
        <div class="d-flex justify-space-between align-center pl-2">
          <span class="grey--text text--darken-3">
            <div v-if="event.all_day && event.start === event.end">
              {{ $d(App.helpers.getDateObject(event.start), 'allDayEvent') }}
            </div>
            <div v-else-if="event.all_day && event.start !== event.end">
              {{
                `${$d(
                  App.helpers.getDateObject(event.start),
                  'allDayEvent',
                )} ~ ${$d(App.helpers.getDateObject(event.end), 'allDayEvent')}`
              }}
            </div>
            <div v-else>
              {{ $d(App.helpers.getDateObject(event.start), 'eventTime') }}
              ~
              {{ $d(App.helpers.getDateObject(event.end), 'eventTime') }}
            </div>
          </span>
        </div>
        <DetailsDisplayElement
          v-if="eventPlace !== null"
          :icon="'mdi-map-marker'"
          :message="eventPlace"
          :tooltip-text="$t('event.location')"
          :is-html-view="true"
        />
        <DetailsDisplayElement
          v-if="modalDisplay.description"
          :icon="'mdi-text'"
          :message="formatDescription(modalDisplay.description)"
          :tooltip-text="$t('meetingScheduler.body')"
          :is-html-view="true"
        />
        <div v-if="!isPrivateTask()">
          <DetailsDisplayElement
            v-if="!isMeeting"
            :icon="'mdi-calendar-account-outline'"
            :message="getCalendarName(event)"
            :tooltip-text="$t('navDrawer.calendar')"
            :sub-message="`${$t('event.editedBy')} : ${modalDisplay.updatedBy}`"
          />
        </div>
        <div v-else>
          <DetailsDisplayElement
            :icon="'mdi-lock'"
            :message="$t('tasks.privateTitle')"
            :tooltip-text="$t('tasks.privateTitle')"
          />
        </div>
        <DetailsDisplayElement
          v-if="meetingOrganizer !== null"
          :icon="'mdi-account-tie'"
          :message="meetingOrganizer"
          :tooltip-text="$t('meetingScheduler.organizer')"
        />
        <DetailsDisplayMenu
          v-if="modalMembers.length !== 0"
          :icon="'mdi-account-group'"
          :details="modalMembers"
          color="grey lighten-2"
          :tooltip-text="$t('meetingScheduler.members')"
        />
        <DetailsDisplayMenu
          v-if="modalFacilities.length !== 0"
          icon="mdi-seat"
          :details="modalFacilities"
          color="orange lighten-4"
          :tooltip-text="$t('meetingScheduler.facilities')"
        />
        <v-row
          v-if="isMeeting && isEventOnCalendarOfLoggedInUser"
          class="px-2 mt-0"
        >
          <v-col cols="10" class="pt-0">
            <v-radio-group v-model="attendanceResponse" row>
              <v-radio :label="$t('meetingScheduler.going')" value="going" />
              <v-radio
                :label="$t('meetingScheduler.notGoing')"
                value="not_going"
              />
              <v-radio
                :label="$t('meetingScheduler.undecided')"
                value="undecided"
              />
            </v-radio-group>
          </v-col>
          <v-spacer />
          <v-col cols="2">
            <v-btn
              v-if="attendanceResponse !== originalAttendanceResponse"
              :loading="isUpdatingAttendance"
              color="primary"
              @click="updateAttendanceResponse"
            >
              {{ $t('common.save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-if="event.isTask && canUpdateTaskStatus" class="pt-0">
        <v-spacer />
        <v-btn outlined @click="updateTaskStatus(event)">
          {{
            event.is_done
              ? $t('tasks.markAsIncomplete')
              : $t('tasks.markAsCompleted')
          }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <confirm ref="confirm" />
  </v-menu>
</template>
<script>
import { mapGetters } from 'vuex';

import Confirm from './Confirm.vue';
import DetailsDisplayElement from './DetailsDisplayElement.vue';
import DetailsDisplayMenu from './DetailsDisplayMenu.vue';
import DeleteOptionsDialog from './DeleteOptionsDialog.vue';
import DetailsToolbar from './Calendar/DetailsToolbar.vue';
import EventTypeChip from './EventTypeChip.vue';

import axiosCancelRequest from '../mixins/axiosCancelRequest';
import calendarAndBookings from '../mixins/calendarAndBookings';
import meetingAttendanceResponse from '../mixins/meetingAttendanceResponse';
import { deleteMeeting, updateAttendance } from '../api/meetings.api';
import { sendGoogleAnalyticsEvent } from '../plugins/vue-gtag';
import calendarAddEdit from '../mixins/calendarAddEdit';

export default {
  components: {
    DeleteOptionsDialog,
    Confirm,
    DetailsToolbar,
    DetailsDisplayElement,
    DetailsDisplayMenu,
    EventTypeChip,
  },
  mixins: [
    calendarAndBookings,
    axiosCancelRequest,
    meetingAttendanceResponse,
    calendarAddEdit,
  ],
  data: () => ({
    userId: null,
    isRecurring: false,
    isEditable: false,
    canBeCopied: false,
    canBeDuplicated: false,
    meetingOrganizerId: null,
    attendanceResponse: null,
    originalAttendanceResponse: null,
    isUpdatingAttendance: false,
  }),
  computed: {
    ...mapGetters([
      'id',
      'userData',
      'event',
      'formExtras',
      'eventMenuPosition',
      'isViewScheduleDetailsMenuOpen',
      'modalDisplay',
      'meetingOrganizer',
      'modalMembers',
      'modalFacilities',
      'tasksDrawer',
      'isLoadingMembers',
      'isLoadingFacilities',
    ]),
    updatedBy() {
      const user = this.userData(this.event.updated_by);
      if (user) {
        return user;
      }
      return null;
    },
    isViewEventDetailsMenuOpen: {
      get() {
        return this.isViewScheduleDetailsMenuOpen;
      },
      set(value) {
        if (value) {
          this.$store.dispatch('openViewScheduleDetailsMenu');
        } else {
          this.$store.dispatch('closeViewScheduleDetailsMenu');
        }
      },
    },
    canUpdateTaskStatus() {
      if (this.event.isTask && this.event.user_id === this.id) {
        return true;
      }
      return false;
    },
    isLoading() {
      if (this.isLoadingMembers || this.isLoadingFacilities) {
        return true;
      }
      return false;
    },
    eventPlace() {
      return this.event === null ||
        this.event.place === null ||
        this.event.place === ''
        ? null
        : this.formatPlace(this.event.place);
    },
    isMeeting() {
      return this.event.meeting_id !== null;
    },
    isDeletable() {
      if (this.isMeeting) {
        if (this.meetingOrganizerId === this.id) {
          return this.isEventOnCalendarOfLoggedInUser;
        }
        return false;
      }
      return this.isEditable;
    },
    isEventOnCalendarOfLoggedInUser() {
      const user = this.userData(this.id);
      if (!user) {
        return false;
      }
      const calendarOfLoggedInUser = user.calendars.find(
        (calendar) => calendar.id === this.event.calendar_id,
      );
      return typeof calendarOfLoggedInUser !== 'undefined';
    },
  },
  watch: {
    event() {
      this.checkIfEditable();
      this.checkIfEventCanBeCopied();
      this.checkIfMeetingCanBeDuplicated();
      this.fetchMembers();
      this.fetchFacilities();
      this.assignDisplayValues();
    },
  },

  mounted () {
    window.addEventListener('keydown', this.keyDownHandler)
  },

  destroyed () {
    window.removeEventListener('keydown', this.keyDownHandler)
  },

  methods: {
    isEventRestricted() {
      if (typeof this.event.subject === 'undefined') {
        return true;
      }
      return false;
    },

    isPrivateTask() {
      if (this.event.isTask && this.event.is_private === 1) {
        return true;
      }
      return false;
    },

    checkIfEventCanBeCopied() {
      if (this.event.meeting_id || this.isEventRestricted()) {
        this.canBeCopied = false;
        return;
      }

      this.canBeCopied = true;
    },

    checkIfMeetingCanBeDuplicated() {
      if (this.event.meeting_id && !this.isEventRestricted()) {
        this.canBeDuplicated = true;
        return;
      }

      this.canBeDuplicated = false;
    },
    async checkIfEditable() {
      this.isEditable = false;

      if (this.isEventRestricted() || this.isMeeting) {
        return;
      }
      let calendarId = null;
      if (this.event.isTask) {
        calendarId = this.userData(this.event.user_id).default_calendar.id;
      } else {
        calendarId = this.event.calendar_id;
      }
      if (calendarId === null) {
        return;
      }
      this.cancelExistingRequests('check-permission');
      await axios
        .get(`api/user/check-permission/calendar/${calendarId}/`, {
          cancelToken: this.getCancelToken('check-permission'),
        })
        .then(() => {
          this.isEditable = true;
        })
        .catch((error) => {
          if (error.response.status === 403) {
            this.isEditable = false;
          }
        });
    },

    async fetchMembers() {
      this.cancelExistingRequests('meeting-scheduler/get_meeting');
      this.$store.dispatch('resetMeetingOrganizerAndMembers');
      let organizerName = null;
      const meetingId = this.event.meeting_id;
      if (meetingId !== null) {
        const { data } = await axios.get(
          `api/meeting-scheduler/get_meeting/${meetingId}`,
          {
            cancelToken: this.getCancelToken('meeting-scheduler/get_meeting'),
          },
        );
        this.meetingOrganizerId = data.created_by;
        organizerName = this.userData(data.created_by).name;
        this.$store.dispatch('setMeetingOrganizer', organizerName);
        await this.$store.dispatch('fetchAndSetModalMembers', meetingId);
        this.isEditable = this.getIsEditableMeeting();
        if (this.isAuthUserAMemberOfMeeting()) {
          this.setMeetingAttendanceResponse();
        }
      }
    },

    getIsEditableMeeting() {
      if (this.meetingOrganizerId === this.id) {
        return this.isEventOnCalendarOfLoggedInUser;
      }
      if (this.isAuthUserAMemberOfMeeting()) {
        return this.isEventOnCalendarOfLoggedInUser;
      }
      return false;
    },

    isAuthUserAMemberOfMeeting() {
      return (
        this.modalMembers &&
        this.modalMembers.some((member) => member.id === this.id)
      );
    },

    setMeetingAttendanceResponse() {
      const attendingStatus = this.modalMembers.find(
        (member) => member.id === this.id,
      );
      this.attendanceResponse = this.getMeetingAttendanceResponse(
        attendingStatus.attending_status,
      );
      this.originalAttendanceResponse = this.attendanceResponse;
    },

    async updateAttendanceResponse() {
      this.isUpdatingAttendance = true;
      try {
        await updateAttendance(
          { id: this.event.meeting_id },
          this.attendanceResponse,
        );
        sendGoogleAnalyticsEvent(
          'updateAttendanceResponseFromViewScheduleModal',
          this.attendanceResponse,
        );
        this.showSuccessSnackbar(this.attendanceResponse);
      } catch {
        this.$store.dispatch('openSnackbar', {
          color: 'error',
          message: this.$t('common.somethingWentWrong'),
        });
      }
      this.isUpdatingAttendance = false;
      this.closeMenu();
      this.$store.dispatch('updateIsRefreshRequired', true);
    },

    async fetchFacilities() {
      this.$store.dispatch('resetFacilities');
      const groupType =
        this.event.meeting_id !== null ? 'App\\Meeting' : 'App\\Event';
      const groupId =
        this.event.meeting_id !== null ? this.event.meeting_id : this.event.id;
      this.$store.dispatch('fetchAndSetModalFacilities', {
        groupType,
        groupId,
      });
    },

    assignDisplayValues() {
      this.$store.dispatch('setModalDisplay', {
        eventTypeId: this.event.event_type_id,
        subject: this.event.subject,
        description: this.event.description,
        start: this.event.start,
        end: this.event.end,
        place: this.event.place,
        createdBy: this.event.created_by
          ? this.userData(this.event.created_by).name
          : '',
        updatedBy: this.event.updated_by
          ? this.userData(this.event.updated_by).name
          : '',
      });
    },

    setDefaultCalendar(id) {
      this.defaultCalendarId = id;
    },

    closeMenu() {
      this.isViewEventDetailsMenuOpen = false;
    },

    editEvent() {
      if (this.event.isTask) {
        this.editTask();
        return;
      }
      const facilitiesId =
        this.event.meeting_id === null
          ? this.modalFacilities.map((facility) => facility.facilityId)
          : [];
      const data = {
        form: this.event,
        dateTimeRange: this.formExtras.formDates,
        defaultCalendar: this.event.calendar_id,
        facilities: facilitiesId,
      };
      this.$emit('open-edit-form', data);
      this.closeMenu();
    },

    editTask() {
      this.isViewEventDetailsMenuOpen = false;
      this.$root.$emit('set-task-to-edit', this.event);
    },

    duplicateMeeting() {
      if (this.event.meeting_id && !this.isEventRestricted()) {
        this.openScheduleMeetingPage();
      }
    },

    openScheduleMeetingPage() {
      this.$router.push({
        name: 'schedule-meeting',
        params: { id: this.event.meeting_id, isEditing: false },
      });
    },

    deleteEvent() {
      if (this.event.isTask) {
        this.deleteTask();
        return;
      }
      if (this.event.recurrence_id || this.event.irregular_id) {
        this.$refs.deleteOptions.dialog = true;
        return;
      }
      if (this.isMeeting) {
        this.confirmDeleteMeeting();
        return;
      }

      this.deleteNormalEvent();
    },

    async confirmDeleteMeeting() {
      const confirmation = await this.$refs.confirm.open(
        this.$t('meetingScheduler.delete'),
        this.$t('meetingScheduler.deleteBody'),
      );
      if (!confirmation) {
        return;
      }
      this.deleteMeeting();
    },

    async deleteMeeting() {
      const { data } = await deleteMeeting(this.event.meeting_id);
      this.$emit('close', { calendars: data });
      this.closeMenu();
    },

    async deleteTask() {
      const confirmation = await this.$refs.confirm.open(
        this.$t('tasks.deleteTask'),
        this.$t('tasks.deleteTaskBody'),
      );
      if (confirmation === true) {
        axios
          .delete(`api/task/${this.event.id}`)
          .then(() => {
            this.$root.$emit('update-user-calendar', this.event.user_id);
            this.updateInTaskDrawer();
            this.closeMenu();
          })
          .catch(() => {
            this.$store.dispatch('openSnackbar', {
              color: 'error',
              message: this.$t('common.somethingWentWrong'),
            });
          });
      }
    },

    deleteGroupedEvent(value) {
      if (this.event.recurrence_id) {
        this.deleteRecurringEvent(value);
      }
      if (this.event.irregular_id) {
        this.deleteIrregularEvent(value);
      }
    },

    deleteIrregularEvent(value) {
      axios
        .delete(
          `/api/events/irregular/${this.event.id}/${parseInt(value, 10)}/`,
        )
        .then((response) => {
          this.closeMenu();
          this.$emit('close', response.data);
        })
        .catch(() => {
          this.$store.dispatch('openSnackbar', {
            color: 'error',
            message: this.$t('common.somethingWentWrong'),
          });
        });
    },

    deleteRecurringEvent(value) {
      switch (value) {
        case '1': {
          axios
            .delete(
              `/api/event-recur/${this.event.recurrence_id}/single/${this.formExtras.formDates.start}`,
            )
            .then((response) => {
              this.closeMenu();
              this.$emit('close', response.data);
            })
            .catch(() => {
              this.$store.dispatch('openSnackbar', {
                color: 'error',
                message: this.$t('common.somethingWentWrong'),
              });
            });
          break;
        }
        case '2': {
          axios
            .delete(
              `/api/event-recur/${this.event.recurrence_id}/further/${this.formExtras.formDates.start}`,
            )
            .then((response) => {
              this.closeMenu();
              this.$emit('close', response.data);
            })
            .catch(() => {
              this.$store.dispatch('openSnackbar', {
                color: 'error',
                message: this.$t('common.somethingWentWrong'),
              });
            });
          break;
        }
        case '3': {
          axios
            .delete(`/api/event-recur/${this.event.recurrence_id}`)
            .then((response) => {
              this.closeMenu();
              this.$emit('close', response.data);
            })
            .catch(() => {
              this.$store.dispatch('openSnackbar', {
                color: 'error',
                message: this.$t('common.somethingWentWrong'),
              });
            });
          break;
        }
        default:
          break;
      }
    },

    async deleteNormalEvent() {
      const confirmation = await this.$refs.confirm.open(
        this.$t('event.delete'),
        this.$t('event.deleteBody'),
      );
      if (confirmation === true) {
        axios
          .delete(`api/events/${this.event.id}`)
          .then((response) => {
            this.closeMenu();
            this.$emit('close', response.data);
          })
          .catch(() => {
            this.$store.dispatch('openSnackbar', {
              color: 'error',
              message: this.$t('common.somethingWentWrong'),
            });
          });
      }
    },

    onClosingDialog(response) {
      this.$emit('close', response);
    },

    userDetails(user) {
      this.userId = user.id;
    },

    copyEvent() {
      this.$emit('copy', this.event);
      this.closeMenu();
    },

    getCalendarName(event) {
      if (event === undefined) {
        return '';
      }
      if (event.isTask) {
        const user = this.userData(event.user_id);
        return this.$t('tasks.userTodo', { username: user.name });
      }
      const calendar = this.formExtras.calendars.find(
        (calendarIterator) => calendarIterator.id === event.calendar_id,
      );
      if (calendar) {
        return this.getCalendarNameByType(calendar);
      }
      return '';
    },

    getDisplaySubject(event) {
      if (typeof event.subject === 'undefined') {
        return this.$t('event.restrictedSubject');
      }
      if (!event.subject) {
        return this.$t('event.noSubject');
      }
      return event.subject;
    },

    updateTaskStatus(task) {
      axios
        .put(`api/task/${task.id}/status`, {
          is_done: !task.is_done,
        })
        .then((response) => {
          this.$root.$emit('update-user-calendar', task.user_id);
          this.updateInTaskDrawer();
          this.$store.dispatch('updateEvent', response.data.task);
          this.isViewEventDetailsMenuOpen = false;
        })
        .catch((error) => {
          error = error.response.data;
          if (error.errorCode) {
            this.$store.dispatch('openSnackbar', {
              color: 'error',
              message: this.$t(`tasks.${error.messageCode}`),
            });
            return;
          }
          this.$store.dispatch('openSnackbar', {
            color: 'error',
            message: this.$t('common.somethingWentWrong'),
          });
        });
    },

    taskStatusIcon(task) {
      if (task.is_done) {
        return 'mdi-undo';
      }
      return 'mdi-check';
    },

    updateInTaskDrawer() {
      this.$root.$emit('tasks-updated-from-calendar');
    },

    keyDownHandler(event) {
      if (event.code === 'Escape') {
        this.closeMenu();
      }
    },
  },
};
</script>
