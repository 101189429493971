<template>
  <v-row
    id="calendar-template"
    class="d-flex flex-nowrap"
    :style="{
      position: 'sticky',
      top: `${$vuetify.application.top}px`,
      'z-index': 3,
    }"
    no-gutters
    justify="start"
  >
    <v-progress-linear
      :indeterminate="isLoadingFew"
      :active="isLoadingFew"
      absolute
      color="#0D47A1"
    />
    <v-col class="text-truncate">
      <v-card
        class="header-borders"
        tile
        :color="bgColorMemberListTitle"
        :min-width="40"
        height="44px"
      >
        <v-card-text
          class="pl-2 pt-1"
          :style="{
            fontSize: headingListTitleFontSize,
            color: 'rgba(0, 0, 0, 0.87)',
          }"
        >
          {{ memberListTitle }}
        </v-card-text>
      </v-card>
    </v-col>
    <v-col v-for="(date, index) in week" :key="index" class="text-truncate">
      <WeekViewDateHeaderCell :date="date" :index="index" />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import WeekViewDateHeaderCell from './WeekViewDateHeaderCell.vue';

export default {
  components: { WeekViewDateHeaderCell },

  props: {
    week: {
      type: Array,
      default() {
        return [];
      },
    },
    isLoadingFew: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    bgColorMemberListTitle: 'rgb(241, 248, 233)',
  }),

  computed: {
    ...mapGetters('groups', ['selectedGroup']),

    memberListTitle() {
      if (this.$route.meta.page === 'facilities') {
        return this.$t('facilityBooking.facilities');
      }
      if (
        this.selectedGroup !== null &&
        this.selectedGroup.groupType === 'team'
      ) {
        return this.$t('common.dateHeaderTeam');
      }
      return this.$t('common.dateHeaderDepartment');
    },

    headingListTitleFontSize() {
      return this.$vuetify.breakpoint.name === 'xs' ? '8px' : '14px';
    },
  },
};
</script>

<style scoped>
.header-borders {
  border-width: 1px 0px 1px 1px !important;
  border-style: solid !important;
  border-color: rgba(0, 0, 0, 0.12) !important;
}
</style>
