<template>
  <v-dialog v-model="dialog" width="350" @input="close">
    <v-card>
      <v-card-title class="headline">
        {{ $t('event.deleteRepeatingEvent') }}
      </v-card-title>

      <v-card-text>
        <v-radio-group v-model="selection" hide-details>
          <v-radio :label="$t('event.deleteOneEvent')" value="1" />
          <v-radio :label="$t('event.deleteFurtherRepeats')" value="2" />
          <v-radio :label="$t('event.deleteAll')" value="3" />
        </v-radio-group>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text @click="close">{{ $t('common.cancel') }}</v-btn>
        <v-btn color="primary" outlined @click="submit">
          {{ $t('common.delete') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    selection: '1',
  }),
  methods: {
    submit() {
      this.$emit('selection', this.selection);
      this.close();
    },
    close() {
      this.dialog = false;
      this.selection = '1';
    },
  },
};
</script>
