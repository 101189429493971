const appVersionHelpers = {
  methods: {
    isappRecentlyUpdated() {
      const previousVersion = localStorage.getItem('schedulerAppVersion');
      if (!previousVersion) {
        return false;
      }
      const [previousMajor, previousMinor] =
        this.getMajorAndMinorVersions(previousVersion);

      const currentVersion = this.$appVersion;
      const [currentMajor, currentMinor] =
        this.getMajorAndMinorVersions(currentVersion);
      return previousMajor !== currentMajor || previousMinor !== currentMinor;
    },
    getMajorAndMinorVersions(versionString) {
      return versionString.split('.');
    },
    showAppUpdatedSnackbar() {
      this.$store.dispatch('openSnackbar', {
        color: 'success',
        timeout: 10000,
        message: this.$t('appUpdateSnackbar.appUpdated'),
      });
    },
    updateAppVersion() {
      localStorage.schedulerAppVersion = this.$appVersion;
    },
  },
};

export default appVersionHelpers;
