function getDefaultState() {
  return {
    userStatus: null,
    membersStatus: [],
    statusTypes: {
      in_office: {
        color: 'green',
        name: 'inOffice',
      },
      telework: {
        color: 'green',
        name: 'telework',
      },
      on_break: {
        color: 'yellow darken-3',
        name: 'onBreak',
      },
      not_in_office: {
        color: 'red',
        name: 'notInOffice',
      },
    },
  };
}

const state = getDefaultState();

const getters = {
  userStatus: (state) => state.userStatus,
  membersStatus: (state) => state.membersStatus,
  statusTypes: (state) => state.statusTypes,
};

const mutations = {
  setUserStatus: (state, payload) => {
    state.userStatus = payload;
  },
  setMembersStatus: (state, payload) => {
    state.membersStatus = [...payload];
  },
  resetStatus: (state) => {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  async fetchAndSetUserStatus(context) {
    const { data } = await axios.get(
      `api/attendance/users/${context.getters.id}`,
    );
    context.commit('setUserStatus', data);
  },
  async patchAndSetUserStatus(context, payload) {
    const { data } = await axios.patch(
      `api/attendance/user/${context.getters.id}/update-status`,
      {
        status: payload.status,
        dateTime: payload.dateTime,
      },
    );
    context.commit('setUserStatus', data);
  },
  async fetchAndSetMembersStatus(context, payload) {
    const { data } = await axios.get('api/attendance/users', {
      params: {
        users: payload,
      },
    });
    context.commit('setMembersStatus', [...data]);
  },
  resetStatus: ({ commit }) => {
    commit('resetStatus');
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
