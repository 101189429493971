<template>
  <v-bottom-sheet v-model="addEditEventSheetMenu" scrollable fullscreen>
    <v-card elevation-0 :ripple="false">
      <add-edit-toolbar
        :title="isEditing ? $t('event.editEvent') : $t('event.addEvent')"
        :is-edit-mode="isEditing"
        :is-paste-possible="
          eventOnClipboard !== null && !isEditing && !disablePaste
        "
        :is-full-screen="true"
        :is-submit-disabled="isSubmitDisabled"
        :is-processing-submit="isProcessingSubmit"
        @clicked-paste="pasteEvent"
        @clicked-close="close"
        @clicked-submit="submit"
      />
      <v-card-text class="pb-0" height="600px">
        <v-container>
          <v-row justify="center">
            <v-form ref="form" lazy-validation style="width: 450px">
              <v-text-field
                v-model="form.subject"
                prepend-icon="mdi-calendar-edit"
                :label="$t('event.subject')"
                maxlength="255"
                style="max-width: 400px"
              />
              <v-divider />
              <v-checkbox
                v-model="form.all_day"
                :label="$t('event.all_day')"
                hide-details
                @change="
                  specifyTime();
                  updateBusyStatus();
                "
              ></v-checkbox>
              <date-time-range-picker
                v-model="dateTimeRange"
                :only-date="form.all_day == 1"
                :error="facilityBookedError"
                :message="errorMessage"
                @error="setSubmitBtnStatus"
                @change="checkValidity"
              />
              <div v-if="!isTaskOptionSelected">
                <v-checkbox
                  v-if="!isEditingRecurringEvent && isRecurrenceAllowed"
                  v-model="form.isRepeating"
                  :disabled="irregularOptionsData.irregularEvent"
                  :label="$t('event.repeatEvent')"
                  class="pa-0 ma-0"
                />
                <div v-if="form.isRepeating">
                  <v-select
                    v-model="repeatOption"
                    :items="repeatOptions"
                    item-text="name"
                    item-value="value"
                    prepend-icon="mdi-format-list-bulleted"
                    outlined
                  ></v-select>
                  <v-text-field
                    v-model="form.count"
                    hide-details
                    single-line
                    clearable
                    prepend-icon="mdi-counter"
                    :disabled="form.until != null"
                    type="number"
                    :label="$t('event.noOfRepeats')"
                    class="pa-0 ma-0"
                  />
                  <v-menu
                    ref="untilDate"
                    v-model="menuUntilDate"
                    :close-on-content-click="true"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-model="form.until"
                        clearable
                        :disabled="form.count != null"
                        :label="$t('event.pickUntil')"
                        prepend-icon="mdi-calendar-range"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.until"
                      no-title
                      first-day-of-week="1"
                      :locale="$i18n.locale"
                      :events="getNationalHolidayEventColor"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="menuUntilDate = false"
                        >{{ $t('common.cancel') }}</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.untilDate.save(form.until)"
                        >{{ $t('common.ok') }}</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </div>
                <v-checkbox
                  v-if="!isEditingRecurringEvent && isRecurrenceAllowed"
                  v-model="irregularOptionsData.irregularEvent"
                  :disabled="form.isRepeating"
                  :label="$t('event.irregularEvent')"
                  class="mt-0"
                />
                <v-menu
                  v-if="irregularOptionsData.irregularEvent == true"
                  ref="irregularEventMenu"
                  v-model="irregularOptionsData.irregularEventMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="irregularOptionsData.irregularDate"
                  transition="scale-transition"
                  offset-x
                  min-width="290px"
                >
                  <template #activator="{ on }">
                    <v-combobox
                      v-model="irregularOptionsData.irregularDates"
                      :label="$t('event.additionalDates')"
                      prepend-icon="mdi-calendar-range"
                      multiple
                      readonly
                      clearable
                      v-on="on"
                    >
                      <template #selection="{ item }">
                        <v-chip
                          close
                          @click:close="removeIrregularDate(item)"
                          @input="save(item)"
                        >
                          {{ getString(item) }}
                        </v-chip>
                      </template>
                    </v-combobox>
                  </template>
                  <v-date-picker
                    v-model="irregularOptionsData.irregularDate"
                    no-title
                    first-day-of-week="1"
                    :locale="$i18n.locale"
                    :allowed-dates="restrictSelectingCurrentDate"
                    :events="miniCalendarEventsForIrregularDateSelection"
                    @input="save(irregularOptionsData.irregularDate)"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="irregularOptionsData.irregularEventMenu = false"
                      >{{ $t('common.ok') }}</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </div>
              <v-row>
                <v-col :cols="canConvertToTask ? 7 : 12" class="pt-5">
                  <event-type-selector
                    v-model="form.event_type_id"
                    :disabled="isTaskOptionSelected"
                  />
                </v-col>
                <v-col v-if="canConvertToTask" cols="3" class="ml-4">
                  <v-checkbox
                    v-model="isTaskOptionSelected"
                    hide-details
                    :label="$t('tasks.title')"
                    @change="setAllDayIfTaskOptionIsSelected"
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
              <v-textarea
                v-model="form.description"
                prepend-icon="mdi-text"
                :label="$t('event.description')"
                auto-grow
                class="pt-4"
                :rows="1"
              />
              <v-text-field
                v-if="!isTaskOptionSelected && form.members.length === 0"
                v-model="form.place"
                prepend-icon="mdi-map-marker"
                :label="$t('event.eventLocation')"
              />
              <MultipleUsersSelector
                v-if="isInvitingMembersEnabled"
                v-model="form.members"
                outlined
                :items="items"
                :assumed-members="assumedMembers"
                :label="$t('meetingScheduler.members')"
              />
              <facilities-selector
                v-if="isFacilitiesAllowed"
                v-model="facilities"
                :facilities-busy="facilitiesBusy"
                @changed-facilities="checkValidity"
              />
              <v-select
                v-if="isEditingRecurringEvent"
                v-model="editOption"
                :items="editOptions"
                item-text="name"
                item-value="value"
                prepend-icon="mdi-format-list-bulleted"
              />
              <div v-if="!isTaskOptionSelected && form.members.length === 0">
                <v-checkbox
                  v-model="form.busy"
                  :label="$t('common.busy')"
                  class="pb-0 mb-0"
                />
                <v-checkbox
                  v-model="form.priority"
                  :label="$t('event.highPriority')"
                  class="pa-0 ma-0"
                />
              </div>
              <v-row no-gutters>
                <v-col>
                  <v-select
                    :value="form.calendar_id"
                    :items="calendars"
                    item-text="name"
                    item-value="id"
                    prepend-icon="mdi-calendar-question"
                    dense
                    :append-icon="''"
                    class="select-no-underline"
                    disabled
                  />
                </v-col>
              </v-row>
              <v-row v-if="calendars.length > 1 && !isMeetingEvent" no-gutters>
                <v-col class="pl-1" cols="4">
                  <v-checkbox
                    v-model="isPrivate"
                    hide-details
                    :label="$t('common.makePrivate')"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-row>
        </v-container>
      </v-card-text>

      <add-edit-actions
        :is-submit-disabled="isSubmitDisabled"
        :is-processing-submit="isProcessingSubmit"
        @clicked-cancel="close"
        @clicked-submit="submit"
      />
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import { parseISO, format } from 'date-fns';
import { enUS, ja } from 'date-fns/locale';
import { mapGetters } from 'vuex';
import { sendGoogleAnalyticsEvent } from '../plugins/vue-gtag';

import DateTimeRangePicker from './DateTimePicker/DateTimeRangePicker.vue';
import AddEditToolbar from './Calendar/AddEditToolbar.vue';
import AddEditActions from './Calendar/AddEditActions.vue';
import EventTypeSelector from './EventTypeSelector.vue';
import FacilitiesSelector from './Selectors/FacilitiesSelector.vue';

import nationalHolidayDatePickerEvent from '../mixins/nationalHolidayDatePickerEvent';
import dateTimeHelpers from '../mixins/dateTimeHelpers';
import tasksMixins from '../mixins/tasksMixins';
import calendarAddEdit from '../mixins/calendarAddEdit';
import MultipleUsersSelector from './MultipleUsersSelector.vue';
import meetingMembersSelectorItems from '../mixins/meetingMembersSelectorItems';

import { createMeeting } from '../api/meetings.api';
import {
  convertNormalEventToRecurring,
  convertNormalEventToMeeting,
} from '../api/events.api';

export default {
  components: {
    DateTimeRangePicker,
    AddEditToolbar,
    AddEditActions,
    EventTypeSelector,
    FacilitiesSelector,
    MultipleUsersSelector,
  },
  mixins: [
    nationalHolidayDatePickerEvent,
    calendarAddEdit,
    dateTimeHelpers,
    tasksMixins,
    meetingMembersSelectorItems,
  ],
  props: {
    disablePaste: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menuAllDayDate: false,
      emailRequired: false,
      menuUntilDate: false,
      selectedUser: {},
      dateTimeRange: {
        start: null,
        end: null,
      },
      repeatOption: 'DAILY',

      form: {
        id: null,
        calendar_id: null,
        recurrence_id: null,
        irregular_id: null,
        event_type_id: null,
        subject: '',
        description: '',
        place: '',
        all_day: true,
        priority: false,
        busy: true,
        isRepeating: false,
        count: null,
        until: null,
        is_private: 0,
        members: [],
      },
      irregularOptionsData: {
        irregularEvent: false,
        irregularEventMenu: false,
        irregularDates: [],
        irregularDate: null,
      },
      repeatDays: [],
      positionX: null,
      positionY: null,

      editOption: 1,
      calendars: [],
      isEditing: false,
      isSubmitDisabled: false,

      facilities: [],
      errorMessage: '',
      owner: null,
      isTaskOptionSelected: false,
      isPrivate: false,
      isProcessingSubmit: false,
      lastSelectedDateTimeRange: {},
      initialDateTimeRange: {},
    };
  },
  computed: {
    ...mapGetters([
      'name',
      'id',
      'email',
      'eventTypes',
      'isAddEditEventSheetOpen',
      'userData',
      'preferences',
      'formExtras',
      'event',
      'eventOnClipboard',
      'eventFacilities',
    ]),
    addEditEventSheetMenu: {
      get() {
        return this.isAddEditEventSheetOpen;
      },
      set(value) {
        if (value) {
          this.$store.dispatch('openAddEditEventSheet');
        } else {
          this.$store.dispatch('closeAddEditEventSheet');
        }
      },
    },
    isSelfCalendar() {
      if (!this.formExtras.defaultCalendar) {
        return false;
      }
      const { calendars } = this.userData(this.id);
      const { defaultCalendar } = this.formExtras;
      if (calendars.find((calendar) => calendar.id === defaultCalendar)) {
        return true;
      }
      return false;
    },
    isRecurrenceAllowed() {
      return this.facilities.length === 0 && this.event.meeting_id === null;
    },
    editOptions() {
      return [
        { name: this.$t('event.editOnlyCurrent'), value: 1 },
        { name: this.$t('event.editAllFurther'), value: 2 },
        { name: this.$t('event.editAll'), value: 3 },
      ];
    },
    repeatOptions() {
      return [
        { name: this.$t('event.daily'), value: 'DAILY' },
        { name: this.$t('event.weekly'), value: 'WEEKLY' },
        { name: this.$t('event.monthly'), value: 'MONTHLY' },
        { name: this.$t('event.yearly'), value: 'YEARLY' },
      ];
    },
    canConvertToTask() {
      if (!this.owner || !this.owner.type === 'user') {
        return false;
      }
      if (this.event.recurrence_id || this.event.irregular_id) {
        return false;
      }
      if (this.form.isRepeating || this.irregularOptionsData.irregularEvent) {
        return false;
      }
      if (this.facilities.length > 0) {
        return false;
      }
      return true;
    },
    privateCalendarId() {
      let calendarId = null;
      this.calendars.forEach((calendar) => {
        if (
          calendar.my_department === Calendar.PRIVATE &&
          calendar.other_department === Calendar.PRIVATE
        ) {
          calendarId = calendar.id;
        }
      });
      return calendarId;
    },
    publicCalendarId() {
      let calendarId = null;
      this.calendars.forEach((calendar) => {
        if (calendar.default_calendar_for_user_id !== null) {
          calendarId = calendar.id;
        }
      });
      return calendarId;
    },
    isFacilitiesAllowed() {
      return !this.isTaskOptionSelected && !this.isRepeatingEvent;
    },

    isEditingRecurringEvent() {
      return (
        this.isEditing && (this.form.recurrence_id || this.form.irregular_id)
      );
    },

    isInvitingMembersEnabled() {
      return (
        this.isTaskOptionSelected === false &&
        this.form.all_day === false &&
        this.isMultiday === false &&
        this.isPrivate === false &&
        this.isSelfCalendar === true &&
        this.isRepeatingEvent === false
      );
    },

    isRepeatingEvent() {
      return (
        this.form.isRepeating || // regular repeat add case
        this.form.recurrence_id !== null || // regular repeat edit case
        this.irregularOptionsData.irregularEvent === true || // irregular repeat add case
        this.form.irregular_id !== null // irregular repeat edit case
      );
    },

    assumedMembers() {
      // If a meeting is created, these members will be automatically added to the meeting
      const authUser = this.items.find(
        (item) => item.id === this.id && item.type === 'user',
      );
      return [authUser];
    },

    isMultiday() {
      return this.dateTimeRangeStartDate !== this.dateTimeRangeEndDate;
    },

    dateTimeRangeStartDate() {
      if (this.dateTimeRange.start === null) {
        return null;
      }
      return this.dateTimeRange.start.split(' ')[0];
    },

    dateTimeRangeStartTime() {
      if (this.dateTimeRange.start === null) {
        return null;
      }
      return this.dateTimeRange.start.split(' ')[1];
    },

    dateTimeRangeEndDate() {
      if (this.dateTimeRange.end === null) {
        return null;
      }
      return this.dateTimeRange.end.split(' ')[0];
    },

    dateTimeRangeEndTime() {
      if (this.dateTimeRange.end === null) {
        return null;
      }
      return this.dateTimeRange.end.split(' ')[1];
    },

    isMeetingEvent() {
      return this.form.meeting_id !== null;
    },
  },
  watch: {
    addEditEventSheetMenu: {
      handler(value) {
        if (value === true) {
          this.openMenu();
        } else {
          this.reset();
        }
      },
    },
    isPrivate(newVal) {
      if (this.isTaskOptionSelected) {
        this.form.is_private = newVal ? 1 : 0;
        return;
      }
      this.form.calendar_id = newVal
        ? this.privateCalendarId
        : this.publicCalendarId;
    },
  },
  methods: {
    close() {
      this.addEditEventSheetMenu = false;
      this.$emit('close');
    },

    miniCalendarEventsForIrregularDateSelection(date) {
      const irregularDateSelected =
        this.irregularOptionsData.irregularDates.find(
          (irrDate) => irrDate === date,
        );
      const colors = [];
      if (irregularDateSelected) {
        colors.push('blue lighten-1');
      }
      colors.push(this.getNationalHolidayEventColor(date));
      return colors;
    },
    restrictSelectingCurrentDate(val) {
      return val != this.dateTimeRange.start.split(' ')[0];
    },
    pasteEvent() {
      this.$emit('paste', this.form.calendar_id, this.dateTimeRange.start);
      this.close();
    },
    openMenu() {
      this.assignFormExtras();
      this.assignFormData();
    },
    reset() {
      this.$data.irregularOptionsData = {
        ...this.$data.irregularOptionsData,
        ...this.$options.data().irregularOptionsData,
      };
      this.$data.dateTimeRange = {
        ...this.$data.dateTimeRange,
        ...this.$options.data().dateTimeRange,
      };
      this.isTaskOptionSelected = false;
    },
    assignFormExtras() {
      this.calendars = this.injectCalendarName(this.formExtras.calendars);
      this.dateTimeRange = {
        ...this.dateTimeRange,
        ...this.formExtras.formDates,
      };
      this.initialDateTimeRange = this.dateTimeRange;
      this.isEditing = this.formExtras.isEditing;
      this.facilities = this.eventFacilities;
      this.owner = this.formExtras.owner;
    },
    assignFormData() {
      this.form = { ...this.form, ...this.event };
      if (Number.isInteger(this.form.all_day)) {
        this.form.all_day = this.form.all_day === 1;
      }
      this.form.calendar_id = this.formExtras.defaultCalendar;
      this.isPrivate = this.isCalendarPrivate(this.form.calendar_id);
    },
    isCalendarPrivate(calendarId) {
      let isPrivate = false;
      this.calendars.forEach((calendar) => {
        if (
          calendar.id === calendarId &&
          calendar.my_department === Calendar.PRIVATE &&
          calendar.other_department === Calendar.PRIVATE
        ) {
          isPrivate = true;
        }
      });
      return isPrivate;
    },
    setSubmitBtnStatus(value) {
      this.isSubmitDisabled = value;
    },
    setFormData(form) {
      Object.assign(this.$data.form, form);
    },
    setDefaultCalendar(calendarId) {
      this.form.calendar_id = calendarId;
    },
    save(date) {
      const index = this.irregularOptionsData.irregularDates.findIndex(
        (x) => x === date,
      );

      if (index === -1) {
        this.irregularOptionsData.irregularDates.push(date);
      } else {
        this.irregularOptionsData.irregularDates.splice(index, 1);
      }
    },
    removeIrregularDate(dateBeingRemoved) {
      this.irregularOptionsData.irregularDates = [
        ...this.irregularOptionsData.irregularDates.filter(
          (date) => date !== dateBeingRemoved,
        ),
      ];
    },
    getString(dateString) {
      const date = parseISO(dateString);
      const locale = this.$i18n.locale === 'en' ? enUS : ja;
      return format(date, 'yyyy/M/d(E)', { locale });
    },
    showSuccessSnackbar(message) {
      this.$store.dispatch('openSnackbar', {
        color: 'success',
        message,
      });
    },

    prepFormData() {
      if (this.irregularOptionsData.irregularEvent) {
        this.irregularOptionsData.irregularDates.forEach((date, i) => {
          this.irregularOptionsData.irregularDates[i] = `${date} 00:00:01`;
        });
      }
      if (this.form.all_day === true) {
        this.dateTimeRange.start = `${this.dateTimeRange.start.slice(
          0,
          10,
        )} 00:00:00`;
        this.dateTimeRange.end = `${this.dateTimeRange.end.slice(
          0,
          10,
        )} 00:00:00`;
      }
    },

    submit() {
      if (!this.isFacilitiesAllowed) {
        this.facilities = [];
      }
      this.isProcessingSubmit = true;
      this.prepFormData();
      if (this.isEditing === false) {
        this.submitAddEvent();
        return;
      }
      this.submitForEdit();
    },

    submitAddEvent() {
      if (this.isTaskOptionSelected) {
        this.addTasksFromCalendar();
        return;
      }
      if (this.form.isRepeating) {
        this.submitAddEventRecur();
        return;
      }
      if (this.isInvitingMembersEnabled && this.form.members.length > 0) {
        this.addMeeting();
        return;
      }
      axios
        .post('api/events', {
          calendar_id: this.form.calendar_id,
          start: this.dateTimeRange.start,
          end: this.dateTimeRange.end,
          event_type_id: this.form.event_type_id,
          all_day: this.form.all_day,
          subject: this.form.subject,
          description: this.form.description,
          place: this.form.place,
          priority: this.form.priority,
          busy: this.form.busy,
          facilities: this.facilities,
          groupType: 'App\\Event',
          groupId: 0,
          user_id: this.id,
          isIrregular: this.irregularOptionsData.irregularEvent,
          irregularDates: this.irregularOptionsData.irregularDates,
        })
        .then((response) => {
          Object.assign(this.$data, this.$options.data());
          const msg = this.$t('event.addResponseSuccess');
          this.showSuccessSnackbar(msg);
          this.$emit('added:event', response.data);
          this.close();
          this.isProcessingSubmit = false;

          sendGoogleAnalyticsEvent(
            'createEventFromAddEditEvent',
            this.form.subject,
          );
        })
        .catch((error) => {
          if (error.response.status === 403) {
            this.$store.dispatch('openSnackbar', {
              color: 'error',
              message: this.$t('event.cannotAdd'),
            });
          } else {
            this.$store.dispatch('openSnackbar', {
              color: 'error',
              message: this.$t('common.somethingWentWrong'),
            });
          }
          this.isProcessingSubmit = false;
        });
    },

    async addMeeting() {
      const members = this.getMeetingMembers();
      const response = await createMeeting(
        this.form.subject,
        this.form.description,
        this.form.event_type_id,
        this.dateTimeRange.start,
        this.dateTimeRange.end,
        members,
        this.facilities,
      );

      sendGoogleAnalyticsEvent(
        'createMeetingFromAddEditEvent',
        this.form.subject,
        members.length,
      );

      Object.assign(this.$data, this.$options.data());
      const msg = this.$t('event.addResponseSuccess');
      this.showSuccessSnackbar(msg, response.data);
      this.$emit('added:meeting', response.data);
      this.close();
      this.isProcessingSubmit = false;
    },

    getMeetingMembers() {
      const members = this.form.members.map((member) => member.id);
      this.assumedMembers.forEach((member) => members.push(member.id));
      return members;
    },

    submitAddEventRecur() {
      axios
        .post('api/event-recur', {
          calendar_id: this.form.calendar_id,
          start: this.dateTimeRange.start,
          end: this.dateTimeRange.end,
          event_type_id: this.form.event_type_id,
          all_day: this.form.all_day,
          subject: this.form.subject,
          description: this.form.description,
          place: this.form.place,
          priority: this.form.priority,
          facilities: this.facilities,
          busy: this.form.busy,
          repeat_count: this.form.count,
          repeat_freq: this.repeatOption,
          repeat_until:
            this.form.until != null
              ? `${this.form.until} ${this.dateTimeRange.end.split(' ')[1]}`
              : this.form.until,
        })
        .then((response) => {
          Object.assign(this.$data, this.$options.data());
          const msg = this.$t('event.addResponseSuccess');
          this.showSuccessSnackbar(msg, response.data);
          this.$emit('added:event', response.data);
          this.close();
          this.isProcessingSubmit = false;
        })
        .catch((error) => {
          if (error.response.status === 403) {
            this.$store.dispatch('openSnackbar', {
              color: 'error',
              message: this.$t('event.cannotAdd'),
            });
          } else {
            this.$store.dispatch('openSnackbar', {
              color: 'error',
              message: this.$t('common.somethingWentWrong'),
            });
          }
          this.isProcessingSubmit = false;
        });
    },

    submitForEdit() {
      // temporal dependency. ifs need to be executed in the written order
      if (this.isTaskOptionSelected) {
        this.convertEventToTask();
        return;
      }
      if (this.form.isRepeating) {
        this.convertToRecurring();
        return;
      }
      if (this.isInvitingMembersEnabled && this.form.members.length > 0) {
        this.convertToMeeting();
        return;
      }
      if (this.form.irregular_id) {
        this.submitEditForIrregularEvent();
        return;
      }
      if (this.form.recurrence_id) {
        this.submitEditForRecurringEvent();
        return;
      }
      this.submitEditEvent();
    },

    convertEventToTask() {
      if (
        this.owner.type === 'department' ||
        this.owner.type === 'team' ||
        this.facilities.length > 0
      ) {
        this.$store.dispatch('openSnackbar', {
          color: 'error',
          message: this.$t('tasks.cannotAddTasksToNonUser'),
        });
        return;
      }
      axios
        .put(`api/events/${this.form.id}/change-to-task`, {
          calendar_id: this.form.calendar_id,
          user_id: this.owner.id,
          subject: this.form.subject,
          description: this.form.description,
          is_done: false,
          facilities: [],
          groupType: 'App\\Event',
          groupId: this.form.id,
          start: this.dateTimeRange.start,
          end: this.dateTimeRange.end,
          all_day: this.form.all_day,
        })
        .then((response) => {
          Object.assign(this.$data, this.$options.data());
          const msg = this.$t('event.addResponseSuccess');
          this.showSuccessSnackbar(msg, response.data);
          this.$emit('added:task', response.data);
          this.close();
          this.refreshTasksDrawer();
          this.isProcessingSubmit = false;
        })
        .catch((error) => {
          this.$store.dispatch('openSnackbar', {
            color: 'error',
            message: this.$t('common.somethingWentWrong'),
          });
          this.isProcessingSubmit = false;
        });
    },

    convertToRecurring() {
      const data = {
        calendar_id: this.form.calendar_id,
        start: this.dateTimeRange.start,
        end: this.dateTimeRange.end,
        event_type_id: this.form.event_type_id,
        all_day: this.form.all_day,
        subject: this.form.subject,
        description: this.form.description,
        place: this.form.place,
        priority: this.form.priority,
        busy: this.form.busy,
        groupType: 'App\\Event',
        groupId: this.form.id,
        user_id: this.id,
        facilities: [], // Facilities cannot be booked with recurring
        repeatCount: this.form.count,
        repeatFrequency: this.repeatOption,
        repeatUntil:
          this.form.until != null
            ? `${this.form.until} ${this.dateTimeRange.end.split(' ')[1]}`
            : this.form.until,
      };
      convertNormalEventToRecurring(this.form.id, data)
        .then((response) => {
          Object.assign(this.$data, this.$options.data());
          const msg = this.$t('event.editResponseSuccess');
          this.showSuccessSnackbar(msg, response.data);
          this.$emit('added:event', response.data);
          this.close();
          this.isProcessingSubmit = false;
        })
        .catch((error) => {
          if (error.response.status === 403) {
            this.$store.dispatch('openSnackbar', {
              color: 'error',
              message: this.$t('event.cannotEdit'),
            });
          } else {
            this.$store.dispatch('openSnackbar', {
              color: 'error',
              message: this.$t('common.somethingWentWrong'),
            });
          }
          this.isProcessingSubmit = false;
        });
    },

    async convertToMeeting() {
      try {
        const members = this.getMeetingMembers();
        const response = await convertNormalEventToMeeting(this.form.id, {
          subject: this.form.subject,
          description: this.form.description,
          eventTypeId: this.form.event_type_id,
          start: this.dateTimeRange.start,
          end: this.dateTimeRange.end,
          members,
          facilities: this.facilities,
        });
        Object.assign(this.$data, this.$options.data());
        const msg = this.$t('event.editResponseSuccess');
        this.showSuccessSnackbar(msg, response.data);
        this.$emit('added:meeting', response.data);
        this.close();
      } catch (e) {
        if (e.response.status === 403) {
          this.$store.dispatch('openSnackbar', {
            color: 'error',
            message: this.$t('event.cannotEdit'),
          });
        } else {
          this.$store.dispatch('openSnackbar', {
            color: 'error',
            message: this.$t('common.somethingWentWrong'),
          });
        }
      } finally {
        this.isProcessingSubmit = false;
      }
    },

    submitEditForIrregularEvent() {
      axios
        .put(`api/events/irregular/${this.form.id}`, {
          ...this.form,
          editType: this.editOption,
          ...this.dateTimeRange,
          facilities: this.facilities,
        })
        .then((response) => {
          Object.assign(this.$data, this.$options.data());
          const msg = this.$t('event.editResponseSuccess');
          this.showSuccessSnackbar(msg, response.data);
          this.$emit('updated:event', response.data);
          this.close();
          this.isProcessingSubmit = false;
        })
        .catch((error) => {
          if (error.response.status === 403) {
            this.$store.dispatch('openSnackbar', {
              color: 'error',
              message: this.$t('event.cannotAdd'),
            });
          } else {
            this.$store.dispatch('openSnackbar', {
              color: 'error',
              message: this.$t('common.somethingWentWrong'),
            });
          }
          this.isProcessingSubmit = false;
        });
    },

    submitEditForRecurringEvent() {
      axios
        .put(`api/event-recur/${this.form.recurrence_id}`, {
          calendar_id: this.form.calendar_id,
          prev_start: this.formExtras.formDates.start,
          prev_end: this.formExtras.formDates.end,
          start: this.dateTimeRange.start,
          end: this.dateTimeRange.end,
          event_type_id: this.form.event_type_id,
          all_day: this.form.all_day,
          subject: this.form.subject,
          description: this.form.description,
          place: this.form.place,
          priority: this.form.priority,
          facilities: this.facilities,
          busy: this.form.busy,
          edit_type: this.editOption,
        })
        .then((response) => {
          Object.assign(this.$data, this.$options.data());
          const msg = this.$t('event.editResponseSuccess');
          this.showSuccessSnackbar(msg, response.data);
          this.$emit('updated:event', response.data);
          this.close();
          this.isProcessingSubmit = false;
        })
        .catch((error) => {
          if (error.response.status === 403) {
            this.$store.dispatch('openSnackbar', {
              color: 'error',
              message: this.$t('event.cannotEdit'),
            });
          } else {
            this.$store.dispatch('openSnackbar', {
              color: 'error',
              message: this.$t('common.somethingWentWrong'),
            });
          }
          this.isProcessingSubmit = false;
        });
    },

    submitEditEvent() {
      axios
        .put(`api/events/${this.form.id}`, {
          calendar_id: this.form.calendar_id,
          start: this.dateTimeRange.start,
          end: this.dateTimeRange.end,
          event_type_id: this.form.event_type_id,
          all_day: this.form.all_day,
          subject: this.form.subject,
          description: this.form.description,
          place: this.form.place,
          priority: this.form.priority,
          busy: this.form.busy,
          facilities: this.facilities,
          groupType: 'App\\Event',
          groupId: this.form.id,
          user_id: this.id,
          isIrregular: this.irregularOptionsData.irregularEvent,
          irregularDates: this.irregularOptionsData.irregularDates,
        })
        .then((response) => {
          Object.assign(this.$data, this.$options.data());
          const msg = this.$t('event.editResponseSuccess');
          this.showSuccessSnackbar(msg, response.data);
          this.$emit('updated:event', response.data);
          this.close();
          this.isProcessingSubmit = false;
        })
        .catch((error) => {
          if (error.response.status === 403) {
            this.$store.dispatch('openSnackbar', {
              color: 'error',
              message: this.$t('event.cannotEdit'),
            });
          } else {
            this.$store.dispatch('openSnackbar', {
              color: 'error',
              message: this.$t('common.somethingWentWrong'),
            });
          }
          this.isProcessingSubmit = false;
        });
    },

    specifyTime() {
      const startDate = this.dateTimeRange.start.slice(0, 10);
      const endDate = this.dateTimeRange.end.slice(0, 10);
      this.dateTimeRange.start = `${startDate} 10:00`;
      this.dateTimeRange.end = this.getDefaultEndTimeAsPerUserPreference(
        `${endDate} 10:00`,
      );
    },

    updateBusyStatus() {
      if (this.isEditing) {
        return;
      }
      if (this.form.all_day) {
        this.form.busy = false;
        return;
      }
      this.form.busy = true;
    },

    addTasksFromCalendar() {
      if (this.owner.type === 'user') {
        this.addTask()
          .then((response) => {
            Object.assign(this.$data, this.$options.data());
            const msg = this.$t('event.addResponseSuccess');
            this.showSuccessSnackbar(msg, response.data);
            this.$emit('added:task', response.data);
            this.close();
            this.refreshTasksDrawer();
            this.isProcessingSubmit = false;
          })
          .catch((error) => {
            this.$store.dispatch('openSnackbar', {
              color: 'error',
              message: this.$t('common.somethingWentWrong'),
            });
            this.isProcessingSubmit = false;
          });
      }
    },

    setAllDayIfTaskOptionIsSelected() {
      if (this.isEditing) {
        return;
      }
      if (this.isTaskOptionSelected) {
        if (this.form.all_day) {
          return;
        }
        if (this.hasDateTimeBeenAltered()) {
          return;
        }
        this.form.all_day = true;
        return;
      }
      this.form.all_day = false;
      this.dateTimeRange = {
        ...this.dateTimeRange,
        ...this.lastSelectedDateTimeRange,
      };
    },

    hasDateTimeBeenAltered() {
      return (
        JSON.stringify(this.initialDateTimeRange) !==
        JSON.stringify(this.dateTimeRange)
      );
    },
  },
};
</script>
