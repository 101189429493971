<template>
  <v-app-bar app clipped-left color="white" :elevation="0">
    <v-app-bar-nav-icon v-if="$vuetify.breakpoint.xsOnly">
      <v-icon class="ma-0 pa-0" @click="setDrawer(!drawer)"> mdi-menu </v-icon>
    </v-app-bar-nav-icon>

    <router-link style="text-decoration: none; color: inherit" to="/week">
      <v-avatar
        v-if="$vuetify.breakpoint.smAndUp"
        size="30"
        tile
        @click="resetSelectedGroup"
      >
        <img :src="iconPath" alt="alt" />
      </v-avatar>
    </router-link>

    <router-link style="text-decoration: none; color: inherit" to="/week">
      <v-toolbar-title
        :class="$vuetify.breakpoint.smAndUp ? ['mr-12', 'ml-5'] : ['pl-0']"
        @click="resetSelectedGroup"
      >
        <span :style="`font-size: ${appNameFontSize}`">
          {{ shortAppName }}
        </span>
      </v-toolbar-title>
    </router-link>

    <template v-if="$vuetify.breakpoint.xsOnly" #extension>
      <v-row class="justify-space-between align-center">
        <DateSelector v-if="isCalendarOrFacilityPage && !isSearchExtended" />
        <calendar-navigation
          v-if="isCalendarOrFacilityPage && !isSearchExtended"
        />
        <calendar-view v-if="isCalendarOrFacilityPage && !isSearchExtended" />
        <schedule-search-bar
          v-if="isCalendarOrSearchPage"
          :style="{
            width: isSearchExtended ? '100%' : '10%',
          }"
          @expand="isSearchExtended = true"
          @collapse="isSearchExtended = false"
        />
      </v-row>
    </template>
    <template v-else-if="$vuetify.breakpoint.smOnly" #extension>
      <calendar-navigation v-if="isCalendarOrFacilityPage" />
      <calendar-view v-if="isCalendarOrFacilityPage" />
      <DateSelector v-if="isCalendarOrFacilityPage" />
      <schedule-search-bar v-if="isCalendarOrSearchPage" />
    </template>

    <template v-if="$vuetify.breakpoint.mdAndUp">
      <calendar-navigation v-if="isCalendarOrFacilityPage" />
      <calendar-view v-if="isCalendarOrFacilityPage" />
      <DateSelector v-if="isCalendarOrFacilityPage" />
      <schedule-search-bar v-if="isCalendarOrSearchPage" />
    </template>

    <v-spacer />

    <v-menu offset-y>
      <template #activator="{ on, attrs }">
        <v-btn class="ml-0" icon v-bind="attrs" v-on="on">
          <v-icon> mdi-help-circle-outline </v-icon>
        </v-btn>
      </template>
      <v-list class="pa-0">
        <v-list-item @click="openReleaseNotes">
          <v-list-item-icon>
            <v-icon> mdi-alert-decagram </v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ $t('toolbar.showReleases') }}
          </v-list-item-title>
        </v-list-item>

        <v-divider />

        <v-list-item ripple @click="openManualInBrowser">
          <v-list-item-icon>
            <v-icon>mdi-text-box-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('toolbar.manual') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider />

        <v-list-item @click="openSendFeedbackModal">
          <v-list-item-icon>
            <v-icon> mdi-message-alert </v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ $t('toolbar.sendFeedback') }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <TheNotificationsBell />
    <v-btn icon @click="toggleTasksDrawer">
      <v-icon> mdi-clipboard-list-outline </v-icon>
    </v-btn>
    <user-menu />
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import TheNotificationsBell from '../TheNotificationsBell/TheNotificationsBell.vue';
import { getManualUrl } from '../../api/miscellaneous.api';

import UserMenu from './UserMenu.vue';
import CalendarNavigation from './CalendarNavigation.vue';
import CalendarView from './CalendarView.vue';
import DateSelector from './DateSelector.vue';
import ScheduleSearchBar from './SchedulesSearchBar.vue';
import { sendGoogleAnalyticsEvent } from '../../plugins/vue-gtag';

export default {
  components: {
    TheNotificationsBell,
    UserMenu,
    CalendarNavigation,
    CalendarView,
    DateSelector,
    ScheduleSearchBar,
  },
  data() {
    return {
      isSearchExtended: false,
    };
  },
  computed: {
    ...mapGetters(['drawer', 'tasksDrawer']),
    shortAppName() {
      return window.App.shortName;
    },
    isCalendarOrFacilityPage() {
      return (
        this.$route.meta.page === 'calendar' ||
        this.$route.meta.page === 'facilities'
      );
    },
    isCalendarOrSearchPage() {
      return (
        this.$route.meta.page === 'calendar' ||
        this.$route.meta.page === 'search-schedules'
      );
    },
    appNameFontSize() {
      return this.$vuetify.breakpoint.xsOnly ? '0.8em' : '1.1em';
    },
    iconPath() {
      return `${process.env.BASE_URL}favicon.ico`;
    },
  },
  methods: {
    ...mapMutations(['setDrawer', 'setTasksDrawer']),
    ...mapActions('groups', ['resetSelectedGroup']),
    updateIsSearchExtended(value) {
      if (this.$vuetify.breakpoint.xsOnly) {
        this.isSearchExtended = value;
      }
    },
    openSendFeedbackModal() {
      this.$store.commit('showSendFeedbackModal');
    },
    openReleaseNotes() {
      this.$router.push({ name: 'releases' });
    },
    async openManualInBrowser() {
      const response = await getManualUrl();
      window.open(response.data);
    },
    toggleTasksDrawer() {
      if (this.tasksDrawer === true) {
        sendGoogleAnalyticsEvent('closeTasksDrawerFromToolbar');
        this.setTasksDrawer(false);
      } else {
        sendGoogleAnalyticsEvent('openTasksDrawerFromToolbar');
        this.setTasksDrawer(true);
      }
    },
  },
};
</script>
