<template>
  <div>
    <v-text-field
      v-model="form.subject"
      autofocus
      :label="$t('common.subject')"
    />
    <v-textarea
      v-model="form.description"
      :label="$t('common.description')"
      auto-grow
      hide-details
      :rows="getNumberOfLinesIn(form.description)"
    />

    <v-checkbox
      v-if="userId === id"
      v-model="form.is_private"
      hide-details
      :label="$t('common.makePrivate')"
    >
    </v-checkbox>

    <v-checkbox
      v-model="needDateTime"
      :label="$t('tasks.addDateTime')"
      hide-details
    />
    <div v-if="needDateTime">
      <v-checkbox
        v-model="form.all_day"
        hide-details
        :label="$t('event.all_day')"
        @change="toggleAllDay"
      />
    </div>
    <date-time-range-picker
      v-if="needDateTime"
      v-model="dateTimeRange"
      :only-date="onlyDate"
      @input="setDates"
      @error="setSubmitBtnStatus"
    />
    <div class="d-flex justify-end pt-2">
      <v-btn text outlined :ripple="false" @click="close()">
        {{ $t('common.close') }}
      </v-btn>

      <v-btn
        class="ml-2"
        color="primary"
        :ripple="false"
        :loading="isProcessingSubmit"
        :disabled="disabled || isProcessingSubmit"
        @click="save()"
      >
        {{ $t('common.save') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DateTimeRangePicker from './DateTimePicker/DateTimeRangePicker.vue';

export default {
  components: {
    DateTimeRangePicker,
  },
  props: {
    isEditing: {
      type: Boolean,
      default: false,
    },
    taskToBeEdited: {
      type: Object,
      default: null,
    },
    userId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      needDateTime: false,
      disabled: false,
      form: {
        subject: '',
        description: '',
        is_done: false,
        all_day: true,
        is_private: 0,
      },
      dateTimeRange: {
        start: `${App.helpers.getISODateString(new Date())} 10:00`,
        end: `${App.helpers.getISODateString(new Date())} 11:00`,
      },
      onlyDate: true,
      isProcessingSubmit: false,
    };
  },
  computed: {
    ...mapGetters(['preferences', 'id']),
  },
  watch: {
    taskToBeEdited: {
      deep: true,
      handler() {
        this.setFormValues(this.taskToBeEdited);
      },
    },
    isEditing() {
      this.setFormValues(this.taskToBeEdited);
    },
  },
  methods: {
    getNumberOfLinesIn(str) {
      if (!str || str === null || str === '') {
        return 1;
      }
      return str.split('\n').length + 1;
    },
    setSubmitBtnStatus(value) {
      this.disabled = value;
    },
    setDates(dates) {
      this.dateTimeRange.start = dates.start;
      this.dateTimeRange.end = dates.end;
    },
    close() {
      this.$emit('close');
      this.resetFormValues();
    },
    save() {
      this.isProcessingSubmit = true;
      this.prepFormForAllDayTasks();
      if (this.isEditing) {
        this.editTask();
        return;
      }
      this.addTask();
    },
    prepFormForAllDayTasks() {
      if (this.needDateTime && this.form.all_day === true) {
        this.dateTimeRange.start = `${this.dateTimeRange.start.slice(
          0,
          10,
        )} 00:00:00`;
        this.dateTimeRange.end = `${this.dateTimeRange.end.slice(
          0,
          10,
        )} 00:00:00`;
      }
    },
    addTask() {
      axios
        .post('api/task', {
          user_id: this.userId,
          subject: this.form.subject,
          description: this.form.description,
          is_done: false,
          all_day: this.needDateTime ? this.form.all_day : null,
          start: this.needDateTime ? this.dateTimeRange.start : null,
          end: this.needDateTime ? this.dateTimeRange.end : null,
          is_private: this.form.is_private,
        })
        .then(() => {
          this.$emit('add');
          this.resetFormValues();
          this.isProcessingSubmit = false;
        })
        .catch(() => {});
    },
    editTask() {
      axios
        .put(`api/task/${this.taskToBeEdited.id}`, {
          user_id: this.userId,
          subject: this.form.subject,
          description: this.form.description,
          is_done: this.form.is_done,
          start: this.needDateTime ? this.dateTimeRange.start : null,
          end: this.needDateTime ? this.dateTimeRange.end : null,
          all_day: this.needDateTime ? this.form.all_day : null,
          is_private: this.form.is_private,
        })
        .then(() => {
          this.$emit('edit');
          this.resetFormValues();
          this.isProcessingSubmit = false;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 404) {
              this.$store.dispatch('openSnackbar', {
                color: 'error',
                timeout: 6000,
                message: this.$t('tasks.cannotEditAsDeleted'),
              });
              this.isProcessingSubmit = false;
            }
          }
        });
    },
    setFormValues(task) {
      if (!task) {
        return;
      }
      if (task.start !== null) {
        this.needDateTime = true;
        if (!task.all_day) {
          this.onlyDate = false;
        } else {
          this.onlyDate = true;
        }
        this.dateTimeRange.start = task.start;
        this.dateTimeRange.end = task.end;
      }
      Object.assign(this.$data.form, task);
    },
    resetFormValues() {
      this.needDateTime = false;
      Object.assign(
        this.$data.dateTimeRange,
        this.$options.data().dateTimeRange,
      );
      this.onlyDate = true;
      Object.assign(this.$data.form, this.$options.data().form);
    },
    specifyTime() {
      this.onlyDate = false;
      this.form.all_day = false;
      this.dateTimeRange.start = `${
        this.dateTimeRange.start.split(' ')[0]
      } 10:00`;
      this.dateTimeRange.end = `${this.dateTimeRange.end.split(' ')[0]} 11:00`;
    },
    toggleAllDay() {
      if (this.form.all_day) {
        this.onlyDate = true;
      } else {
        this.specifyTime();
      }
    },
  },
};
</script>
