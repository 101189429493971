const tasksMixins = {
  methods: {
    addTask() {
      return axios.post('api/task', {
        user_id: this.owner.id,
        subject: this.form.subject,
        description: this.form.description,
        is_done: false,
        start: this.dateTimeRange.start,
        end: this.dateTimeRange.end,
        all_day: this.form.all_day,
        is_private: this.form.is_private,
      });
    },
    refreshTasksDrawer() {
      this.$root.$emit('tasks-updated-from-calendar');
    },
  },
};

export default tasksMixins;
