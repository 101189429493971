<template>
  <v-row class="align-center py-1" no-gutters>
    <v-col cols="1">
      <tooltip-button :icon="icon" :message="tooltipText" />
    </v-col>
    <v-col cols="11">
      <v-badge
        v-for="(detail, index) in details"
        :key="index"
        :icon="iconDisplayed(detail)"
        :value="detail.attending_status === 1 || detail.attending_status === 0"
        :color="colorDisplayed(detail)"
        offset-x="25"
        offset-y="24"
        overlap
        :class="$vuetify.breakpoint.smAndDown ? 'ml-2 mb-1' : ' mr-1 mb-1'"
      >
        <v-btn
          small
          depressed
          rounded
          :color="color"
          :class="
            detail.attending_status === 1 || detail.attending_status === 0
              ? 'pr-8'
              : ''
          "
        >
          {{ `${detail.name} ` }}
        </v-btn>
      </v-badge>
    </v-col>
  </v-row>
</template>

<script>
import TooltipButton from './DisplayMenu/components/TooltipButton.vue';

export default {
  components: { TooltipButton },
  props: {
    icon: {
      type: String,
      default: 'mdi-checkbox-blank-circle',
    },
    details: {
      type: Array,
      required: true,
    },
    color: {
      type: String,
      default: 'grey lighten-2',
    },
    tooltipText: {
      type: String,
      default: 'List',
    },
  },

  methods: {
    iconDisplayed(detail) {
      return detail.attending_status === 1 ? 'mdi-check' : 'mdi-close';
    },

    colorDisplayed(detail) {
      return detail.attending_status === 1 ? 'green' : 'red';
    },
  },
};
</script>
