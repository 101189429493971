<template>
  <v-card-title class="pa-0 py-1 pr-1">
    <v-spacer />
    <v-tooltip v-if="canBeCopied" bottom :disabled="$vuetify.breakpoint.xsOnly">
      <template #activator="{ on }">
        <v-btn icon @click="$emit('clicked-copy')" v-on="on">
          <v-icon size="20">mdi-content-copy</v-icon>
        </v-btn>
      </template>
      <span>{{ $t('viewScheduleCard.copy') }}</span>
    </v-tooltip>
    <v-tooltip
      v-if="isEditable && !isMeeting"
      bottom
      :disabled="$vuetify.breakpoint.xsOnly"
    >
      <template #activator="{ on }">
        <v-btn icon @click="$emit('clicked-edit')" v-on="on">
          <v-icon size="20">mdi-pencil-outline</v-icon>
        </v-btn>
      </template>
      <span>{{ $t('viewScheduleCard.edit') }}</span>
    </v-tooltip>

    <MeetingEditOptionsMenu
      v-else-if="isEditable && isMeeting"
      :id="meetingId"
      @personal-edit="$emit('clicked-edit')"
    />

    <v-menu v-if="overflowOptions.length > 0" bottom left offset-y>
      <template #activator="{ on }">
        <v-btn icon v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, i) in overflowOptions"
          :key="i"
          @click="handleClickActions(item.action)"
        >
          <v-list-item-avatar>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-tooltip bottom :disabled="$vuetify.breakpoint.xsOnly">
      <template #activator="{ on }">
        <v-btn icon @click="$emit('clicked-close')" v-on="on">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
      <span>{{ $t('common.close') }}</span>
    </v-tooltip>
  </v-card-title>
</template>

<script>
import MeetingEditOptionsMenu from '../MeetingEditOptionsMenu.vue';

export default {
  components: {
    MeetingEditOptionsMenu,
  },

  props: {
    isEditable: {
      type: Boolean,
      required: true,
    },

    isDeletable: {
      type: Boolean,
      required: true,
    },

    meetingId: {
      type: Number,
      default: null,
    },

    canBeCopied: {
      type: Boolean,
      default: true,
    },

    canBeDuplicated: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isMeeting() {
      return this.meetingId !== null;
    },

    overflowOptions() {
      const optionsMenu = [];

      if (this.canBeDuplicated) {
        optionsMenu.push({
          title: this.$t('viewScheduleCard.duplicate'),
          icon: 'mdi-content-copy',
          action: 'duplicate',
        });
      }
      if (this.isDeletable) {
        optionsMenu.push({
          title: this.$t('viewScheduleCard.delete'),
          icon: 'mdi-delete-outline',
          action: 'delete',
        });
      }

      return optionsMenu;
    },
  },

  methods: {
    handleClickActions(action) {
      switch (action) {
        case 'duplicate':
          this.$emit('clicked-duplicate');
          break;
        case 'delete':
          this.$emit('clicked-delete');
          break;
        default:
          break;
      }
    },
  },
};
</script>
