import { pasteTask } from '../api/tasks.api';
import { pasteRecurringEvent, pasteEvent } from '../api/events.api';

const eventPasteHandler = {
  methods: {
    async handlePasteTaskEvent(eventId, calendarId, date) {
      try {
        const { data } = await pasteTask(eventId, calendarId, date);
        await this.updateCalendarsInUsersList(data.calendars);
      } catch (error) {
        if (
          error.response.data &&
          error.response.data.error === 'cannot-update-private'
        ) {
          this.$store.dispatch('openSnackbar', {
            color: 'error',
            message: this.$t(`common.${error.response.data.messageCode}`),
          });
          this.updateCalendarsInUsersList(error.response.data.calendars);
          return;
        }
        this.$store.dispatch('openSnackbar', {
          color: 'error',
          message: this.$t('common.cannotCopyTask'),
        });
      }
    },

    async handlePasteRecurringEvent(eventId, calendarId, date) {
      try {
        const { data } = await pasteRecurringEvent(eventId, calendarId, date);
        await this.updateCalendarsInUsersList(data.calendars);
      } catch (error) {
        if (
          error.response.data &&
          error.response.data.error === 'cannot-update-private'
        ) {
          this.$store.dispatch('openSnackbar', {
            color: 'error',
            message: this.$t(`common.${error.response.data.messageCode}`),
          });
          this.updateCalendarsInUsersList(error.response.data.calendars);
          return;
        }
        this.$store.dispatch('openSnackbar', {
          color: 'error',
          message: this.$t('common.somethingWentWrong'),
        });
      }
    },

    async handlePasteEvent(eventId, calendarId, date) {
      try {
        const { data } = await pasteEvent(eventId, calendarId, date);
        await this.updateCalendarsInUsersList(data.calendars);
      } catch (error) {
        if (
          error.response.data &&
          error.response.data.error === 'cannot-update-private'
        ) {
          this.$store.dispatch('openSnackbar', {
            color: 'error',
            message: this.$t(`common.${error.response.data.messageCode}`),
          });
          this.updateCalendarsInUsersList(error.response.data.calendars);
          return;
        }
        this.$store.dispatch('openSnackbar', {
          color: 'error',
          message: this.$t('common.somethingWentWrong'),
        });
      }
    },
  },
};

export default eventPasteHandler;
