const getDefaultState = () => ({
  isAddEventQuickOpen: false,
  isAddEditEventSheetOpen: false,
  addEventQuickTimeoutIds: [],
  isViewScheduleDetailsMenuOpen: false,
  event: {
    id: null,
    calendar_id: null,
    recurrence_id: null,
    irregular_id: null,
    event_type_id: null,
    meeting_id: null,
    subject: '',
    description: '',
    all_day: false,
    priority: false,
    place: '',
    busy: true,
    isRepeating: false,
    count: null,
    until: null,
    updated_by: null,
    updated_at: null,
    start: null,
    end: null,
    user_id: null,
    is_private: 0,
    members: [],
  },
  facilities: [],
  formExtras: {
    defaultCalendar: null,
    calendars: [],
    formDates: [],
    isEditing: false,
    timeRange: [],
    hasTime: false,
    owner: null,
  },
  eventMenuPosition: {
    x: null,
    y: null,
  },
});

const state = getDefaultState();

const getters = {
  isAddEventQuickOpen: (state) => state.isAddEventQuickOpen,

  isAddEditEventSheetOpen: (state) => state.isAddEditEventSheetOpen,

  isViewScheduleDetailsMenuOpen: (state) => state.isViewScheduleDetailsMenuOpen,

  addEventQuickTimeoutIds: (state) => state.addEventQuickTimeoutIds,

  event: (state) => state.event,

  formExtras: (state) => state.formExtras,

  eventMenuPosition: (state) => state.eventMenuPosition,

  eventFacilities: (state) => state.facilities,
};

const mutations = {
  updateIsAddEventQuickOpen: (state, payload) => {
    state.isAddEventQuickOpen = payload;
  },
  updateIsAddEditEventSheetOpen: (state, payload) => {
    state.isAddEditEventSheetOpen = payload;
  },
  updateIsViewScheduleDetailsMenuOpen: (state, payload) => {
    state.isViewScheduleDetailsMenuOpen = payload;
  },
  updateAddEventQuickTimeoutIds: (state, payload) => {
    state.addEventQuickTimeoutIds.push(payload);
  },
  resetAddEventQuickTimeoutIds: (state) => {
    state.addEventQuickTimeoutIds = [];
  },
  updateEvent: (state, payload) => {
    state.event = { ...state.event, ...payload };
  },
  updateFormExtras: (state, payload) => {
    state.formExtras = { ...state.formExtras, ...payload };
  },
  resetAddEditEventFormState: (state) => {
    const s = getDefaultState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  updateEventMenuPosition: (state, payload) => {
    state.eventMenuPosition = { ...state.eventMenuPosition, ...payload };
  },

  updateEventFacilities: (state, payload) => {
    state.facilities = payload;
  },
};

const actions = {
  resetAddEditEventFormState({ commit }) {
    state.addEventQuickTimeoutIds.forEach((id) => {
      clearTimeout(id);
    });
    commit('resetAddEditEventFormState');
  },
  openAddEventQuick: ({ commit }) => {
    commit('updateIsAddEventQuickOpen', false);
    commit('updateIsViewScheduleDetailsMenuOpen', false);
    const timeout = setTimeout(() => {
      commit('updateIsAddEventQuickOpen', true);
      commit('resetAddEventQuickTimeoutIds');
    }, 250);
    commit('updateAddEventQuickTimeoutIds', timeout);
  },
  closeAddEventQuick: ({ commit }) => {
    state.addEventQuickTimeoutIds.forEach((id) => {
      clearTimeout(id);
    });
    commit('resetAddEventQuickTimeoutIds');
    commit('updateIsAddEventQuickOpen', false);
  },
  openAddEditEventSheet: ({ commit }) => {
    commit('updateIsAddEventQuickOpen', false);
    state.addEventQuickTimeoutIds.forEach((id) => {
      clearTimeout(id);
    });
    commit('resetAddEventQuickTimeoutIds');
    setTimeout(() => {
      commit('updateIsAddEditEventSheetOpen', true);
    }, 10);
  },
  closeAddEditEventSheet: ({ commit }) => {
    commit('updateIsAddEditEventSheetOpen', false);
  },
  openViewScheduleDetailsMenu: ({ commit }) => {
    commit('updateIsViewScheduleDetailsMenuOpen', true);
  },
  closeViewScheduleDetailsMenu: ({ commit }) => {
    commit('updateIsViewScheduleDetailsMenuOpen', false);
  },
  appendAddEventQuickTimeoutIds: ({ commit }, payload) => {
    commit('updateAddEventQuickTimeoutIds', payload);
  },
  clearAddEventQuickTimeoutIds: ({ commit }) => {
    commit('resetAddEventQuickTimeoutIds');
  },
  updateEvent: ({ commit }, payload) => {
    commit('updateEvent', payload);
  },
  updateFormExtras: ({ commit }, payload) => {
    commit('updateFormExtras', payload);
  },
  updateEventMenuPosition: ({ commit }, payload) => {
    commit('updateEventMenuPosition', payload);
  },
  updateEventFacilities: ({ commit }, payload) => {
    commit('updateEventFacilities', payload);
  },
  resetEventData: ({ commit }) => {
    commit('updateEvent', getDefaultState().event);
  },
  resetFormExtras: ({ commit }) => {
    commit('updateFormExtras', getDefaultState().formExtras);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
