<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <v-card>
      <v-card-title>
        <span v-if="isEditing === false">{{ $t('team.add') }}</span>
        <span v-else>{{ $t('team.edit') }}</span>
        <v-spacer />
        <v-btn text icon color="grey" @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-text>
          <v-text-field
            v-model="form.name"
            :label="$t('team.name')"
            prepend-icon="mdi-account-group"
            class="required-field"
            required
            :rules="nameRules"
          />

          <v-textarea
            v-model="form.description"
            :label="$t('team.description')"
            prepend-icon="mdi-text"
            auto-grow
            :rows="1"
          />

          <MultipleUsersSelector
            v-model="users"
            :items="items"
            :assumed-members="assumedMembers"
            :label="$t('team.addMembers')"
          />

          <v-alert
            v-if="isEditing && users && users.length === 0"
            type="warning"
          >
            {{ $t('team.deleteIfNoUsers') }}
          </v-alert>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn :disabled="!valid" color="primary" @click="submit">
            {{ $t('common.submit') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>

    <confirm ref="confirm" />
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { sendGoogleAnalyticsEvent } from '../plugins/vue-gtag';
import Confirm from './Confirm.vue';
import MultipleUsersSelector from './MultipleUsersSelector.vue';
import meetingMembersSelectorItems from '../mixins/meetingMembersSelectorItems';

export default {
  components: {
    Confirm,
    MultipleUsersSelector,
  },

  mixins: [meetingMembersSelectorItems],

  data() {
    return {
      valid: true,
      dialog: false,
      users: [],
      form: {
        name: '',
        description: '',
      },
      teamToBeEdited: {},
      isEditing: false,
    };
  },

  computed: {
    ...mapGetters({
      authUserId: 'id',
      selectedUsersFromStore: 'selectedUsers',
    }),

    nameRules() {
      return [(v) => !!v || this.$t('common.required')];
    },

    assumedMembers() {
      // If a team is created, these members will be automatically added to the team
      const authUser = this.items.find(
        (item) => item.id === this.authUserId && item.type === 'user',
      );
      return [authUser];
    },
  },

  methods: {
    openDialog() {
      this.dialog = true;
      this.users = getSelectedUsersForAddFromUsersList(
        this.selectedUsersFromStore,
        this.items,
      );
    },

    closeDialog() {
      Object.assign(this.$data, this.$options.data());
      this.$store.dispatch('emptySelectedUsers');
      this.dialog = false;
    },

    openForEdit(team) {
      this.dialog = true;
      this.isEditing = true;
      this.teamToBeEdited = team;
      this.setFormDataForEdit(team);
    },

    setFormDataForEdit(team) {
      this.form.name = team.name;
      this.form.description = team.description;

      this.users = getSelectedUsersForEditFromUsersList(
        team.users,
        team.name,
        this.items,
      );
      this.$store.dispatch('setSelectedUsersState', this.users);
    },

    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (this.isEditing === false) {
        this.addTeam();
      } else {
        if (this.users.length === 0) {
          const confirmation = await this.$refs.confirm.open(
            this.$t('team.delete'),
            this.$t('team.saveWithNoUsers'),
          );
          if (confirmation === true) {
            this.editTeam();
          }
          return;
        }
        this.editTeam();
      }
    },

    addTeam() {
      axios
        .post('/api/teams', {
          name: this.form.name,
          description: this.form.description,
          users: JSON.stringify(this.users),
        })
        .then(() => {
          this.refreshWithNewData();
          this.$store.dispatch('openSnackbar', {
            message: this.$t('team.created'),
            color: 'success',
            timeout: 2000,
          });
        })
        .catch((error) => {
          this.$store.dispatch('openSnackbar', {
            message:
              error.response.status === 409
                ? this.$t('team.sameNameExists')
                : this.$t('common.somethingWentWrong'),
            color: 'error',
            timeout: 6000,
          });
        });
    },

    editTeam() {
      axios
        .put(`/api/teams/${this.teamToBeEdited.id}`, {
          name: this.form.name,
          description: this.form.description,
          users: JSON.stringify(this.users),
        })
        .then(() => {
          this.refreshWithNewData();
          this.$store.dispatch('openSnackbar', {
            message: 'Team Information Updated',
            color: 'success',
            timeout: 2000,
          });
          sendGoogleAnalyticsEvent(
            'updatedTeamInformation',
            this.teamToBeEdited.id,
          );
        })
        .catch((error) => {
          this.closeDialog();
          if (error.response.status === 403) {
            this.$store.dispatch('openSnackbar', {
              color: 'error',
              message: this.$t('common.unauthorized'),
            });
            return;
          }
          this.$store.dispatch('openSnackbar', {
            color: 'error',
            message: this.$t('common.somethingWentWrong'),
          });
        });
    },

    refreshWithNewData() {
      this.closeDialog();
      this.$emit('update');
      this.$store.dispatch('groups/fetchGroups');
      this.$store.dispatch('fetchAndSetGroupsUsers');
      this.$store.dispatch('emptySelectedUsers');
    },
  },
};

function getSelectedUsersForAddFromUsersList(selectedUsers, items) {
  const selectedUsersMap = new Map();
  selectedUsers.forEach((user) => {
    selectedUsersMap.set(user.id, true);
  });

  const filteredUsersMap = new Map();
  items.forEach((user) => {
    if (selectedUsersMap.has(user.id) && !filteredUsersMap.has(user.id)) {
      filteredUsersMap.set(user.id, user);
    }
  });

  return Array.from(filteredUsersMap.values());
}

function getSelectedUsersForEditFromUsersList(selectedUsers, teamName, items) {
  const selectedUsersMap = new Map();
  selectedUsers.forEach((user) => {
    selectedUsersMap.set(`${user.id}-${user.name}-${teamName}`, true);
  });

  return items.filter((item) =>
    selectedUsersMap.has(`${item.id}-${item.name}-${item.groupName}`),
  );
}
</script>
