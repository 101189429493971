<template>
  <v-row class="align-center py-1" no-gutters>
    <v-col cols="1">
      <tooltip-button :icon="icon" :message="tooltipText" />
    </v-col>
    <v-col class="pl-2" cols="11">
      <span
        v-if="isHtmlView"
        class="text-subtitle-2"
        v-html="sanitizedMessage"
      />
      <span v-else class="text-subtitle-2"> {{ message }} </span>
      <div v-if="subMessage !== null" class="text-caption pt-1">
        {{ subMessage }}
      </div>
    </v-col>
  </v-row>
</template>

<script>
import xss from 'xss';
import TooltipButton from './DisplayMenu/components/TooltipButton.vue';

export default {
  components: { TooltipButton },
  props: {
    icon: {
      type: String,
      default: 'circle',
    },
    message: {
      type: String,
      required: true,
    },
    tooltipText: {
      type: String,
      default: 'Information',
    },
    subMessage: {
      type: String,
      default: null,
    },
    isHtmlView: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    sanitizedMessage() {
      return this.isHtmlView ? xss(this.message) : this.message;
    },
  },
};
</script>
