function getDefaultState() {
  return {
    isGridLoading: false,
    isLinearLoading: false,
    idOfEventBeingDragged: null,
    dropInfo: null,
    isDragSelect: false,
    dragSelectInfo: null,
    clickInfo: null,
    doubleClickInfo: null,
    selectInfo: null,
    eventChipInfo: null,
    nameCellInfo: null,
    moreMenuInfo: null,
    dragDropOptions: null,
    selectedDragDropOption: null,
  };
}

const state = getDefaultState();

const getters = {
  isGridLoading: (state) => state.isGridLoading,
  isGridLinearLoading: (state) => state.isLinearLoading,
  idOfEventBeingDragged: (state) => state.idOfEventBeingDragged,
  dropInfo: (state) => state.dropInfo,
  isDragSelect: (state) => state.isDragSelect,
  dragSelectInfo: (state) => state.dragSelectInfo,
  clickInfo: (state) => state.clickInfo,
  doubleClickInfo: (state) => state.doubleClickInfo,
  selectInfo: (state) => state.selectInfo,
  eventChipInfo: (state) => state.eventChipInfo,
  nameCellInfo: (state) => state.nameCellInfo,
  moreMenuInfo: (state) => state.moreMenuInfo,
  dragDropOptions: (state) => state.dragDropOptions,
  selectedDragDropOption: (state) => state.selectedDragDropOption,
};

const mutations = {
  startEventDrag(state, payload) {
    state.idOfEventBeingDragged = {
      id: payload,
    };
  },
  endEventDrag(state) {
    state.idOfEventBeingDragged = null;
  },
  storeDropInfo(state, payload) {
    state.dropInfo = {
      data: payload.data,
      row: payload.row,
      col: payload.col,
    };
  },
  startDragSelect(state, payload) {
    state.isDragSelect = true;
    state.dragSelectInfo = {
      row: payload.row,
      start: payload.start,
      end: payload.start,
    };
  },
  updateDragSelect(state, payload) {
    state.dragSelectInfo.end = payload;
  },
  showGridLoading(state) {
    state.isGridLoading = true;
  },
  showLinearLoading(state) {
    state.isLinearLoading = true;
  },
  endDragSelect(state) {
    state.isDragSelect = false;
  },

  storeClickInfo(state, payload) {
    state.clickInfo = payload;
  },
  storeDoubleClickInfo(state, payload) {
    state.doubleClickInfo = payload;
  },
  storeSelectInfo(state, payload) {
    state.selectInfo = payload;
  },
  storeClickedEventChipInfo(state, payload) {
    state.eventChipInfo = {
      data: payload.data,
      x: payload.x,
      y: payload.y,
    };
  },
  storeClickedNameCellInfo(state, payload) {
    state.nameCellInfo = payload;
  },
  storeMoreMenuInfo(state, payload) {
    state.moreMenuInfo = {
      date: payload.date,
      x: payload.x,
      y: payload.y,
    };
  },
  storeDragDropOptions(state, payload) {
    state.dragDropOptions = { ...payload };
  },
  storeSelectedDragDropOption(state, payload) {
    state.selectedDragDropOption = payload;
  },
  resetGrid(state) {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  startEventDrag(context, payload) {
    context.commit('startEventDrag', payload);
  },
  endEventDrag(context) {
    context.commit('endEventDrag');
  },
  storeDropInfo(context, payload) {
    context.commit('storeDropInfo', payload);
  },
  startDragSelect(context, payload) {
    context.commit('startDragSelect', payload);
  },
  updateDragSelect(context, payload) {
    context.commit('updateDragSelect', payload);
  },
  endDragSelect(context) {
    context.commit('endDragSelect');
  },
  storeClickInfo(context, payload) {
    context.commit('storeClickInfo', payload);
  },
  storeDoubleClickInfo(context, payload) {
    context.commit('storeDoubleClickInfo', payload);
  },
  storeSelectInfo(context, payload) {
    context.commit('storeSelectInfo', payload);
  },
  showGridLoading(context) {
    context.commit('showGridLoading');
  },
  showLinearLoading(context) {
    context.commit('showLinearLoading');
  },
  storeClickedEventChipInfo(context, payload) {
    context.commit('storeClickedEventChipInfo', payload);
  },
  storeClickedNameCellInfo(context, payload) {
    context.commit('storeClickedNameCellInfo', payload);
  },
  storeMoreMenuInfo(context, payload) {
    context.commit('storeMoreMenuInfo', payload);
  },
  storeDragDropOptions(context, payload) {
    context.commit('storeDragDropOptions', payload);
  },
  storeSelectedDragDropOption(context, payload) {
    context.commit('storeSelectedDragDropOption', payload);
  },
  resetGrid: ({ commit }) => {
    commit('resetGrid');
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
