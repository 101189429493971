import Vue from 'vue';
import VueRouter from 'vue-router';

import WeekView from '../pages/WeekViewCalendar/WeekViewCalendar.vue';
import store from '../store';
import i18n from '../i18n';

import NotFound from '../pages/404.vue';

Vue.use(VueRouter);

const MonthView = () =>
  import(
    /* webpackChunkName: "month-day-view" */ '../pages/MonthViewCalendar.vue'
  );

const Attendance = () =>
  import(
    /* webpackChunkName: "attendance" */ '../pages/Attendance/Attendance.vue'
  );

const MeetingScheduler = () =>
  import(
    /* webpackChunkName: "meeting-scheduler" */ '../pages/MeetingScheduler/MeetingScheduler.vue'
  );

const ScheduleMeeting = () =>
  import(
    /* webpackChunkName: "meeting-scheduler" */ '../pages/ScheduleMeeting/ScheduleMeeting.vue'
  );

const Informations = () =>
  import(
    /* webpackChunkName: "informations" */ '../pages/Informations/Informations.vue'
  );

const FacilityBooking = () =>
  import(
    /* webpackChunkName: "facility-booking" */ '../pages/FacilityBooking/FacilityBooking.vue'
  );

const AdminSettings = () =>
  import(
    /* webpackChunkName: "admin-settings" */ '../pages/AdminSettings/AdminSettings.vue'
  );

const DepartmentSendingInformation = () =>
  import(
    /* webpackChunkName: "admin-settings" */ '../pages/DepartmentSendingInformation/DepartmentSendingInformation.vue'
  );

const EventTypeCrud = () =>
  import(
    /* webpackChunkName: "admin-settings" */ '../pages/EventTypeCrud/EventTypeCrud.vue'
  );

const FacilityCrud = () =>
  import(
    /* webpackChunkName: "admin-settings" */ '../pages/FacilityCrud/FacilityCrud.vue'
  );

const FacilityTypeManagement = () =>
  import(
    /* webpackChunkName: "admin-settings" */ '../pages/FacilityCrud/FacilityTypeManagement.vue'
  );

const CalendarSettingsManagement = () =>
  import(
    /* webpackChunkName: "admin-settings" */ '../pages/CalendarSettingsManagement.vue'
  );

const ManageHolidaysPage = () =>
  import(
    /* webpackChunkName: "admin-settings" */ '../pages/ManageHolidays/ManageHolidaysPage.vue'
  );

const ManageTeams = () =>
  import(/* webpackChunkName: "admin-settings" */ '../pages/ManageTeams.vue');

const UserPreferences = () =>
  import(
    /* webpackChunkName: "user-preferences" */ '../pages/UserPreferences/UserPreferences.vue'
  );

const Releases = () =>
  import(/* webpackChunkName: "user-preferences" */ '../pages/Releases.vue');

const Search = () =>
  import(
    /* webpackChunkName: "user-preferences" */ '../pages/SearchSchedules/SearchResultsPage.vue'
  );

const isAdmin = (to, from, next) => {
  if (store.getters.user.isAdmin === 1) {
    next();
  } else {
    next({ name: 'week' });
  }
};

const routes = [
  {
    path: '/week/',
    name: 'week',
    component: WeekView,
    alias: '/',
    meta: {
      page: 'calendar',
      name: 'Week',
    },
  },
  {
    path: '/month/',
    name: 'month',
    component: MonthView,
    meta: {
      page: 'calendar',
      name: 'Month',
    },
  },
  {
    path: '/day/',
    name: 'day',
    component: MonthView,
    meta: {
      page: 'calendar',
      name: 'Day',
    },
  },
  {
    path: '/attendance/',
    name: 'attendance',
    component: Attendance,
    meta: {
      page: 'attendance',
      name: 'Attendance',
    },
  },
  {
    path: '/facility-booking/week/',
    name: 'facilities-week',
    component: FacilityBooking,
    meta: {
      page: 'facilities',
      name: 'Week',
    },
  },
  {
    path: '/facility-booking/month/',
    name: 'facilities-month',
    component: FacilityBooking,
    meta: {
      page: 'facilities',
      name: 'Month',
    },
  },
  {
    path: '/meeting-scheduler',
    name: 'meeting-scheduler',
    component: MeetingScheduler,
    meta: {
      page: 'meeting-scheduler',
    },
  },
  {
    path: '/meeting-scheduler/schedule/:id',
    name: 'schedule-meeting',
    component: ScheduleMeeting,
    meta: {
      page: 'schedule-meeting',
    },
  },
  {
    path: '/informations',
    name: 'informations',
    component: Informations,
    meta: {
      page: 'informations',
    },
  },
  {
    path: '/preferences',
    name: 'preferences',
    component: UserPreferences,
    meta: {
      page: 'preferences',
      category: 'user',
      name: 'Preferences',
      requiresAuth: true,
    },
  },
  {
    path: '/admin/settings',
    name: 'admin-settings',
    component: AdminSettings,
    meta: {
      page: 'admin-settings',
    },
    beforeEnter: isAdmin,
  },
  {
    path: '/admin/event-type-settings',
    name: 'event-type-settings',
    component: EventTypeCrud,
    meta: {
      page: 'admin-settings',
    },
    beforeEnter: isAdmin,
  },
  {
    path: '/admin/facility-settings',
    name: 'facility-settings',
    component: FacilityCrud,
    meta: {
      page: 'admin-settings',
    },
    beforeEnter: isAdmin,
  },
  {
    path: '/admin/facility-type-management',
    name: 'facility-type-management',
    component: FacilityTypeManagement,
    meta: {
      page: 'admin-settings',
    },
    beforeEnter: isAdmin,
  },
  {
    path: '/admin/department-sending-information',
    name: 'department-sending-information',
    component: DepartmentSendingInformation,
    meta: {
      page: 'admin-settings',
    },
    beforeEnter: isAdmin,
  },
  {
    path: '/admin/national-holidays',
    name: 'national-holidays',
    component: ManageHolidaysPage,
    meta: {
      page: 'national-holidays',
    },
    beforeEnter: isAdmin,
  },
  {
    path: '/admin/calendar-settings',
    name: 'calendar-settings',
    component: CalendarSettingsManagement,
    meta: {
      page: 'calendar-setting',
    },
    beforeEnter: isAdmin,
  },
  {
    path: '/teams',
    name: 'manage-teams',
    component: ManageTeams,
    meta: {
      page: 'manage-teams',
    },
  },
  {
    path: '/releases',
    name: 'releases',
    component: Releases,
    meta: {
      page: 'releases',
    },
  },
  {
    path: '*',
    name: '404',
    component: NotFound,
    meta: {
      page: '404',
    },
  },
  {
    path: '/schedules',
    name: 'search-schedules',
    query: { search: '', page: null },
    component: Search,
    meta: {
      page: 'search-schedules',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    const { page } = to.meta;
    let documentTitle = i18n.t(`documentTitles.${page}`);
    documentTitle = App.helpers.appendAppName(documentTitle);
    document.title = documentTitle;
  });
});

export default router;
