const state = {
  meeting: {
    id: 0,
    date: '',
    start: '',
    end: '',
    event_type_id: null,
    subject: '',
    description: '',
    members: [],
    facilities: [],
  },
  editMeetingEvent: {
    name: '',
    start: '',
    end: '',
    color: '',
  },
  currentMeetingEvent: {
    name: '',
    start: '',
    end: '',
    color: '',
  },
  memberEvents: [],
  facilityEvents: [],
};

const getters = {
  meeting: (state) => state.meeting,

  meetingDate: (state) => state.meeting.date,

  meetingStart: (state) => state.meeting.start,

  meetingEnd: (state) => state.meeting.end,

  meetingEventTypeId: (state) => state.meeting.event_type_id,

  meetingSubject: (state) => state.meeting.subject,

  meetingDescription: (state) => state.meeting.description,

  meetingMembers: (state) => state.meeting.members,

  meetingFacilities: (state) => state.meeting.facilities,

  editMeetingEvent: (state) => state.editMeetingEvent,

  currentMeetingEvent: (state) => state.currentMeetingEvent,
};

const mutations = {
  updateMeeting: (state, payload) => {
    state.meeting = { ...state.meeting, ...payload };
  },

  updateEditMeetingEvent: (state, payload) => {
    state.editMeetingEvent = { ...state.editMeetingEvent, ...payload };
  },

  updateCurrentMeetingEvent: (state, payload) => {
    state.currentMeetingEvent = { ...state.currentMeetingEvent, ...payload };
  },
};

const actions = {
  updateMeeting: ({ commit }, payload) => {
    commit('updateMeeting', payload);
  },

  updateEditMeetingEvent: ({ commit }, payload) => {
    commit('updateEditMeetingEvent', payload);
  },

  updateCurrentMeetingEvent: ({ commit }, payload) => {
    commit('updateCurrentMeetingEvent', payload);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
