import axios from 'axios';
import accountsAppBaseUrl from '../accountsAppBaseUrl';

const httpClient = axios.create({
  baseURL: `${process.env.BASE_URL}api/`,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
});

httpClient.interceptors.response.use(undefined, (error) => {
  if (typeof error.response !== 'undefined') {
    if (error.response.status === 401) {
      const url = new URL(window.location.href);
      const redirectTo = encodeURIComponent(`${url.pathname}${url.search}`);
      window.location = `${accountsAppBaseUrl}?continue=${redirectTo}`;
    }
    if (
      (error.response.status === 403 &&
        error.response.data === 'Scheduler usage forbidden.') ||
      (error.response.status === 500 &&
        error.response.data.message ===
          "User doesn't exist in scheduler database.")
    ) {
      window.location = accountsAppBaseUrl;
    }
  }
  throw error;
});

export default httpClient;
