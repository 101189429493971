export default (() => {
  let accountsPublicPath = '/';
  if (process.env.VUE_APP_BUILD_FOR === 'staging') {
    accountsPublicPath = '/staging-accounts/';
  } else if (process.env.NODE_ENV === 'production') {
    accountsPublicPath = '/accounts/';
  }

  let { origin } = window.location;
  if (process.env.NODE_ENV !== 'production') {
    origin = 'http://localhost:8080';
  }
  return origin + accountsPublicPath;
})();
