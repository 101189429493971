<template>
  <v-autocomplete
    :value="value"
    outlined
    dense
    multiple
    item-text="name"
    item-value="id"
    prepend-icon="mdi-seat"
    :items="facilities"
    :label="$t('selection.facilitySelect')"
    :rules="[() => rules || $t('common.required')]"
    :menu-props="{
      maxHeight: $vuetify.breakpoint.smAndDown ? 150 : 304,
      offsetY: true,
    }"
    @change="$emit('changed-facilities')"
    @input="$emit('input', $event)"
  >
    <template #selection="data">
      <v-chip
        small
        :class="[
          'my-1',
          data.item.status == 'busy' ? 'red white--text' : 'grey lighten-2',
        ]"
        close
        @click:close="removeFacility(data.item.id)"
      >
        {{ data.item.name }}
      </v-chip>
    </template>
    <template #item="data">
      <v-list-item-content>
        <v-list-item-title>
          <v-icon class="mr-2">
            {{
              data.attrs.inputValue === true
                ? 'mdi-checkbox-marked'
                : 'mdi-checkbox-blank-outline'
            }}
          </v-icon>
          {{ data.item.name }}
        </v-list-item-title>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    value: {
      type: Array,
      required: true,
    },
    facilitiesBusy: {
      type: Array,
      default() {
        return [];
      },
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['facility', 'facilitiesViewableByUser']),
    facilities() {
      const choices = [...this.facilitiesViewableByUser];
      for (let i = 0; i < this.value.length; i += 1) {
        const facility = this.facility(this.value[i]);
        const isFacilityInChoices = choices.some(
          (choice) => choice === facility,
        );
        if (!isFacilityInChoices) {
          choices.push(facility);
        }
      }
      if (this.facilitiesBusy === []) {
        return choices;
      }
      const sortedChoicesByFacilityTypeId = this.sortByFacilityTypeId(choices);
      return this.getFacilitiesWithFacilityTypeNameHeaders(
        sortedChoicesByFacilityTypeId,
      );
    },
    rules() {
      return this.required === false ? true : this.value.length > 0;
    },
  },
  methods: {
    removeFacility(id) {
      this.$emit(
        'input',
        this.value.filter((facility) => facility !== id),
      );
      this.$emit('changed-facilities');
    },
    sortByFacilityTypeId(choices) {
      return choices.sort(
        (choice1, choice2) =>
          choice1.facility_type_id - choice2.facility_type_id,
      );
    },
    getFacilitiesWithFacilityTypeNameHeaders(choices) {
      const facilities = [];
      let lastFacilityTypeName = null;
      choices.forEach((facility) => {
        const facilityTypeName = facility.typeName;
        if (facilityTypeName !== lastFacilityTypeName) {
          lastFacilityTypeName = facilityTypeName;
          facilities.push({ divider: true });
          facilities.push({ header: facilityTypeName });
          facilities.push({ divider: true });
        }
        const isFacilityBusy = this.facilitiesBusy.includes(facility.id);
        facilities.push({
          id: facility.id,
          name: facility.name,
          typeName: facilityTypeName,
          status: isFacilityBusy ? 'busy' : 'free',
        });
      });
      return facilities;
    },
  },
};
</script>
