function getDefaultState() {
  return {
    informations: [],
    isErrorInFetchInformations: false,
    isArchivedInformationsShown: false,
    isInformationsTableLoading: false,
    isAddEditInformationDialogOpen: false,
    editInformation: null,
    displayInformation: null,
    copiedInformation: null,
  };
}

const state = getDefaultState();

const getters = {
  informations: (state) => state.informations,
  isErrorInFetchInformations: (state) => state.isErrorInFetchInformations,
  isArchivedInformationsShown: (state) => state.isArchivedInformationsShown,
  isInformationsTableLoading: (state) => state.isInformationsTableLoading,
  isAddEditInformationDialogOpen: (state) =>
    state.isAddEditInformationDialogOpen,
  editInformation: (state) => state.editInformation,
  isDisplayInformationDialogOpen: (state) => state.displayInformation !== null,
  displayInformation: (state) => state.displayInformation,
  isPasteInformationPossible: (state) => state.copiedInformation !== null,
  copiedInformation: (state) => state.copiedInformation,
};

const mutations = {
  setInformations(state, payload) {
    state.informations = [...payload];
  },
  setIsErrorInFetchInformations(state, payload) {
    state.isErrorInFetchInformations = payload;
  },
  setIsArchivedInformationsShown(state, payload) {
    state.isArchivedInformationsShown = payload;
  },
  setIsInformationsTableLoading(state, payload) {
    state.isInformationsTableLoading = payload;
  },
  setIsAddEditInformationDialogOpen(state, payload) {
    state.isAddEditInformationDialogOpen = payload;
  },
  setEditInformation(state) {
    state.editInformation = { ...state.displayInformation };
  },
  resetEditInformation(state) {
    state.editInformation = null;
  },
  setDisplayInformation(state, payload) {
    state.displayInformation = { ...payload };
  },
  resetDisplayInformation(state) {
    state.displayInformation = null;
  },
  setCopiedInformation(state) {
    state.copiedInformation = { ...state.displayInformation };
  },
  reset(state) {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  setIsArchivedInformationsShown(context, payload) {
    context.commit('setIsArchivedInformationsShown', payload);
  },
  async fetchAndSetInformations(context) {
    context.commit('setIsInformationsTableLoading', true);
    try {
      let informations = [];
      context.commit('setIsErrorInFetchInformations', false);
      if (context.state.isArchivedInformationsShown) {
        informations = await axios.get('/api/informations/archived/');
      } else {
        informations = await axios.get('/api/informations/all/');
      }
      context.commit('setInformations', informations.data);
    } catch {
      context.commit('setIsErrorInFetchInformations', true);
    }
    context.commit('setIsInformationsTableLoading', false);
  },
  openAddEditInformationDialog(context) {
    context.commit('setIsAddEditInformationDialogOpen', true);
  },
  closeAddEditInformationDialog(context) {
    context.commit('setIsAddEditInformationDialogOpen', false);
  },
  setEditInformation(context) {
    context.commit('setEditInformation');
  },
  resetEditInformation(context) {
    context.commit('resetEditInformation');
  },
  async fetchAndSetDisplayInformation(context, payload) {
    const { data } = await axios.get(`/api/informations/${payload}`);
    context.commit('setDisplayInformation', { ...data });
  },
  async markInformationAsSeen(context, payload) {
    await axios.patch(`/api/informations/${payload}/mark-as-seen`);
  },
  closeDisplayInformationDialog(context) {
    context.commit('resetDisplayInformation');
  },
  copyInformation(context) {
    context.commit('setCopiedInformation');
  },
  resetAddEditInformation(context) {
    context.commit('reset');
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
