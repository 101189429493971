<template>
  <v-menu offset-y>
    <template #activator="{ on }">
      <v-btn
        text
        :small="$vuetify.breakpoint.xsOnly"
        outlined
        class="pa-0 pl-2 ma-0 text-none"
        v-on="on"
      >
        {{ currentView }}
        <v-icon color="grey darken-1" filled>mdi-menu-down</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in calendarViews"
        :key="index"
        @click="changeView(item.value)"
      >
        <v-list-item-title>{{ item.text }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { sendGoogleAnalyticsEvent } from '../../plugins/vue-gtag';

export default {
  data: () => ({
    selectedView: 'week',
  }),
  computed: {
    currentView() {
      switch (this.$route.name) {
        case 'month':
          return this.$t('calendarViews.month');
        case 'day':
          return this.$t('calendarViews.day');
        case 'week':
          return this.$t('calendarViews.week');
        case 'facilities-week':
          return this.$t('calendarViews.week');
        case 'facilities-month':
          return this.$t('calendarViews.month');
        default:
          return '';
      }
    },

    calendarViews() {
      if (this.$route.meta.page === 'calendar') {
        return [
          { text: this.$t('calendarViews.month'), value: 'month' },
          { text: this.$t('calendarViews.week'), value: 'week' },
          { text: this.$t('calendarViews.day'), value: 'day' },
        ];
      }
      return [
        { text: this.$t('calendarViews.month'), value: 'month' },
        { text: this.$t('calendarViews.week'), value: 'week' },
      ];
    },
  },
  methods: {
    changeView(view) {
      if (this.$route.meta.page === 'facilities') {
        view = `facilities-${view}`;
      }
      sendGoogleAnalyticsEvent(`changeView-${view}`);
      this.$router.push({ name: view, params: this.$route.params });
    },
  },
};
</script>
