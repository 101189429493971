<template>
  <v-card-actions v-if="$vuetify.breakpoint.smAndUp" :style="'margin:auto'">
    <v-btn
      text
      outlined
      color="primary"
      :disabled="isProcessingSubmit"
      @click="$emit('clicked-cancel')"
    >
      {{ $t('common.cancel') }}
    </v-btn>
    <v-btn
      :loading="isProcessingSubmit"
      :disabled="isSubmitDisabled || isProcessingSubmit"
      color="primary"
      @click="$emit('clicked-submit')"
    >
      {{ $t('common.save') }}
    </v-btn>
  </v-card-actions>
</template>

<script>
export default {
  props: {
    isSubmitDisabled: {
      type: Boolean,
      required: true,
    },
    isProcessingSubmit: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
