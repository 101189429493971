<template>
  <div class="d-flex justify-space-between">
    <date-picker v-model="date" :icon="icon" />
    <time-picker
      v-if="!onlyDate"
      v-model="time"
      :base-time="baseTime"
      :multiday="multiday"
      class="pl-2"
    />
  </div>
</template>

<script>
import DatePicker from './DatePicker.vue';
import TimePicker from './TimePicker.vue';

export default {
  components: {
    DatePicker,
    TimePicker,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    baseTime: {
      type: String,
      default: null,
    },
    multiday: {
      type: Boolean,
      default: false,
    },
    onlyDate: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
  },
  computed: {
    date: {
      get() {
        return this.value.slice(0, 10);
      },
      set(value) {
        this.$emit(
          'input',
          this.onlyDate ? `${value} 00:00` : `${value} ${this.time}`,
        );
      },
    },
    time: {
      get() {
        return this.value.slice(11, 16);
      },
      set(value) {
        this.$emit(
          'input',
          this.onlyDate ? `${this.date} 00:00` : `${this.date} ${value}`,
        );
      },
    },
  },
  watch: {
    onlyDate() {
      this.$emit(
        'input',
        this.onlyDate ? `${this.date} 00:00` : `${this.date} ${this.time}`,
      );
    },
  },
};
</script>
