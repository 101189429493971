<template>
  <v-card
    :id="rowIndex + '-0'"
    class="pb-8 ma-0"
    :ripple="false"
    tile
    outlined
    :style="infoCardStyle"
    :min-width="40"
    height="100%"
    width="100%"
  >
    <div :id="rowIndex + ',0,' + 0">
      <span
        :style="infoTextStyle"
        :class="['d-inline-block mt-2', $vuetify.breakpoint.smAndUp && 'ml-2']"
      >
        {{ name }}
      </span>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: '',
    },
    rowIndex: {
      type: Number,
      required: true,
    },
  },
  computed: {
    infoCardStyle() {
      return {
        'background-color': 'rgb(241, 248, 233)',
        'border-width': '0 0 1px 1px',
        cursor: 'default',
      };
    },
    infoTextStyle() {
      const color = this.type === 'user' ? '' : '#0D47A1';
      return {
        width: this.wrapWidth,
        fontSize: this.namesFontSize,
        color,
      };
    },
    wrapWidth() {
      switch (this.breakpoint) {
        case 'xs':
          return `${Math.floor((window.innerWidth * 30) / 300)}px`;
        case 'sm':
          return `${Math.floor((window.innerWidth * 64) / 600)}px`;
        case 'md':
          return `${Math.floor((window.innerWidth * 80) / 960)}px`;
        case 'lg':
          return `${Math.floor((window.innerWidth * 115) / 1264)}px`;
        default:
          return '150px';
      }
    },
    namesFontSize() {
      switch (this.breakpoint) {
        case 'xs':
          return '10px';
        default:
          return '16px';
      }
    },
    breakpoint() {
      return this.$vuetify.breakpoint.name;
    },
  },
};
</script>
