<template>
  <v-dialog v-model="isOpen" max-width="400px">
    <v-card>
      <v-card-title class="text-h5"
        >{{ $t('resourceNotFound.meetingDeletedTitle') }}
      </v-card-title>
      <v-card-text v-if="!!message" class="mt-2">
        {{ message }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click="close">
          {{ $t('common.ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },

    message: {
      type: String,
      default: 'Not found',
    },
  },

  computed: {
    isOpen: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  methods: {
    close() {
      this.$emit('input', false);
    },
  },
};
</script>
