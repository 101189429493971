<template>
  <v-autocomplete
    ref="selector"
    v-model="userORs"
    :search-input.sync="search"
    :items="departmentUsersSelectorChoices"
    item-text="name"
    item-value="id"
    :label="label"
    :menu-props="{ auto: true }"
    :multiple="multiple"
    :prepend-inner-icon="prependInnerIcon"
    dense
    :outlined="needOutline"
    :solo="needSolo"
    :clearable="!isNotClearable && !multiple"
    :hide-details="isRulesRequired == true ? false : true"
    :rules="[() => rulesToCheck(userORs) || $t('common.required')]"
    :filter="filterWithNameOrEmail"
    @change="emitData()"
  >
    <template v-if="multiple" #selection="data">
      <v-chip class="mt-1" small close @click:close="removeUser(data.item)">
        {{ data.item.name }}
      </v-chip>
    </template>
    <template v-if="multiple" #item="data">
      <v-list-item-content>
        <v-list-item-title>
          <v-icon class="mr-2">
            {{
              data.attrs.inputValue === true
                ? 'mdi-checkbox-marked'
                : 'mdi-checkbox-blank-outline'
            }}
          </v-icon>
          {{ data.item.name }}
        </v-list-item-title>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { toAscii } from 'japanese-string-utils';
import { mapGetters } from 'vuex';

export default {
  props: {
    width: {
      type: String,
      default: '100%',
    },
    label: {
      type: String,
      default: 'Members',
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    defaultUser: {
      type: Number,
      required: true,
    },
    prependInnerIcon: {
      type: String,
      default: '',
    },
    isRulesRequired: {
      type: Boolean,
      default: false,
    },
    isNotClearable: {
      type: Boolean,
      default: false,
    },
    needOutline: {
      type: Boolean,
      default: true,
    },
    needSolo: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    userORs: [],
    search: null,
  }),
  computed: {
    ...mapGetters(['departmentUsersSelectorChoices']),
  },
  watch: {
    defaultUser() {
      this.assignDefaultData();
    },
  },
  created() {
    this.assignDefaultData();
  },
  mounted() {
    this.assignDefaultData();
  },
  methods: {
    assignDefaultData() {
      let userORs = [];
      if (this.multiple === true) {
        userORs.push(this.defaultUser);
      } else {
        userORs = this.defaultUser;
      }
      this.userORs = userORs;

      if (this.userORs != null) {
        this.emitData();
      }
    },
    assign(userORs) {
      this.userORs = userORs;
    },
    removeUser(item) {
      this.userORs = this.userORs.filter((user) => user !== item.id);
      this.emitData();
    },
    emitData() {
      this.search = '';
      this.$emit('change', this.userORs);
    },
    validateCompulsoryField() {
      return this.$refs.selector.validate(true);
    },
    rulesToCheck(userORs) {
      if (this.isRulesRequired === true) {
        if (this.multiple === true && userORs.length !== 0) {
          return true;
        }
        if (this.multiple === false && (userORs !== '' || userORs != null)) {
          return true;
        }
        return false;
      }
      return true;
    },
    filterWithNameOrEmail(user, queryText) {
      if (!queryText) {
        return true;
      }
      if (!('id' in user)) {
        return false;
      }
      const queryTextHalfWidth = toAscii(queryText).toLowerCase();
      const userNameHalfWidth = toAscii(user.name).toLowerCase();
      const userEmail = user.email.toLowerCase();

      return (
        userNameHalfWidth.indexOf(queryTextHalfWidth) > -1 ||
        userEmail.indexOf(queryTextHalfWidth) > -1
      );
    },
  },
};
</script>
