<template>
  <v-list-item
    :style="{ background: getNotificationColor(notification) }"
    @click="$emit('click')"
  >
    <TheNotificationsMenuListItemIcon :notification="notification" />

    <v-list-item-content class="py-2">
      <v-list-item-title>
        {{
          notification.data.notification.event.subject
            ? notification.data.notification.event.subject
            : $t('event.noSubject')
        }}
      </v-list-item-title>

      <TheNotificationsMenuListItemSubtitle :notification="notification" />
      <v-list-item-subtitle class="caption font-italic">
        {{ `${getReceivedBeforeTime(notification.created_at)}` }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action class="ma-0">
      <v-list-item-icon class="mr-3">
        <v-btn icon :ripple="false" @click.stop="$emit('click:toggle-read-status')">
          <v-icon small>{{ getReadStatusIcon(notification) }}</v-icon>
        </v-btn>
      </v-list-item-icon>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { ja, enUS } from 'date-fns/locale';
import { formatDistance } from 'date-fns';
import TheNotificationsMenuListItemIcon from './TheNotificationsMenuListItemIcon.vue';
import TheNotificationsMenuListItemSubtitle from './TheNotificationsMenuListItemSubtitle.vue';

export default {
  components: {
    TheNotificationsMenuListItemIcon,
    TheNotificationsMenuListItemSubtitle,
  },

  props: {
    notification: {
      type: Object,
      required: true,
    },
  },

  methods: {
    getNotificationColor(notification) {
      return notification.read_at ? '' : '#BBDEFB';
    },

    getReceivedBeforeTime(dateString) {
      const date = App.helpers.getDateObject(dateString);
      return formatDistance(date, new Date(), {
        locale: this.$i18n.locale === 'en' ? enUS : ja,
        addSuffix: true,
        includeSeconds: true,
      });
    },

    getReadStatusIcon(notification) {
      return notification.read_at ? 'mdi-circle-outline' : 'mdi-circle-slice-8';
    },
  }
};
</script>
