const state = {
  tasksDrawer: false,
};

const getters = {
  tasksDrawer: (state) => state.tasksDrawer,
};

const mutations = {
  setTasksDrawer(state, payload) {
    state.tasksDrawer = payload;
  },
};

export default {
  state,
  getters,
  mutations,
};
