<template>
  <v-menu offset-y close-on-click min-width="200px" max-width="500px">
    <template #activator="{ on }">
      <v-btn icon v-on="on" @click="fetchInformationSeenBy">
        <v-icon>mdi-eye-outline</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="py-2">
        {{ $t('informations.viewers') }} ({{ seenBy.length }})
      </v-card-title>
      <v-card-text>
        <v-chip v-for="user in seenBy" :key="user.name" small class="mr-1 mb-1">
          {{ user.name }}
        </v-chip>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      seenBy: [],
    };
  },
  methods: {
    async fetchInformationSeenBy() {
      const { data } = await axios.get(`api/informations/${this.id}/seen-by/`);
      this.seenBy = [...data];
    },
  },
};
</script>
