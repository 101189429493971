import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: process.env.VUE_APP_PUSHER_APP_KEY,
  authEndpoint: `${process.env.BASE_URL}api/broadcasting/auth`,
  wsHost: process.env.VUE_APP_WEBSOCKETS_HOST,
  wsPort: process.env.VUE_APP_WEBSOCKETS_PORT,
  wssPort: process.env.VUE_APP_WEBSOCKETS_PORT,
  encrypted: true,
  forceTLS: false,
  disableStats: true,
  enabledTransports: ['ws', 'wss'],
});

reconnectWebSocketOnTabFocus();

function reconnectWebSocketOnTabFocus() {
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible' && isWebSocketDisconnected()) {
      window.Echo.connector.connect();
    }
  });
}

function isWebSocketDisconnected() {
  /**
   * https://pusher.com/docs/channels/using_channels/connection/#available-states
   * Reconnect on tab focus for unavailable state instead of waiting 15 seconds.
   */
  switch (window.Echo.connector.pusher.connection.state) {
    case 'disconnected':
    case 'unavailable':
      return true;
    default:
      return false;
  }
}
