import Calendar from '../calendarAttributes';
import store from '../store';

const calendarPermissionMixin = {
  methods: {
    numericalValueForPermissionType(calendarAttribute) {
      switch (calendarAttribute) {
        case Calendar.PRIVATE:
          return 1;

        case Calendar.READ_STATUS:
          return 2;

        case Calendar.READ_DETAILS:
          return 3;

        case Calendar.PUBLIC:
          return 4;

        default:
          return 0;
      }
    },
    canLoggedInUserWriteToUserCalendar(calendarOwner, calendar) {
      if (
        (calendarOwner.type === 'user' &&
          calendarOwner.id === store.getters.id) ||
        calendarOwner.type === 'team'
      )
        return true;
      if (this.isCalendarOwnerOfSameDepartment(calendarOwner)) {
        if (this.numericalValueForPermissionType(calendar.my_department) > 3) {
          return true;
        }
      } else if (
        this.numericalValueForPermissionType(calendar.other_department) > 3
      ) {
        return true;
      }
      return false;
    },
    canLoggedInUserWriteToDepartmentCalendar(departmentId, calendar) {
      if (this.doesUserBelongToDepartment(departmentId)) {
        if (this.numericalValueForPermissionType(calendar.my_department) > 3) {
          return true;
        }
      } else if (
        this.numericalValueForPermissionType(calendar.other_department) > 3
      ) {
        return true;
      }
      return false;
    },
    isCalendarOwnerOfSameDepartment(calendarOwner) {
      if (
        calendarOwner.type === 'user' &&
        this.isUserOfSameDepartment(calendarOwner)
      ) {
        return true;
      }
      if (
        calendarOwner.type === 'department' &&
        store.getters.department === calendarOwner.id
      ) {
        return true;
      }
      return false;
    },
    doesUserBelongToDepartment(departmentId) {
      const authUserDepartmentIds = store.getters.allDepartmentIds;
      if (authUserDepartmentIds.some((deptId) => deptId === departmentId)) {
        return true;
      }
      return false;
    },
    isUserOfSameDepartment(userTo) {
      const authUserDepartmentIds = store.getters.allDepartmentIds;
      let subjectUserDepartmentIds = [];
      if (userTo.additional_departments) {
        const subjectUserAdditionalDepartments = [
          ...userTo.additional_departments,
        ];
        subjectUserDepartmentIds = subjectUserAdditionalDepartments.map(
          (department) => department.id,
        );
      }
      subjectUserDepartmentIds.splice(0, 0, userTo.department_id);
      if (
        subjectUserDepartmentIds.some((subjectDeptId) =>
          authUserDepartmentIds.includes(subjectDeptId),
        )
      ) {
        return true;
      }
      return false;
    },
  },
};

export default calendarPermissionMixin;
