import { normalize, schema } from 'normalizr';
import i18n from '../../i18n';

function appendDefaultEventType(eventTypes) {
  const defaultEventType = {
    id: null,
    name: i18n.t('eventType.none'),
    color: '#CFD8DC',
  };
  eventTypes.unshift(defaultEventType);
  return eventTypes;
}

const state = {
  eventTypeIds: [],
  eventTypes: [],
};

const getters = {
  eventTypes: (state) => {
    const { eventTypes } = state;
    const eventTypesCopy = [...eventTypes];
    return appendDefaultEventType(eventTypesCopy);
  },
  eventTypesWithoutNull: (state) => state.eventTypes,
  eventTypeIds: (state) => state.eventTypeIds,
  normalizedEventTypes: (state) => {
    const eventTypes = new schema.Entity('eventTypes');
    const mySchema = [eventTypes];
    const normalizedEventTypes = normalize(state.eventTypes, mySchema);
    return normalizedEventTypes.entities.eventTypes;
  },
  defaultEventType: () => ({
    id: null,
    name: i18n.t('eventType.none'),
    color: '#CFD8DC',
  }),
};

const mutations = {
  updateEventTypes: (state, event) => {
    state.eventTypes = event;
  },
  updateEventTypeIds: (state, event) => {
    state.eventTypeIds = event;
  },
};

const actions = {
  async fetchEventTypes(context) {
    const response = await axios.get('api/event-types');
    context.commit('updateEventTypes', response.data);
  },
  updateEventTypes(context, payload) {
    context.commit('updateEventTypes', payload);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
