<template>
  <v-select
    :value="value"
    :items="eventTypes"
    :menu-props="{ auto: true }"
    :disabled="disabled"
    item-text="name"
    item-value="id"
    outlined
    hide-details
    dense
    prepend-icon="mdi-calendar-star"
    :label="$t('event.chooseEventType')"
    @input="$emit('input', $event)"
  >
    <template #selection="{ item }">
      <v-chip small :style="eventTypeStyle(item.color)">{{ item.name }}</v-chip>
    </template>
    <template #item="{ item }">
      <v-list-item-content
        class="mb-1 pl-4"
        :style="`${eventTypeStyle(item.color)}; border-radius: 5px`"
      >
        {{ item.name }}
      </v-list-item-content>
    </template>
  </v-select>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    value: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['eventTypes']),
  },
  methods: {
    eventTypeStyle(color) {
      const background = `background-color: ${color}`;
      const text = `color: ${this.$root.getTextColor(color)}`;
      return `${background}; ${text}`;
    },
  },
};
</script>
