<template>
  <div id="calendar-grid" ref="calendarGrid">
    <WeekViewDateHeader
      :key="$route.path"
      :week="currentWeek"
      :is-loading-few="isLoadingFew"
    />

    <WeekSkeletonLoader
      v-if="isLoading && isFirstLoad"
      :count="selectedUsersNum ? selectedUsersNum + 1 : 0"
    />
    <div v-else-if="!isLoading && usersList.length === 0" class="text-center">
      {{ $t('common.emptyUsersList') }}
    </div>
    <div v-else id="user-schedule-grid" style="position: static">
      <div v-for="(rowData, rowIndex) in usersList" :key="rowIndex">
        <WeekViewCalendarGridRow
          :row-data="[...rowData]"
          :row-index="rowIndex"
          :week-header="currentWeek"
          :is-grid-loading="isLoading"
          :is-dragging="isDragging"
          :drag-row-index="dragRowIndex"
          :drag-start-cell-index="dragStartCellIndex"
          :drag-end-cell-index="dragEndCellIndex"
          :highlight-cells="getHighlightedRowCells(rowIndex)"
          @start-drag="startCalendarGridDrag($event, rowIndex)"
          @modify-drag="continueDrag($event, rowIndex)"
          @end-drag="endCalendarGridDrag($event, rowIndex)"
          @cell-double-click="cellDoubleClick($event, rowIndex)"
          @cell-item-dragged="dragEvent($event, rowIndex)"
          @cell-item-dropped="dropEvent($event, rowIndex)"
          @event-click="emitEventClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import WeekViewDateHeader from '../../../components/WeekViewDateHeader.vue';
import WeekSkeletonLoader from '../../../components/Calendar/WeekSkeletonLoader.vue';
import WeekViewCalendarGridRow from './WeekViewCalendarGridRow.vue';

export default {
  components: {
    WeekViewDateHeader,
    WeekSkeletonLoader,
    WeekViewCalendarGridRow,
  },
  props: {
    usersList: {
      type: Array,
      default: () => [],
    },
    currentWeek: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isLoadingFew: {
      type: Boolean,
      default: false,
    },
    isFirstLoad: {
      type: Boolean,
      default: false,
    },
    selectedUsersNum: {
      type: Number,
      default: 10,
    },
    highlightRowsCells: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      isDragging: false,
      dragRowIndex: null,
      dragStartCellIndex: null,
      dragEndCellIndex: null,
      eventBeingDragged: null,
      dragEventRow: null,
      dragEventCell: null,
    };
  },
  computed: {
    ...mapGetters(['isNationalHoliday']),
    namesFontSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return '10px';
        default:
          return '16px';
      }
    },
  },
  methods: {
    getHighlightedRowCells(rowIndex) {
      if (this.highlightRowsCells.length === 0) {
        return [];
      }
      const filteredRowCells = this.highlightRowsCells.find(
        (rowCells) => rowCells.row === rowIndex,
      );
      return filteredRowCells !== undefined ? filteredRowCells.cells : [];
    },
    startCalendarGridDrag(data, rowIndex) {
      this.isDragging = true;
      this.dragRowIndex = rowIndex;
      this.dragStartCellIndex = data.cellIndex;
      this.dragEndCellIndex = data.cellIndex;
    },
    continueDrag(data, rowIndex) {
      if (this.isDragging === true) {
        this.dragEndCellIndex = data.cellIndex;
      }
    },
    endCalendarGridDrag(data, rowIndex) {
      this.dragEndCellIndex = data.cellIndex;
      if (this.isDragging === false) {
        return;
      }
      this.isDragging = false;

      if (this.dragStartCellIndex === this.dragEndCellIndex) {
        this.$emit('single-click', {
          row: this.dragRowIndex,
          cell: this.dragStartCellIndex,
          mouseEvent: data.mouseEvent,
        });
        return;
      }

      this.$emit('drag-select', {
        row: this.dragRowIndex,
        startCell: this.dragStartCellIndex,
        endCell: this.dragEndCellIndex,
        mouseEvent: data.mouseEvent,
      });
    },
    cellDoubleClick(cellIndex, rowIndex) {
      this.$emit('double-click', { row: rowIndex, cell: cellIndex });
    },
    dragEvent(data, rowIndex) {
      this.eventBeingDragged = data.event;
      this.dragEventRow = rowIndex;
      this.dragEventCell = data.cellIndex;
    },
    dropEvent(data, rowIndex) {
      this.$emit('drop-event', {
        initialRow: this.dragEventRow,
        initialCell: this.dragEventCell,
        finalRow: rowIndex,
        finalCell: data.cellIndex,
        draggedEvent: this.eventBeingDragged,
        droppedEvent: data.event,
      });
      this.eventBeingDragged = null;
      this.dragEventRow = null;
      this.dragEventCell = null;
    },
    emitEventClick(eventDetails, user, nativeEvent) {
      this.$emit('event-click', eventDetails, user, nativeEvent);
    },
  },
};
</script>
