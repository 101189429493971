var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-tooltip',{attrs:{"bottom":"","open-delay":500,"color":"#000000","disabled":_vm.$vuetify.breakpoint.xsOnly,"open-on-hover":_vm.$vuetify.breakpoint.smAndUp},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-card',_vm._g({ref:"eventChip",class:{
        shake: _vm.isEventToBeOpened,
        caption: true,
      },style:(_vm.getEventChipStyle(_vm.schedule)),attrs:{"id":'event' + _vm.schedule.id,"flat":""},on:{"mousedown":function($event){return $event.stopPropagation()},"click":_vm.displayEvent}},on),[_c('div',{class:[
          _vm.$vuetify.breakpoint.xsOnly ? 'pl-0' : 'pl-1',
          'noselect',
          _vm.isAttendingStatusNotGoing(_vm.schedule) ||
          _vm.isAttendingStatusMaybe(_vm.schedule)
            ? 'isNotGoingOrMaybe'
            : '',
        ],style:(_vm.cardDivStyle)},[(_vm.isHighPriority)?_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" mdi-star ")]):_vm._e(),(_vm.isTaskDone)?_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-checkbox-marked-circle-outline ")]):_vm._e(),(_vm.isTaskNotDone)?_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-circle-outline ")]):_vm._e(),(_vm.isTimeDisplayed)?_c('strong',{class:{
            'text-decoration-line-through':
              _vm.isAttendingStatusNotGoing(_vm.schedule),
          }},[_vm._v(" "+_vm._s(_vm.schedule.timeString)+" ")]):_vm._e(),_c('span',{class:{
            'text-decoration-line-through':
              _vm.isAttendingStatusNotGoing(_vm.schedule),
          },style:({ fontSize: _vm.subjectFontSize })},[_vm._v(" "+_vm._s(_vm.subjectString)+" ")])],1)])]}}])},[_vm._v(" "+_vm._s(_vm.timeDurationString(_vm.schedule))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.schedule.subject)+" "),(!!_vm.schedule.place)?_c('span',[_vm._v("("+_vm._s(_vm.schedule.place)+")")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }