<template>
  <v-card
    tile
    dark
    :color="backgroundColor"
    :min-width="40"
    height="44px"
    class="text-center text-truncate body-2 header-borders"
  >
    <div
      :style="{
        fontSize: dayTextFontSize,
        color: textColor,
      }"
    >
      {{ getDayText }}
    </div>
    <div
      :style="{
        fontSize: dateTextFontSize,
        color: textColor,
      }"
    >
      {{ getDateText }}
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { isToday, isSunday, isSaturday, isFirstDayOfMonth } from 'date-fns';
import fgColourMixin from '../mixins/foregroundColour';

export default {
  mixins: [fgColourMixin],

  props: {
    date: {
      type: Date,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    ...mapGetters(['nationalHolidays']),

    isHoliday() {
      return this.getHoliday !== undefined;
    },

    getHoliday() {
      const isoDate = App.helpers.getISODateString(this.date);
      return this.nationalHolidays.find((holiday) => holiday.date === isoDate);
    },

    getDayText() {
      const day = this.$i18n.d(this.date, 'weekViewHeaderDay');
      return this.isHoliday ? `${day} ${this.getHoliday.name}` : day;
    },

    getDateText() {
      return this.index === 0 || isFirstDayOfMonth(this.date)
        ? this.$i18n.d(this.date, 'weekViewHeaderWithMonth')
        : this.date.getDate();
    },

    backgroundColor() {
      if (isToday(this.date)) {
        return 'primary';
      }
      if (isSunday(this.date) || this.isHoliday) {
        return '#EF9A9A';
      }
      if (isSaturday(this.date)) {
        return '#BBDEFB';
      }
      return '#FFFFFF';
    },

    textColor() {
      return this.getTextColor(this.backgroundColor);
    },

    dayTextFontSize() {
      return this.$vuetify.breakpoint.name === 'xs' ? '8px' : '12px';
    },

    dateTextFontSize() {
      return this.$vuetify.breakpoint.name === 'xs' ? '12px' : '19px';
    },
  },
};
</script>
