<template>
  <span>
    <v-btn
      text
      :small="$vuetify.breakpoint.xsOnly"
      icon
      class="pa-0 ma-0"
      @click="updateCurrentDateToPreviousPeriod"
    >
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>

    <v-btn
      text
      :small="$vuetify.breakpoint.xsOnly"
      outlined
      class="pa-0 ma-0 text-none"
      @click="updateCurrentDateToToday"
    >
      <span>{{ $t('common.today') }}</span>
    </v-btn>

    <v-btn
      text
      :small="$vuetify.breakpoint.xsOnly"
      icon
      @click="updateCurrentDateToNextPeriod"
    >
      <v-icon>mdi-chevron-right</v-icon>
    </v-btn>
  </span>
</template>

<script>
import axiosCancelRequests from '../../mixins/axiosCancelRequest';
import { sendGoogleAnalyticsEvent } from '../../plugins/vue-gtag';

export default {
  mixins: [axiosCancelRequests],
  methods: {
    updateCurrentDateToPreviousPeriod() {
      this.cancelExistingRequests();
      switch (this.$route.meta.name) {
        case 'Day':
          sendGoogleAnalyticsEvent('updateCurrentDateToPreviousDay');
          this.$store.dispatch('updateCurrentDateToPreviousDay');
          break;
        case 'Week':
          sendGoogleAnalyticsEvent('updateCurrentDateToPreviousWeek');
          this.$store.dispatch('updateCurrentDateToPreviousWeek');
          break;
        case 'Month':
          sendGoogleAnalyticsEvent('updateCurrentDateToPreviousMonth');
          this.$store.dispatch('updateCurrentDateToPreviousMonth');
          break;
        default:
          sendGoogleAnalyticsEvent('updateCurrentDateToPreviousWeek');
          this.$store.dispatch('updateCurrentDateToPreviousWeek');
          break;
      }
    },
    updateCurrentDateToNextPeriod() {
      this.cancelExistingRequests();
      switch (this.$route.meta.name) {
        case 'Day':
          sendGoogleAnalyticsEvent('updateCurrentDateToNextDay');
          this.$store.dispatch('updateCurrentDateToNextDay');
          break;
        case 'Week':
          sendGoogleAnalyticsEvent('updateCurrentDateToNextWeek');
          this.$store.dispatch('updateCurrentDateToNextWeek');
          break;
        case 'Month':
          sendGoogleAnalyticsEvent('updateCurrentDateToNextMonth');
          this.$store.dispatch('updateCurrentDateToNextMonth');
          break;
        default:
          sendGoogleAnalyticsEvent('updateCurrentDateToNextDay');
          this.$store.dispatch('updateCurrentDateToNextDay');
          break;
      }
    },
    updateCurrentDateToToday() {
      this.cancelExistingRequests();
      sendGoogleAnalyticsEvent('updateCurrentDateToToday');
      this.$store.dispatch('updateCurrentDateToToday');
    },
  },
};
</script>
