const state = {
  eventOnClipboard: null,
};

const getters = {
  eventOnClipboard: (state) => state.eventOnClipboard,
};

const mutations = {
  updateEventOnClipboard: (state, event) => {
    state.eventOnClipboard = event;
  },
};

const actions = {
  setEventOnClipboard(context, payload) {
    context.commit('updateEventOnClipboard', payload);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
