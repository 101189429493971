const state = {
  snackbar: {
    open: false,
    color: null,
    timeout: 3000,
    message: null,
  },
};

const getters = {
  isSnackbarOpen: (state) => state.snackbar.open,
  snackbar: (state) => state.snackbar,
};

const mutations = {
  openSnackbarMutation: (state, payload) => {
    state.snackbar.open = true;
    state.snackbar.color = payload.color ? payload.color : 'error';
    state.snackbar.timeout = payload.timeout ? payload.timeout : 3000;
    state.snackbar.message = payload.message ? payload.message : '';
  },
  closeSnackbarMutation: (state) => {
    state.snackbar.open = false;
    state.snackbar.color = null;
    state.snackbar.timeout = 3000;
    state.snackbar.message = null;
  },
};

const actions = {
  openSnackbar: ({ commit }, payload) => {
    commit('openSnackbarMutation', payload);
  },
  closeSnackbar: ({ commit }) => {
    commit('closeSnackbarMutation');
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
