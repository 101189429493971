import { mapGetters } from 'vuex';

function addEventListenerToStopRefreshWhenHidden(stopRefreshWhenHidden) {
  document.addEventListener('visibilitychange', stopRefreshWhenHidden);
}

function removeEventListenerToStopRefreshWhenHidden(stopRefreshWhenHidden) {
  document.removeEventListener('visibilitychange', stopRefreshWhenHidden);
}

const dataRefresher = {
  data() {
    return {
      fetchData: null,
      refresher: null,
    };
  },

  computed: {
    ...mapGetters(['isRefreshRequired']),
  },

  watch: {
    isRefreshRequired() {
      if (this.isRefreshRequired) {
        this.fetchData();
        this.$store.dispatch('updateIsRefreshRequired', false);
      }
    },
  },

  beforeDestroy() {
    this.stopRefreshing();
    removeEventListenerToStopRefreshWhenHidden(this.stopRefreshWhenHidden);
  },

  methods: {
    setUpRefresher(fetchData) {
      this.fetchData = fetchData;
      this.startRefreshing();
      addEventListenerToStopRefreshWhenHidden(this.stopRefreshWhenHidden);
    },

    startRefreshing() {
      const waitTimeInSeconds = 60;
      if (this.refresher !== null) {
        this.stopRefreshing();
      }
      this.refresher = setInterval(() => {
        this.fetchData();
        this.$store.dispatch('fetchAndSetUserStatus');
      }, waitTimeInSeconds * 1000);
    },

    stopRefreshing() {
      clearInterval(this.refresher);
      this.refresher = null;
    },

    stopRefreshWhenHidden() {
      if (document.hidden) {
        this.stopRefreshing();
      } else {
        this.fetchData();
        this.startRefreshing();
      }
    },
  },
};

export default dataRefresher;
