<template>
  <v-dialog v-model="isOpen" width="500">
    <v-card shaped>
      <v-card-title class="pt-3 pr-4">
        <v-spacer />
        <v-btn text icon color="grey" @click="isOpen = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-img contain height="250" :src="imagePath" />

      <v-card-text>
        <div class="my-4 text-subtitle-1 font-weight-bold text-center">
          {{ $t('addUserReminder.title') }}
        </div>
        <div class="text-center">
          {{ $t('addUserReminder.body1') }}
          {{ $t('addUserReminder.body2') }}
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text :href="userManagementPage" target="_blank">
          {{ $t('addUserReminder.button') }}
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import accountsAppBaseUrl from '../accountsAppBaseUrl';

export default {
  props: {
    isReminderShown: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isOpen: {
      get() {
        return this.isReminderShown;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    imagePath() {
      return `${process.env.BASE_URL}addUsers.png`;
    },
    userManagementPage() {
      return `${accountsAppBaseUrl}admin/users`;
    },
  },
};
</script>
