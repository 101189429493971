<template>
  <v-container class="pa-0">
    <v-row no-gutters>
      <date-time-picker
        v-model="start"
        :only-date="onlyDate"
        :icon="onlyDate ? `mdi-calendar-range` : `mdi-calendar-clock`"
      />
      <div class="pt-5 px-4">~</div>
      <date-time-picker
        v-model="end"
        :base-time="start.slice(11, 16)"
        :multiday="start.slice(0, 10) !== end.slice(0, 10)"
        :only-date="onlyDate"
      />
    </v-row>
    <v-row v-if="selectionError || error" justify="center">
      <v-alert type="error">{{ errorMessageDisplay }}</v-alert>
    </v-row>
  </v-container>
</template>

<script>
import { addMinutes, differenceInMinutes, format } from 'date-fns';
import DateTimePicker from './DateTimePicker.vue';

export default {
  components: {
    DateTimePicker,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    onlyDate: {
      type: Boolean,
      default: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: 'There is an error',
    },
  },
  data: () => ({
    selectionError: false,
    errorMessage: '',
  }),
  computed: {
    start: {
      get() {
        const start =
          this.value.start !== null
            ? this.value.start
            : format(App.helpers.getDateObject(), 'yyyy-MM-dd HH:mm');
        return this.onlyDate ? `${start.slice(0, 10)} 00:00` : start;
      },
      set(value) {
        this.generateNewEnd(value, this.start, this.end);
      },
    },
    end: {
      get() {
        const end =
          this.value.end !== null
            ? this.value.end
            : format(App.helpers.getDateObject(), 'yyyy-MM-dd HH:mm');
        return this.onlyDate ? `${end.slice(0, 10)} 00:00` : end;
      },
      set(value) {
        const { start } = this;
        const end = value;
        this.checkError(start, end);
      },
    },
    errorMessageDisplay() {
      return this.error ? this.message : this.errorMessage;
    },
  },

  methods: {
    generateNewEnd(newStart, start, end) {
      start = App.helpers.getDateObject(start);
      end = App.helpers.getDateObject(end);
      newStart = App.helpers.getDateObject(newStart);
      const minutesDifference = differenceInMinutes(end, start);
      const newEnd = addMinutes(newStart, minutesDifference);
      this.checkError(
        format(newStart, 'yyyy-MM-dd HH:mm'),
        format(newEnd, 'yyyy-MM-dd HH:mm'),
      );
    },
    checkError(start, end) {
      this.selectionError = start > end;
      this.errorMessage =
        start > end ? this.$t('messages.endTimeIsLessThanStartTime') : '';
      this.$emit('error', this.selectionError);
      this.$emit('input', { ...this.value, ...{ start, end } });
      this.$emit('change');
    },
  },
};
</script>
