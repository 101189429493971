<template>
  <div>
    <v-row no-gutters class="d-print-none">
      <v-col
        v-if="$vuetify.breakpoint.smAndUp"
        class="pa-0 mx-5 py-2"
        sm="4"
        lg="3"
      >
        <DepartmentAndTeamSelector width="350px" />
      </v-col>
      <v-col v-else class="px-1 py-2" cols="12">
        <DepartmentTeamSelectionModal />
      </v-col>

      <v-col
        v-if="$vuetify.breakpoint.smAndUp"
        align="right"
        justify="center"
        class="pa-0 mx-5 py-2"
        sm="4"
      >
        <MultipleUsersSelectorWithSelfSelected
          ref="userSelector"
          width="350px"
          @user-selected="onCustomUsersSelected"
        />
      </v-col>
      <v-col
        v-if="selectedUsers.length > 0"
        align="left"
        justify="center"
        class="pa-0 pl-0 mr-2 py-2"
        cols="12"
        sm="1"
      >
        <v-btn text outlined color="primary" @click="openAddTeamDialog">
          <v-icon left>mdi-plus</v-icon>
          {{ $t('team.add') }}
        </v-btn>
      </v-col>
    </v-row>

    <AddEditTeamModal ref="addEditTeamModal" @update="updateTeamList" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import DepartmentAndTeamSelector from '../../../components/DepartmentAndTeamSelector.vue';
import MultipleUsersSelectorWithSelfSelected from '../../../components/MultipleUsersSelectorWithSelfSelected.vue';
import DepartmentTeamSelectionModal from './DepartmentTeamSelectionModal.vue';
import AddEditTeamModal from '../../../components/AddEditTeamModal.vue';
import { sendGoogleAnalyticsEvent } from '../../../plugins/vue-gtag';

export default {
  components: {
    DepartmentAndTeamSelector,
    MultipleUsersSelectorWithSelfSelected,
    DepartmentTeamSelectionModal,
    AddEditTeamModal,
  },
  data() {
    return {
      usersId: [],
      currentsUsers: [],
    };
  },
  computed: {
    ...mapGetters('groups', ['selectedGroup']),
    ...mapGetters([
      'id',
      'defaultGroup',
      'departmentUsers',
      'teamUsers',
      'selectedUsers',
    ]),
  },
  watch: {
    selectedGroup() {
      if (this.selectedGroup !== null) {
        this.$store.dispatch('emptySelectedUsers');
        this.generateWeekViewCalendarsList();
      }
    },
  },
  created() {
    this.loadDefaultData();
  },
  methods: {
    loadDefaultData() {
      if (this.selectedGroup === null && this.selectedUsers.length === 0) {
        this.$store.dispatch('groups/setSelectedGroup', this.defaultGroup);
      }
      if (this.selectedGroup === null) {
        this.processSelectedUsers(this.selectedUsers);
        return;
      }
      this.generateWeekViewCalendarsList();
    },
    generateWeekViewCalendarsList() {
      const { id } = this.selectedGroup;
      const { type } = this.selectedGroup;
      const users = this.getUsersOfSelectedGroup(id, type);
      this.processSelectedUsers(users, id, type);
    },
    getUsersOfSelectedGroup(id, type) {
      return type === 'department'
        ? this.departmentUsers(id)
        : this.teamUsers(id);
    },
    processSelectedUsers(users, id = null, type = null) {
      if (id === null) {
        this.$store.dispatch('groups/setSelectedGroup', null);
      }
      this.$emit('first-load', true);
      if (users !== null) {
        users = users.map((user) => ({ id: user.id, type: 'user' }));
        users = this.placeLoggedInUserOnTop(users);
        if (id) {
          users.splice(1, 0, { id, type });
        }
        if (!this.isCurrentUsersDifferentFromGeneratedUsers(users)) {
          return;
        }
        this.currentsUsers = [...users];
        this.assignUsersId(users);
        this.$emit('update-users', users);
      }
    },
    placeLoggedInUserOnTop(users) {
      const pos = this.findUser(users, this.id);
      const user = { id: this.id, type: 'user' };
      if (pos !== -1) {
        users.splice(pos, 1);
      }
      users.splice(0, 0, user);
      return users;
    },
    findUser(users, userId) {
      let pos = -1;
      users.forEach((user, index) => {
        if (user.id === userId) {
          pos = index;
        }
      });
      return pos;
    },
    isCurrentUsersDifferentFromGeneratedUsers(users) {
      if (users.length !== this.currentsUsers.length) {
        return true;
      }
      return users.some(
        (user) =>
          !this.currentsUsers.some(
            (currentUser) =>
              currentUser.id === user.id && currentUser.type === user.type,
          ),
      );
    },
    assignUsersId(users) {
      this.usersId = users.filter((user) => user.type === 'user');
      this.usersId = this.usersId.map((user) => user.id);
    },
    openAddTeamDialog() {
      this.$refs.addEditTeamModal.openDialog();
    },
    updateTeamList() {
      this.$store.dispatch('groups/fetchGroups');
    },
    onCustomUsersSelected(users) {
      sendGoogleAnalyticsEvent('customUsersSelected', users.length);
      this.processSelectedUsers(users);
    },
  },
};
</script>
