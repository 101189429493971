const state = {
  isRefreshRequired: false,
};

const getters = {
  isRefreshRequired: (state) => state.isRefreshRequired,
};

const mutations = {
  updateIsRefreshRequired: (state, payload) => {
    state.isRefreshRequired = payload;
  },
};

const actions = {
  updateIsRefreshRequired: ({ commit }, payload) => {
    commit('updateIsRefreshRequired', payload);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
