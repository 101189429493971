<template>
  <v-dialog
    v-model="isDisplayInformationDialogOpen"
    persistent
    scrollable
    max-width="1000px"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card v-if="displayInformation !== null">
      <information-toolbar />
      <v-card-text>
        <v-row>
          <v-col class="text-h4" align="center">
            <v-icon v-if="displayInformation.priority === 1" color="red">
              mdi-star
            </v-icon>
            {{ displayInformation.subject }}
          </v-col>
        </v-row>

        <v-row v-if="displayInformation.body !== 'null'">
          <v-col cols="auto">
            <v-icon>mdi-text</v-icon>
          </v-col>
          <v-col>
            <span v-html="sanitizedBody"></span>
          </v-col>
        </v-row>

        <v-row v-if="displayInformation.attachments.length !== 0">
          <v-col cols="auto">
            <v-icon>mdi-attachment</v-icon>
          </v-col>
          <v-col class="pt-0">
            <v-chip-group>
              <v-chip
                v-for="(attachment, index) in displayInformation.attachments"
                :key="index"
                @click="openAttachment(attachment)"
                >{{ attachment.actual_name }}</v-chip
              >
            </v-chip-group>
          </v-col>
        </v-row>

        <v-divider class="pb-3" />

        <v-row>
          <v-col cols="auto">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-icon v-on="on">mdi-account</v-icon>
              </template>
              <span>{{ $t('informations.postedBy') }}</span>
            </v-tooltip>
          </v-col>
          <v-col>
            <span class="pr-2"> {{ displayInformation.user_name }}, </span>
            <span>
              {{
                getGroupByTypeAndId(
                  'department',
                  displayInformation.posted_by_department_id,
                ).name
              }}
            </span>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="auto" class="pt-0">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-icon v-on="on">mdi-calendar</v-icon>
              </template>
              <span>{{ $t('informations.date') }}</span>
            </v-tooltip>
          </v-col>
          <v-col class="pt-0">
            <v-chip small>
              {{ getDateTime(displayInformation.start) }}
            </v-chip>
            -
            <v-chip small>
              {{ getDateTime(displayInformation.end) }}
            </v-chip>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import xss from 'xss';
import InformationToolbar from './InformationToolbar.vue';

export default {
  components: {
    InformationToolbar,
  },
  computed: {
    ...mapGetters('groups', ['getGroupByTypeAndId']),
    ...mapGetters(['isDisplayInformationDialogOpen', 'displayInformation']),
    sanitizedBody() {
      return xss(this.displayInformation.body);
    },
  },
  methods: {
    getDateTime(value) {
      const dateTime = App.helpers.getDateObject(value);
      return this.$d(dateTime, 'eventTime');
    },
    openAttachment(attachment) {
      window.open(attachment.url);
    },
  },
};
</script>
