<template>
  <v-dialog v-model="dialog" max-width="500">
    <v-card>
      <v-card-title class="title">
        {{ $t('TheSwitchOrganizationModal.switchOrganization') }}
        <v-spacer />
        <v-btn text icon color="grey" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item
            v-for="tenant in user.tenants"
            :key="tenant.id"
            :href="getUrl(tenant)"
            :disabled="currentTenant.id === tenant.id"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ tenant.name }}
                {{ currentTenant.id === tenant.id ? `(${$t('TheSwitchOrganizationModal.current')})` : '' }}
                </v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon v-if="currentTenant.id !== tenant.id">
              <v-icon>mdi-arrow-right-bold</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapGetters([
      'user',
      'currentTenant',
    ]),
  },

  methods: {
    open() {
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },

    getUrl(tenant) {
      let subdomainToRedirectTo = tenant.initial_subdomain;
      if (tenant.subdomain !== null) {
        subdomainToRedirectTo = tenant.subdomain;
      }
      return this.replaceSubdomainInUrl(subdomainToRedirectTo);
    },

    replaceSubdomainInUrl(subdomain) {
      const url = window.location.href;
      return url.replace(url.split('//')[1].split('.')[0], subdomain);
    },
  },
};
</script>
