<template>
  <span>
    <v-card-title class="py-2">
      <v-btn
        v-if="isFullScreen"
        icon
        class="mr-2"
        @click.stop="$emit('clicked-close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      {{ title }}
      <v-spacer />
      <v-btn
        v-if="isPastePossible"
        class="mr-3"
        icon
        @click="$emit('clicked-paste')"
      >
        <v-icon>mdi-content-paste</v-icon>
      </v-btn>
      <v-btn v-if="!isFullScreen" icon @click.stop="$emit('clicked-close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-btn
        v-if="$vuetify.breakpoint.xsOnly"
        :loading="isProcessingSubmit"
        :disabled="isSubmitDisabled || isProcessingSubmit"
        color="primary"
        @click="$emit('clicked-submit')"
      >
        {{ $t('common.save') }}
      </v-btn>
    </v-card-title>
    <v-divider />
  </span>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    isEditMode: {
      type: Boolean,
      required: true,
    },
    isPastePossible: {
      type: Boolean,
      required: true,
    },
    isFullScreen: {
      type: Boolean,
      default: false,
    },
    isSubmitDisabled: {
      type: Boolean,
      required: true,
    },
    isProcessingSubmit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
