<template>
  <v-dialog
    v-model="dialog"
    max-width="400"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-card-title class="headline">
        {{ $t('sendFeedbackModal.sendFeedback') }}
      </v-card-title>
      <v-card-text>
        <p>
          {{ $t('sendFeedbackModal.message') }}
        </p>
        <v-textarea v-model="feedback" outlined hide-details />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" outlined @click="dialog = false">
          {{ $t('common.cancel') }}
        </v-btn>
        <v-btn color="primary" :loading="loading" @click="sendFeedback">
          {{ $t('common.submit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { storeFeedBack } from '../api/miscellaneous.api';

export default {
  data: () => ({
    feedback: '',
    loading: false,
  }),
  computed: {
    dialog: {
      get() {
        return this.$store.getters.isSendFeedbackModalVisible;
      },
      set(value) {
        if (value) {
          this.$store.commit('showSendFeedbackModal');
        } else {
          this.$store.commit('hideSendFeedbackModal');
        }
      },
    },
  },
  watch: {
    dialog(newValue) {
      if (newValue) {
        this.feedback = '';
        this.loading = false;
      }
    },
  },
  methods: {
    async sendFeedback() {
      this.loading = true;
      try {
        await storeFeedBack(this.feedback);
        this.$store.dispatch('openSnackbar', {
          color: 'success',
          message: this.$t('sendFeedbackModal.thankYouForWritingToUs'),
        });
        this.dialog = false;
      } catch (err) {
        this.$store.dispatch('openSnackbar', {
          color: 'error',
          message: this.$t('common.somethingWentWrong'),
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
