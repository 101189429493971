import { format } from 'date-fns';
import { mapGetters } from 'vuex';

const calendarAddEdit = {
  data() {
    return {
      overlappingFacilityStartEnd: null,
    };
  },
  computed: {
    ...mapGetters(['userData']),
    ...mapGetters('groups', ['getGroupByTypeAndId']),

    facilitiesBusy() {
      if (this.overlappingFacilityStartEnd === null) {
        return [];
      }
      return this.overlappingFacilityStartEnd.map((item) => item.facility_id);
    },

    facilityBookedError() {
      this.disabled = false;
      this.isSubmitDisabled = false;
      if (this.facilitiesBusy.length === 0) {
        return false;
      }
      this.errorMessage = this.$t('messages.facilityHasBookingInTime');
      this.disabled = true;
      this.isSubmitDisabled = true;
      return true;
    },
  },
  methods: {
    async checkValidity() {
      this.$store.dispatch('updateEventFacilities', this.facilities);
      if (this.facilities.length !== 0) {
        this.form.isRepeating = false;
        const data = await this.checkStartEndTimeValidity();
        this.overlappingFacilityStartEnd =
          typeof data === 'object' ? data : null;
      } else {
        this.overlappingFacilityStartEnd = null;
      }
    },

    async checkStartEndTimeValidity() {
      const start = App.helpers.getDateObject(this.dateTimeRange.start);
      const end = App.helpers.getDateObject(this.dateTimeRange.end);

      const parameters = {
        facilities: [...this.facilities],
        all_day: this.form.all_day === false || this.form.all_day === 0 ? 0 : 1,
        start: format(start, 'yyyy-MM-dd HH:mm:ss'),
        end: format(end, 'yyyy-MM-dd HH:mm:ss'),
        group_type: 'App\\Event',
        group_id: this.form.id === null ? 0 : this.form.id,
        recurrence_type: 'none',
      };
      const { data } = await axios.get('/api/facility-booking/get-overlap/', {
        params: { ...parameters },
      });
      return data;
    },

    injectCalendarName(calendars) {
      calendars.forEach((calendar) => {
        calendar.name = this.getCalendarNameByType(calendar);
      });
      return calendars;
    },

    getCalendarNameByType(calendar) {
      if (calendar.user_id !== null) {
        return this.getUserCalendarName(calendar);
      } else if (calendar.department_id !== null) {
        return this.getDepartmentCalendarName(calendar);
      } else if (calendar.team_id !== null) {
        return this.getTeamCalendarName(calendar);
      }
    },

    getUserCalendarName(calendar) {
      const name = this.userData(calendar.user_id).name;
      if (calendar.default_calendar_for_user_id !== null) {
        return this.$t('event.publicCalendar', { name: name });
      }
      return this.$t('event.privateCalendar', { name: name });
    },

    getDepartmentCalendarName(calendar) {
      const name = this.getGroupByTypeAndId(
        'department',
        calendar.department_id,
      ).name;
      return this.$t('event.publicCalendar', { name: name });
    },

    getTeamCalendarName(calendar) {
      const name = this.getGroupByTypeAndId('team', calendar.team_id).name;
      return this.$t('event.publicCalendar', { name: name });
    },
  },
};

export default calendarAddEdit;
