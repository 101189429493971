const state = {
  selectedUsers: [],
};

const getters = {
  selectedUsers: (state) => state.selectedUsers,
};

const mutations = {
  setSelectedUsers: (state, users) => {
    state.selectedUsers = users;
  },
  emptySelectedUsers: (state) => {
    state.selectedUsers = [];
  },
};

const actions = {
  async setSelectedUsersState(context, payload) {
    context.commit('setSelectedUsers', payload);
  },
  emptySelectedUsers(context) {
    context.commit('emptySelectedUsers');
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
