<template>
  <v-chip small label :color="chipColor" :text-color="textColor">
    {{ eventTypeName }}
  </v-chip>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ...mapGetters(['normalizedEventTypes']),

    eventTypeName() {
      return this.normalizedEventTypes[this.id].name;
    },

    chipColor() {
      return this.normalizedEventTypes[this.id].color;
    },

    textColor() {
      return this.$root.getTextColor(this.chipColor);
    },
  },
};
</script>
