<template>
  <v-snackbar
    v-model="snackbar.open"
    :top="$vuetify.breakpoint.xsOnly"
    :color="snackbar.color"
    :timeout="snackbar.timeout"
  >
    {{ snackbar.message }}
    <template #action="{ attrs }">
      <v-btn
        icon
        v-bind="attrs"
        class="font-weight-bold"
        @click="closeSnackbar"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['isSnackbarOpen', 'snackbar']),
  },
  methods: {
    ...mapActions(['closeSnackbar']),
  },
};
</script>
