function getDefaultState() {
  return {
    displayEvent: null,
    displayPosition: { x: 0, y: 0 },
    copiedEvent: null,
  };
}

const state = getDefaultState();

const getters = {
  isDisplayMenuOpen: (state) => state.displayEvent !== null,
  displayEvent: (state) => state.displayEvent,
  copiedEvent: (state) => state.copiedEvent,
  displayEventType: (state) =>
    state.displayEvent ? state.displayEvent.group_type : null,
  displayPosition: (state) => state.displayPosition,
};

const mutations = {
  setDisplayEvent(state, payload) {
    state.displayEvent = { ...payload };
  },
  setCopiedEvent(state, payload) {
    state.copiedEvent = { ...payload };
  },
  setDisplayPosition(state, payload) {
    state.displayPosition = { ...payload };
  },
  resetDisplayMenu(state) {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  setDisplayEvent(context, payload) {
    context.commit('setDisplayEvent', payload);
  },
  setCopiedEvent(context, payload) {
    context.commit('setCopiedEvent', payload);
  },
  setDisplayPosition(context, payload) {
    context.commit('setDisplayPosition', payload);
  },
  closeDisplayMenu(context) {
    context.commit('setDisplayEvent', null);
    context.commit('setDisplayPosition', { x: 0, y: 0 });
  },
  resetDisplayMenu(context) {
    context.commit('resetDisplayMenu');
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
