import i18n from '../../i18n';
import accountsAppBaseUrl from '../../accountsAppBaseUrl';
import { updateUserPreferences } from '../../api/userPreferences.api';

const state = {
  user: {
    name: '',
    id: null,
    email: '',
    department: null,
    departmentName: null,
    isAdmin: 0,
    calendars: [],
    default_calendar: null,
    type: 'user',
    defaultCalendar: {},
    additional_departments: [],
    tenants: [],
    language: 'ja',
  },
  preferences: {
    is_end_time_shown: false,
    first_day_of_the_week: null,
    default_group_type: null,
    default_group_id: null,
    reminder_type: 'off',
    notification_before_mins: null,
    is_email_reminder_enabled: false,
    email_before_mins: null,
    receive_event_and_task_emails: false,
    receive_meeting_invite_emails: false,
  },
};

const getters = {
  name: (state) => state.user.name,
  id: (state) => state.user.id,
  email: (state) => state.user.email,
  department: (state) => state.user.department,
  user: (state) => state.user,
  language: (state) => state.user.language,
  reminder_type: (state) => state.preferences.reminder_type,
  email_before_mins: (state) => state.preferences.email_before_mins,
  notification_before_mins: (state) =>
    state.preferences.notification_before_mins,
  preferences: (state) => state.preferences,
  additional_departments: (state) => state.user.additional_departments,
  allDepartmentIds: (state) => {
    const departments = [...state.user.additional_departments];
    const departmentIds = departments.map((department) => department.id);
    departmentIds.splice(0, 0, state.user.department);
    return departmentIds;
  },
  allDepartments: (state, _, __, rootGetters) => {
    const departments = [...state.user.additional_departments];
    const loggedInUserDepartment =
      rootGetters['groups/getLoggedInUserDepartment'];
    departments.splice(0, 0, loggedInUserDepartment);
    return departments;
  },
  firstDayOfTheWeek: (state) => state.preferences.first_day_of_the_week,
  defaultGroupType: (state) => state.preferences.default_group_type,
  defaultGroupId: (state) => state.preferences.default_group_id,
  defaultGroup: (state, _, __, rootGetters) =>
    rootGetters['groups/getGroupByTypeAndId'](
      state.preferences.default_group_type,
      state.preferences.default_group_id,
    ),

  currentTenant: (state) => {
    if (process.env.NODE_ENV !== 'production') {
      return state.user.tenants[0];
    }
    const subdomain = App.helpers.getSubdomain();

    const tenant = state.user.tenants.find(
      (tenantWithUser) =>
        tenantWithUser.subdomain === subdomain ||
        tenantWithUser.initial_subdomain === subdomain,
    );

    return tenant;
  },
};

const mutations = {
  updateUser: (state, payload) => {
    state.user = {
      ...state.user,
      ...payload,
    };
  },

  updatePreferences: (state, payload) => {
    const isEmailReminderEnabled =
      payload.is_email_reminder_enabled === 1 ||
      payload.is_email_reminder_enabled === true;
    const receiveEventAndTaskEmails =
      payload.receive_event_and_task_emails === 1 ||
      payload.receive_event_and_task_emails === true;
    const receiveMeetingInviteEmails =
      payload.receive_meeting_invite_emails === 1 ||
      payload.receive_meeting_invite_emails === true;
    state.preferences = {
      ...payload,
      ...{
        is_email_reminder_enabled: isEmailReminderEnabled,
        receive_event_and_task_emails: receiveEventAndTaskEmails,
        receive_meeting_invite_emails: receiveMeetingInviteEmails,
      },
    };
  },
};

const actions = {
  setUser: ({ commit }, payload) => {
    const user = {
      name: payload.name,
      id: payload.id,
      email: payload.email,
      isAdmin: payload.is_admin,
      department: payload.department ? payload.department.id : null,
      departmentName: payload.department ? payload.department.name : null,
      calendars: payload.calendars,
      default_calendar: payload.default_calendar,
      defaultCalendars: payload.default_calendar,
      additional_departments: payload.additional_departments,
      tenants: payload.tenants,
      language: payload.language,
    };
    commit('updateUser', user);

    let defaultGroupType = payload.default_group_type;
    let defaultGroupId = payload.default_group_id;

    if (defaultGroupType === null) {
      defaultGroupType = 'App\\Department';
      defaultGroupId = user.department;
    }

    defaultGroupType =
      defaultGroupType === 'App\\Department' ? 'department' : 'team';

    const preferences = {
      is_end_time_shown: payload.is_end_time_shown,
      first_day_of_the_week: payload.first_day_of_the_week,
      default_group_type: defaultGroupType,
      default_group_id: defaultGroupId,
      reminder_type: payload.reminder_type,
      notification_before_mins: payload.notification_before_mins,
      is_email_reminder_enabled: payload.is_email_reminder_enabled,
      email_before_mins: payload.email_before_mins,
      receive_event_and_task_emails: payload.receive_event_and_task_emails,
      receive_meeting_invite_emails: payload.receive_meeting_invite_emails,
    };
    i18n.locale =
      typeof payload.language === 'undefined' ? 'ja' : payload.language;
    // i18n.locale = 'en';
    commit('updatePreferences', preferences);
  },
  async logout({ commit }) {
    await axios.post(`${accountsAppBaseUrl}api/logout`);
    const user = {
      name: '',
      id: null,
      email: '',
      department: null,
      departmentName: null,
      isAdmin: 0,
    };
    commit('updateUser', user);
    window.location.href = `${accountsAppBaseUrl}?continue=%2Fscheduler%2F`;
  },
  async updateUserPreferences({ commit }, payload) {
    const { data } = await updateUserPreferences(payload);
    const defaultGroupType = data.default_group_type;
    data.default_group_type =
      defaultGroupType === 'App\\Team' ? 'team' : 'department';
    commit('updatePreferences', data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
