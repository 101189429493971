function getDefaultState() {
  return {
    quickBookingInfo: null,
    isFullAddEditBookingOpen: false,
    addEditBooking: null,
  };
}

const state = getDefaultState();

const getters = {
  quickBookingInfo: (state) => state.quickBookingInfo,
  isFullAddEditBookingOpen: (state) => state.isFullAddEditBookingOpen,
  addEditBooking: (state) => state.addEditBooking,
};

const mutations = {
  storeQuickBookingInfo: (state, payload) => {
    state.quickBookingInfo = {
      facilities: [...payload.facilities],
      start: payload.start,
      end: payload.end,
      x: payload.x,
      y: payload.y,
    };
  },
  resetQuickBookingInfo: (state) => {
    state.quickBookingInfo = null;
  },
  updateIsFullAddEditBookingOpen: (state, payload) => {
    state.isFullAddEditBookingOpen = payload;
  },
  setAddEditBooking(state, payload) {
    state.addEditBooking = { ...payload };
  },
  resetAddEditBooking(state) {
    state.addEditBooking = null;
  },
  resetFacilityPageMenu: (state) => {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  storeQuickBookingInfo(context, payload) {
    context.commit('storeQuickBookingInfo', payload);
  },
  resetQuickBookingInfo(context) {
    context.commit('resetQuickBookingInfo');
  },
  openFullAddEditBooking: ({ commit }) => {
    commit('updateIsFullAddEditBookingOpen', true);
  },
  closeFullAddEditBooking: ({ commit }) => {
    commit('updateIsFullAddEditBookingOpen', false);
  },
  setAddEditBooking(context, payload) {
    context.commit('setAddEditBooking', payload);
  },
  resetAddEditBooking(context) {
    context.commit('resetAddEditBooking');
  },
  resetFacilityPageMenu: ({ commit }) => {
    commit('resetFacilityPageMenu');
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
