<template>
  <v-combobox
    v-model="comboBoxTime"
    :items="timeOptions"
    :style="`width: 75px`"
    :menu-props="{ auto: true, minWidth: '120px' }"
    append-icon
    @update:search-input="inputTime"
    @change="formatTime"
    @keydown="checkKeyDown"
    @blur="checkBlur"
  >
    <template v-if="baseTime !== null && !multiday" #item="{ item }">
      {{ item }}
      <span
        class="pl-2"
        style="font-weight: 300; font-size: smaller; color: grey"
      >
        ({{ timeGap(item) }})
      </span>
    </template>
  </v-combobox>
</template>

<script>
import { differenceInMinutes } from 'date-fns';

export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    baseTime: {
      type: String,
      default: null,
    },
    multiday: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    comboBoxTime: null,
  }),
  computed: {
    timeOptions() {
      const options = [];
      const baseTime = this.baseTime ? this.baseTime : '00:00';
      const baseMinutes = parseInt(baseTime.slice(3, 5), 10) % 15;
      for (let hour = 0; hour < 24; hour += 1) {
        const hourString = hour < 10 ? `0${hour}` : `${hour}`;
        for (let minute = 0; minute < 60; minute += 15) {
          const newMinute = minute + baseMinutes;
          options.push(
            newMinute < 10
              ? `${hourString}:0${newMinute}`
              : `${hourString}:${newMinute}`,
          );
        }
      }
      return options.filter((option) => option >= baseTime || this.multiday);
    },
  },
  watch: {
    value() {
      this.comboBoxTime = this.value;
    },
  },
  created() {
    this.comboBoxTime = this.value;
  },
  methods: {
    inputTime(time) {
      if (time !== null) {
        this.comboBoxTime = this.checkAndUpdateTime(time);
      }
    },
    checkAndUpdateTime(timeString) {
      const japNumbersRegex = /[\uFF10-\uFF19]/g;
      const japColonRegex = /：/g;
      let japNumbersAndColonReplacedString = timeString.replace(
        japNumbersRegex,
        (ch) => String.fromCharCode(ch.charCodeAt(0) - 65248),
      );
      japNumbersAndColonReplacedString =
        japNumbersAndColonReplacedString.replace(japColonRegex, (ch) => ':');
      return japNumbersAndColonReplacedString;
    },
    async formatTime(time) {
      this.comboBoxTime = await this.formatAndUpdateTime(time);
      this.$emit('input', this.comboBoxTime);
      this.$emit('change', this.comboBoxTime);
    },
    formatAndUpdateTime(timeString, defaultTime = null) {
      const notNumbersRegex = /[^0-9]/g;
      const numbersString = timeString.replace(notNumbersRegex, (ch) => '');
      let substituteTime = defaultTime;
      if (substituteTime === null || substituteTime.length !== 5) {
        substituteTime = '09:00';
      }
      let hour = this.generateHour(
        numbersString.slice(0, 2),
        substituteTime.slice(0, 2),
      );
      let minute = this.generateMinute(
        numbersString.slice(2, 4),
        substituteTime.slice(3, 5),
      );
      hour = minute === 60 ? hour + 1 : hour;
      minute = minute === 60 ? 0 : minute;
      minute = hour > 23 ? 59 : minute;
      hour = hour > 23 ? 23 : hour;
      let time = hour < 10 ? `0${hour}:` : `${hour}:`;
      time = minute < 10 ? `${time}0${minute}` : `${time}${minute}`;
      return time;
    },
    generateHour(hour, defaultHour) {
      if (hour.length === 0) {
        return parseInt(defaultHour, 10);
      }
      let generatedHour = parseInt(hour, 10);
      generatedHour = generatedHour > 24 ? 24 : generatedHour;
      return generatedHour;
    },
    generateMinute(minute, defaultMinute) {
      if (minute.length === 0) {
        return parseInt(defaultMinute, 10);
      }
      let generatedMinute = parseInt(minute, 10);
      generatedMinute = generatedMinute > 60 ? 60 : generatedMinute;
      return generatedMinute;
    },
    checkKeyDown(event) {
      if (event.key === 'Enter') {
        this.formatTime(event.srcElement.value);
      }
    },
    checkBlur(event) {
      if (event.type === 'blur') {
        this.formatTime(event.srcElement.value);
      }
    },
    timeGap(item) {
      const minutes = differenceInMinutes(
        App.helpers.getDateObject(`2020-01-01 ${item}`),
        App.helpers.getDateObject(`2020-01-01 ${this.baseTime}`),
      );
      return minutes >= 60 || minutes <= -60
        ? `${minutes / 60} ${this.$t('common.hrs')}`
        : `${minutes} ${this.$t('common.mins')}`;
    },
  },
};
</script>
