<template>
  <v-simple-table>
    <template #default>
      <tbody>
        <tr v-for="i in rowsNum" :key="i">
          <td class="pa-0">
            <v-card
              class="ma-0"
              :ripple="false"
              tile
              outlined
              :style="{
                'border-width': '0 0 1px 1px',
              }"
              :min-width="40"
              :max-height="100"
              height="100%"
              width="100%"
            >
              <v-skeleton-loader type="card" height="100" tile />
            </v-card>
          </td>
          <td v-for="i in 7" :key="i" class="pa-0">
            <v-card
              class="ma-0"
              :ripple="false"
              tile
              outlined
              :style="{
                'border-width': '0 0 1px 1px',
              }"
              :min-width="40"
              height="100%"
              width="100%"
            >
              <div>
                <skeleton-event-chips />
              </div>
            </v-card>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import SkeletonEventChips from './SkeletonEventChips.vue';

export default {
  components: {
    SkeletonEventChips,
  },
  props: {
    count: {
      type: Number,
      default: 10,
    },
  },
  computed: {
    rowsNum() {
      return this.count ? this.count : 10;
    },
  },
};
</script>