import { format } from 'date-fns';

const App = Object.freeze({
  suite: process.env.VUE_APP_SUITE,
  name: process.env.VUE_APP_NAME,
  shortName: process.env.VUE_APP_NAME_SHORT,
  version: process.env.VUE_APP_VERSION,
  helpers: {
    getISODateString(date) {
      const d = this.getDateObject(date);
      let month = `${d.getMonth() + 1}`;
      let day = `${d.getDate()}`;
      const year = d.getFullYear();

      if (month.length < 2) month = `0${month}`;
      if (day.length < 2) day = `0${day}`;

      return [year, month, day].join('-');
    },
    displayDate(date) {
      const d = this.getDateObject(date);
      let month = `${d.getMonth() + 1}`;
      let day = `${d.getDate()}`;
      const year = d.getFullYear();
      if (month.length < 2) month = `0${month}`;
      if (day.length < 2) day = `0${day}`;
      return [day, month, year].join('/');
    },
    displayDateTime(date) {
      const d = this.getDateObject(date);
      let month = `${d.getMonth() + 1}`;
      let day = `${d.getDate()}`;
      const year = d.getFullYear();
      let hour = d.getHours();
      let minutes = d.getMinutes();
      if (month.length < 2) month = `0${month}`;
      if (day.length < 2) day = `0${day}`;
      if (hour < 10) {
        hour = `0${hour}`;
      }
      if (minutes < 10) {
        minutes = `0${minutes}`;
      }
      hour = `${hour}`;
      minutes = `${minutes}`;
      return `${[hour, minutes].join(':')}  ${[day, month, year].join('/')}`;
    },
    getSubdomain() {
      return window.location.host.split('.')[0];
    },
    getDateOfToday() {
      return format(new Date(), 'yyyy-MM-dd');
    },
    appendAppName(str) {
      return `${str} - ${App.name}`;
    },
    getDateObject(dateTimeString = null) {
      if (dateTimeString === null || dateTimeString === '') {
        return new Date();
      }
      if (
        typeof dateTimeString === 'string' &&
        dateTimeString.split(' ').length > 1
      )
        return new Date(dateTimeString.replace(/-/g, '/'));
      return new Date(dateTimeString);
    },
  },
});

window.App = App;

export default App;
