import { mapGetters } from 'vuex';

const meetingMembersSelectorItems = {
  computed: {
    ...mapGetters(['departmentsAndTeamsWithUsers']),

    items() {
      return this.departmentsAndTeamsWithUsers.flatMap(mapGroupIntoItems);
    },
  },
};

function mapGroupIntoItems(group) {
  const items = [];

  if (group.users.length === 0) {
    return items;
  }

  items.push({ divider: true });

  items.push({
    id: group.id,
    type: 'group',
    name: group.name,
    users: group.users,
  });

  items.push({ divider: true });

  group.users.forEach((user) => {
    items.push({
      id: user.id,
      type: 'user',
      name: user.name,
      groupName: group.name,
      email: user.email,
      isMainDepartment:
        group.type === 'App\\Department' && typeof user.pivot === 'undefined',
    });
  });

  return items;
}

export default meetingMembersSelectorItems;
