<template>
  <v-row
    :id="'row' + rowIndex"
    :style="{ height: `${rowHeight}px` }"
    class="flex-nowrap row-fix mt-0"
    no-gutters
    justify="start"
  >
    <v-col>
      <WeekViewCalendarGridRowHeaderCell
        :name="userInfo.name"
        :type="userInfo.type"
        :row-index="rowIndex"
      />
    </v-col>
    <v-col v-for="(cellData, cellIndex) in eventCells" :key="cellIndex">
      <WeekViewCalendarGridRowCell
        :cell-data="[...cellData]"
        :cell-index="cellIndex"
        :row-index="rowIndex"
        :week-header="weekHeader"
        :is-grid-loading="isGridLoading"
        :is-dragging="isDragging"
        :drag-row-index="dragRowIndex"
        :drag-start-cell-index="dragStartCellIndex"
        :drag-end-cell-index="dragEndCellIndex"
        :is-highlighted="isCellHighlighted(cellIndex)"
        @start-drag="emitStartDrag($event, cellIndex)"
        @modify-drag="emitModifyDrag($event, cellIndex)"
        @end-drag="emitEndDrag($event, cellIndex)"
        @cell-double-click="emitCellDoubleClick(cellIndex)"
        @cell-item-dragged="emitCellItemDragged($event, cellIndex)"
        @cell-item-dropped="emitCellItemDropped($event, cellIndex)"
        @event-click="emitEventClick"
      />
    </v-col>
  </v-row>
</template>

<script>
import WeekViewCalendarGridRowCell from './WeekViewCalendarGridRowCell.vue';
import WeekViewCalendarGridRowHeaderCell from './WeekViewCalendarGridRowHeaderCell.vue';

export default {
  components: {
    WeekViewCalendarGridRowCell,
    WeekViewCalendarGridRowHeaderCell,
  },
  props: {
    rowData: {
      type: Array,
      required: true,
    },
    rowIndex: {
      type: Number,
      required: true,
    },
    weekHeader: {
      type: Array,
      default: () => [],
    },
    isGridLoading: {
      type: Boolean,
      default: false,
    },
    isDragging: {
      type: Boolean,
      default: false,
    },
    dragRowIndex: {
      type: Number,
      default: null,
    },
    dragStartCellIndex: {
      type: Number,
      default: null,
    },
    dragEndCellIndex: {
      type: Number,
      default: null,
    },
    highlightCells: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      maxEventsInCell: 0,
    };
  },

  computed: {
    eventCells() {
      const eventCells = this.rowData.slice(1);
      return this.getFormattedEventCells(eventCells);
    },
    userInfo() {
      return this.rowData[0];
    },
    defaultEventHeight() {
      return 21;
    },
    defaultRowHeight() {
      return 100;
    },
    rowHeight() {
      return Math.max(
        this.maxEventsInCell * this.defaultEventHeight + 50,
        this.defaultRowHeight,
      );
    },
  },

  methods: {
    getFormattedEventCells(eventCells) {
      let maxPosition = 0;
      let extendingEvents = [];
      const cells = eventCells.map((cell, index) => {
        const day = this.weekHeader[index];
        const nonNullEvents = cell.filter((event) => event !== null);
        const events = this.injectPositionInEventsForDay(
          nonNullEvents,
          extendingEvents,
          day,
        );

        if (events.length) {
          maxPosition = Math.max(
            events[events.length - 1].position,
            maxPosition,
          );
        }

        const allEvents = [...events, ...extendingEvents];
        extendingEvents = this.getEventsExtendingToNextDay(allEvents, day);
        return events;
      });

      this.maxEventsInCell = maxPosition;

      return cells;
    },
    injectPositionInEventsForDay(events, extendingEvents, day) {
      const sortedEvents = this.getSortedEventsForDay(events, day);
      let position = 0;
      return sortedEvents.map((event) => {
        position += 1;
        while (this.isEventPositionFilled(extendingEvents, position)) {
          position += 1;
        }
        return { ...event, position };
      });
    },
    getSortedEventsForDay(events) {
      return events.sort((event1, event2) => {
        if (event1.start > event2.start) {
          return 1;
        }
        if (event1.start < event2.start) {
          return -1;
        }
        return event1.created_at < event2.created_at ? -1 : 1;
      });
    },
    isEventPositionFilled(positionedEvents, position) {
      if (positionedEvents.length === 0) {
        return false;
      }
      return positionedEvents.some((event) => event.position === position);
    },
    getEventsExtendingToNextDay(events, day) {
      return events.filter((event) => {
        const currentDate = App.helpers.getISODateString(day);
        const eventEndDate = App.helpers.getISODateString(event.end);
        return eventEndDate > currentDate;
      });
    },
    isCellHighlighted(cellIndex) {
      if (this.highlightCells.length === 0) {
        return false;
      }
      return this.highlightCells.includes(cellIndex);
    },
    emitStartDrag(mouseEvent, cellIndex) {
      this.$emit('start-drag', { mouseEvent, cellIndex });
    },
    emitModifyDrag(mouseEvent, cellIndex) {
      this.$emit('modify-drag', { mouseEvent, cellIndex });
    },
    emitEndDrag(mouseEvent, cellIndex) {
      this.$emit('end-drag', { mouseEvent, cellIndex });
    },
    emitCellDoubleClick(cellIndex) {
      this.$emit('cell-double-click', cellIndex);
    },
    emitCellItemDragged(event, cellIndex) {
      this.$emit('cell-item-dragged', { event, cellIndex });
    },
    emitCellItemDropped(event, cellIndex) {
      this.$emit('cell-item-dropped', { event, cellIndex });
    },
    emitEventClick(event, nativeEvent) {
      this.$emit('event-click', event, this.userInfo, nativeEvent);
    },
  },
};
</script>
