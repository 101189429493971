import httpClient from './httpClient';

const createEvent = (
  calendarId,
  start,
  end,
  eventTypeId,
  allDay,
  subject,
  description,
  place,
  priority,
  busy,
  facilities,
  groupType,
  groupId,
  userId,
) =>
  httpClient.post('events', {
    calendar_id: calendarId,
    start,
    end,
    event_type_id: eventTypeId,
    all_day: allDay,
    subject,
    description,
    place,
    priority,
    busy,
    facilities,
    groupType,
    groupId,
    user_id: userId,
  });

const convertNormalEventToRecurring = (eventId, data) =>
  httpClient.patch(`events/${eventId}/convert-to-recurring`, data);

const convertNormalEventToMeeting = (
  eventId,
  { subject, description, eventTypeId, start, end, members, facilities },
) =>
  httpClient.patch(`events/${eventId}/convert-to-meeting`, {
    subject,
    description,
    eventTypeId,
    start,
    end,
    members,
    facilities,
  });

const dragDropRecurringEvent = (calendarOwner, event, startDate, endDate) => {
  const endpoint = `event-recur/${event.recurrence_id}/move`;
  return httpClient.put(endpoint, {
    id: calendarOwner.id,
    type: calendarOwner.type,
    newStartDate: startDate,
    newEndDate: endDate,
    edit_type: 1,
  });
};

const dragDropEvent = (calendarOwner, event, startDate, endDate) => {
  const endpoint = `event/${event.id}/move`;
  return httpClient.put(endpoint, {
    id: calendarOwner.id,
    type: calendarOwner.type,
    newStartDate: startDate,
    newEndDate: endDate,
  });
};

const pasteRecurringEvent = (eventId, calendarId, date) => {
  const endpoint = `event-recur/${eventId}/copy`;
  return httpClient.post(endpoint, {
    calendar_id: calendarId,
    edit_type: 1,
    date,
  });
};

const pasteEvent = (eventId, calendarId, date) => {
  const endpoint = `event/${eventId}/copy`;
  return httpClient.post(endpoint, {
    calendar_id: calendarId,
    date,
  });
};

export {
  convertNormalEventToRecurring,
  createEvent,
  dragDropRecurringEvent,
  dragDropEvent,
  pasteRecurringEvent,
  pasteEvent,
  convertNormalEventToMeeting,
};
