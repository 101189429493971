import { normalize, schema } from 'normalizr';

function getCommonCalendarAsCalendarOwner(group, type) {
  const calendarOwner = {
    uid: `${type} ${group.id}`,
    id: group.id,
    name: group.name,
    type,
    calendars: group.calendars,
    default_calendar: group.calendars[0] ? group.calendars[0] : { id: null },
  };
  return calendarOwner;
}

const state = {
  normalizedUsers: [],
  groupsWithUsers: [],
  isAnyUserWithNoDepartment: false,
};

const getters = {
  normalizedUsers: (state) => state.normalizedUsers,
  userData: (state) => (id) => state.normalizedUsers[id],
  departmentUsers: (state) => (id) => {
    const department = state.groupsWithUsers.find(
      (group) => group.type === 'App\\Department' && group.id === id,
    );
    return department.users;
  },
  teamUsers: (state) => (id) => {
    const team = state.groupsWithUsers.find(
      (group) => group.type === 'App\\Team' && group.id === id,
    );
    return team.users;
  },
  departmentUsersSelectorChoices: (state) => {
    let usersSelector = [];
    const departmentsWithUsers = state.groupsWithUsers.filter(
      (group) => group.type === 'App\\Department',
    );
    departmentsWithUsers.forEach((department) => {
      usersSelector.push({ divider: true });
      usersSelector.push({ header: department.name });
      usersSelector.push({ divider: true });
      usersSelector = usersSelector.concat(department.users);
    });
    return usersSelector;
  },
  calendarOwner: (state, getters) => (id, type) => {
    const uid = `${type} ${id}`;
    return getters.calendarOwners.find(
      (calendarOwner) => uid === calendarOwner.uid,
    );
  },
  departmentsAndTeamsWithUsers: (state, getters) => {
    const departmentsAndTeams = [];
    state.groupsWithUsers.forEach((group) => {
      if (group.type === 'App\\Team') {
        const isUserInTeam = group.users.some((user) => user.id === getters.id);
        if (isUserInTeam === false) {
          return;
        }
      }
      departmentsAndTeams.push({
        id: group.id,
        type: group.type,
        name: group.name,
        users: group.users,
      });
    });
    return departmentsAndTeams;
  },
  calendarOwners: (state) => {
    const calendarOwners = [];
    const departmentsWithUsers = state.groupsWithUsers.filter(
      (group) => group.type === 'App\\Department',
    );
    departmentsWithUsers.forEach((department) => {
      calendarOwners.push({ divider: true });
      calendarOwners.push({ header: department.name });
      calendarOwners.push({ divider: true });
      calendarOwners.push(
        getCommonCalendarAsCalendarOwner(department, 'department'),
      );
      department.users.forEach((user) => {
        const userWithDetails = state.normalizedUsers[user.id];
        userWithDetails.uid = `user ${user.id}`;
        userWithDetails.type = 'user';
        userWithDetails.position = user.position;
        calendarOwners.push(userWithDetails);
      });
    });
    const teamsWithUsers = state.groupsWithUsers.filter(
      (group) => group.type === 'App\\Team',
    );
    teamsWithUsers.forEach((team) => {
      calendarOwners.push({ divider: true });
      calendarOwners.push({ header: team.name });
      calendarOwners.push({ divider: true });
      calendarOwners.push(getCommonCalendarAsCalendarOwner(team, 'team'));
      team.users.forEach((user) => {
        const userWithDetails = state.normalizedUsers[user.id];
        userWithDetails.uid = `user ${user.id}`;
        userWithDetails.type = 'user';
        userWithDetails.position = user.position;
        calendarOwners.push(userWithDetails);
      });
    });
    return calendarOwners;
  },
};

const mutations = {
  setNormalizedUsers: (state, payload) => {
    const users = new schema.Entity('users');
    const mySchema = [users];
    const normalizedUsers = normalize(payload, mySchema);
    state.normalizedUsers = normalizedUsers.entities.users;
  },
  setGroupsWithUsers: (state, payload) => {
    state.groupsWithUsers = payload;
  },
  setIsThereMembersWithWithNoDepartment: (state, payload) => {
    state.isAnyUserWithNoDepartment = payload.some(
      (department) =>
        department.id === null && department.type === 'App\\Department',
    );
  },
};

const actions = {
  fetchAndSetUsers: ({ commit }) => {
    axios.get('api/users').then((response) => {
      commit('setNormalizedUsers', response.data);
    });
  },
  fetchAndSetGroupsUsers: async ({ commit }) => {
    const { data } = await axios.get('api/users-groups');
    commit('setGroupsWithUsers', data);
    commit('setIsThereMembersWithWithNoDepartment', data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
