<template>
  <v-card-title class="pt-0 px-1 pb-0">
    <v-spacer />
    <extra-information v-if="isUserCreator" :id="displayInformation.id" />
    <v-btn v-if="isUserCreator" icon @click="confirmDelete">
      <v-icon>mdi-delete-outline</v-icon>
    </v-btn>
    <v-btn v-if="isUserCreator" icon @click="edit">
      <v-icon>mdi-pencil-outline</v-icon>
    </v-btn>
    <v-btn icon @click="copyInformation">
      <v-icon>mdi-content-copy</v-icon>
    </v-btn>
    <v-btn icon @click="$store.dispatch('closeDisplayInformationDialog')">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <confirm ref="confirm" />
  </v-card-title>
</template>

<script>
import { mapGetters } from 'vuex';
import Confirm from '../../../components/Confirm.vue';
import ExtraInformation from './ExtraInformation.vue';

export default {
  components: {
    Confirm,
    ExtraInformation,
  },
  computed: {
    ...mapGetters(['id', 'displayInformation']),
    isUserCreator() {
      if (this.displayInformation === null) {
        return false;
      }
      return this.displayInformation.user_id === this.id;
    },
  },
  methods: {
    edit() {
      this.$store.dispatch('setEditInformation');
      this.$store.dispatch('closeDisplayInformationDialog');
      this.$store.dispatch('openAddEditInformationDialog');
    },
    async confirmDelete() {
      const isConfirmed = await this.$refs.confirm.open(
        this.$t('informations.deleteTitle'),
        this.$t('informations.deleteBody'),
      );
      if (!isConfirmed) {
        return;
      }
      try {
        await this.delete();
        this.$store.dispatch('openSnackbar', {
          color: 'success',
          message: this.$t('informations.deleted'),
        });
      } catch (error) {
        this.$store.dispatch('openSnackbar', {
          color: 'error',
          message: this.$t('common.somethingWentWrong'),
        });
      }
    },
    async delete() {
      await axios.delete(`/api/informations/${this.displayInformation.id}`);
      this.$store.dispatch('closeDisplayInformationDialog');
      this.$store.dispatch('fetchAndSetInformations');
    },
    copyInformation() {
      this.$store.dispatch('copyInformation');
      this.$store.dispatch('openSnackbar', {
        color: 'success',
        message: this.$t('informations.copied'),
      });
    },
  },
};
</script>
