<template>
  <v-menu
    v-model="menu"
    transition="scale-transition"
    offset-y
    :close-on-content-click="false"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        :class="{
          'ml-2': $vuetify.breakpoint.smAndUp,
          'pr-0': $vuetify.breakpoint.xsOnly,
        }"
        text
        :small="$vuetify.breakpoint.xsOnly"
        v-bind="attrs"
        v-on="on"
      >
        {{ currentDateTillMonthFormatted }}
        <v-icon right> mdi-chevron-down </v-icon>
      </v-btn>
    </template>
    <v-date-picker
      :value="currentDateDependingOnType"
      :type="type"
      first-day-of-week="1"
      width="270"
      no-title
      scrollable
      :locale="$i18n.locale"
      @change="dateUpdated"
    />
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex';
import { getMonth, getYear, getDate } from 'date-fns';

import { sendGoogleAnalyticsEvent } from '../../plugins/vue-gtag';

export default {
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    ...mapGetters(['currentDate']),

    currentDateTillMonthFormatted() {
      return this.$i18n.d(
        App.helpers.getDateObject(this.currentDate),
        'yearMonth',
      );
    },

    currentDateDependingOnType() {
      if (this.isMonthView) {
        return this.currentDateTillMonth;
      }
      return this.currentDate;
    },

    type() {
      if (this.isMonthView) {
        return 'month';
      }
      return 'date';
    },

    isMonthView() {
      return this.$route.meta.name === 'Month';
    },

    currentDateTillMonth() {
      if (this.currentDate === null) {
        return null;
      }
      return this.currentDate.substr(0, 7);
    },
  },
  methods: {
    close() {
      this.menu = false;
    },

    dateUpdated(value) {
      this.close();
      const date = App.helpers.getDateObject(value);
      this.$store.dispatch('setYearOfCurrentDate', getYear(date));
      this.$store.dispatch('setMonthOfCurrentDate', getMonth(date));
      if (!this.isMonthView) {
        this.$store.dispatch('setDayOfCurrentDate', getDate(date));
      }
      sendGoogleAnalyticsEvent('dateUpdated', value);
    },
  },
};
</script>
