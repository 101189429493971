import axios from 'axios';
import accountsAppBaseUrl from '../accountsAppBaseUrl';

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.baseURL = process.env.BASE_URL;

window.axios.interceptors.response.use(undefined, (error) => {
  if (axios.isCancel(error)) {
    return Promise.reject(error);
  }

  if (typeof error.response !== 'undefined') {
    if (
      error.response.status === 401 &&
      error.response.data.message === 'Unauthenticated.'
    ) {
      const url = new URL(window.location.href);
      const redirectTo = encodeURIComponent(`${url.pathname}${url.search}`);
      window.location = `${accountsAppBaseUrl}?continue=${redirectTo}`;
    }
    if (
      (error.response.status === 403 &&
        error.response.data === 'Scheduler usage forbidden.') ||
      (error.response.status === 500 &&
        error.response.data.message ===
          "User doesn't exist in scheduler database.")
    ) {
      window.location = accountsAppBaseUrl;
    }
  }

  return Promise.reject(error);
});
