<template>
  <v-tooltip bottom :disabled="$vuetify.breakpoint.xsOnly">
    <template #activator="{ on }">
      <v-btn icon @click="$emit('click')" v-on="on">
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <span>{{ message }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
};
</script>
