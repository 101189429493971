const fgColourMixin = {
  methods: {
    getTextColor(color) {
      // Refer: https://stackoverflow.com/a/3943023/6402452
      const rgb = [
        +`0x${color[1]}${color[2]}`,
        +`0x${color[3]}${color[4]}`,
        +`0x${color[5]}${color[6]}`,
      ];
      const linearRgb = rgb.map((channel) => {
        const linColor = channel / 255;
        if (linColor <= 0.03928) {
          return linColor / 12.92;
        }
        return ((linColor + 0.055) / 1.055) ** 2.4;
      });

      const [red, green, blue] = linearRgb;

      const luminence = 0.2126 * red + 0.7152 * green + 0.0722 * blue;
      if (luminence > 0.179) {
        return '#000000';
      }
      return '#FFFFFF';
    },
  },
};

export default fgColourMixin;
