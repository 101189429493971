<template>
  <div>
    <confirm ref="confirm" />

    <v-navigation-drawer v-model="tasksDrawer" right app width="400px">
      <template #prepend>
        <v-card class="blue lighten-4">
          <v-card-title class="py-3 pr-2">
            {{ $t('tasks.title') }}
            <v-spacer />
            <v-btn icon @click="tasksDrawer = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-card-title>
        </v-card>
        <v-progress-linear
          :indeterminate="isProgressBarActive"
          :active="isProgressBarActive"
          absolute
          color="#0D47A1"
        />
      </template>

      <v-divider />

      <v-row class="ma-1 my-3">
        <SingleUserSelector
          :label="null"
          :multiple="false"
          :default-user="user_id"
          :is-not-clearable="true"
          :need-outline="false"
          :need-solo="true"
          @change="updateUserSelection"
        />
      </v-row>

      <v-card outlined>
        <v-expansion-panels v-model="addEditTaskPanel" tile accordion flat>
          <v-expansion-panel :disabled="!canWrite">
            <v-expansion-panel-header
              class="blue lighten-5"
              expand-icon="mdi-plus"
            >
              {{ formTitle }}
            </v-expansion-panel-header>
            <v-expansion-panel-content eager>
              <div class="pa-3">
                <add-edit-task
                  ref="addEditTask"
                  :is-editing="isEditing"
                  :task-to-be-edited="taskToBeEdited"
                  :user-id="user_id"
                  @add="afterTaskAddEdit"
                  @edit="afterTaskAddEdit"
                  @close="closeAddEditTaskPanel"
                />
              </div>
            </v-expansion-panel-content>
            <v-divider class="grey" />
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>

      <v-list dense>
        <v-list-item
          v-for="task in incompleteTasks"
          :key="task.id"
          link
          class="pa-0"
        >
          <v-list-item-action
            class="ma-0 ml-1"
            @mouseover="task.hover = true"
            @mouseleave="task.hover = false"
          >
            <v-btn
              v-if="canUpdateTaskStatus(task)"
              icon
              @click="updateTaskStatus(task)"
            >
              <v-icon>{{ taskStatusIcon(task, task.hover) }}</v-icon>
            </v-btn>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ task.subject ? task.subject : $t('tasks.busy') }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ task.description ? task.description : null }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="task.start" style="font-size: 10px">
              {{
                task.all_day
                  ? $d(App.helpers.getDateObject(task.start), 'allDayEvent')
                  : $d(App.helpers.getDateObject(task.start), 'eventTime')
              }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action class="ml-1">
            <div>
              <v-btn v-if="canWrite" icon small @click="setTaskToEdit(task)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn v-if="canWrite" icon small @click="deleteTask(task)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <template #append>
        <v-expansion-panels v-model="completedTasksPanel">
          <v-expansion-panel>
            <v-expansion-panel-header
              class="blue lighten-5"
              expand-icon="mdi-chevron-up"
            >
              {{
                ` ${$t('tasks.completedTasks')} ${
                  completedTasks.length > 0
                    ? '(' + completedTasks.length + ')'
                    : ''
                }`
              }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list v-if="completedTasks.length > 0" dense class="pa-0" />
              <center v-else class="pt-3">
                <font size="2">{{ $t('tasks.noCompletedTasks') }}</font>
              </center>

              <v-virtual-scroll
                :items="completedTasks"
                :bench="2"
                item-height="60"
                height="350"
              >
                <template #default="{ item }">
                  <v-list-item link class="pa-0">
                    <v-list-item-action
                      class="my-0 mx-2"
                      @mouseover="item.hover = true"
                      @mouseleave="item.hover = false"
                    >
                      <v-btn
                        v-if="canUpdateTaskStatus(item)"
                        icon
                        small
                        @click="updateTaskStatus(item)"
                      >
                        <v-icon>
                          {{ taskStatusIcon(item, item.hover) }}
                        </v-icon>
                      </v-btn>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        style="
                          font-size: 0.8125rem;
                          font-weight: 500;
                          line-height: 1rem;
                        "
                      >
                        {{ item.subject ? item.subject : $t('tasks.busy') }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        style="
                          font-size: 0.75rem;
                          font-weight: 500;
                          line-height: 1rem;
                        "
                        class="pb-1"
                      >
                        {{ item.description ? item.description : null }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        v-if="item.start"
                        style="font-size: 10px"
                      >
                        {{
                          item.all_day
                            ? $d(
                                App.helpers.getDateObject(item.start),
                                'allDayEvent',
                              )
                            : $d(
                                App.helpers.getDateObject(item.start),
                                'eventTime',
                              )
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action class="ml-1">
                      <div>
                        <v-btn
                          v-if="canWrite"
                          icon
                          small
                          @click="setTaskToEdit(item)"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn
                          v-if="canWrite"
                          icon
                          small
                          @click="deleteTask(item)"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </div>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-virtual-scroll>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SingleUserSelector from '../SingleUserSelector.vue';
import Confirm from '../Confirm.vue';
import AddEditTask from '../AddEditTask.vue';

export default {
  components: {
    SingleUserSelector,
    Confirm,
    AddEditTask,
  },
  data() {
    return {
      user_id: null,
      isEditing: false,
      addEditTaskPanel: null,
      completedTasksPanel: null,
      canWrite: true,
      incompleteTasks: [],
      completedTasks: [],
      taskToBeEdited: null,
      taskStatusHover: false,
      isProgressBarActive: false,
    };
  },
  computed: {
    ...mapGetters(['name', 'id', 'preferences', 'userData']),
    tasksDrawer: {
      get() {
        return this.$store.getters.tasksDrawer;
      },
      set(value) {
        this.$store.commit('setTasksDrawer', value);
      },
    },
    formTitle() {
      return this.isEditing ? this.$t('tasks.edit') : this.$t('tasks.add');
    },
  },
  watch: {
    tasksDrawer(newValue) {
      if (newValue === true) {
        this.onOpeningNavDrawer();
      } else {
        this.quitEditMode();
      }
    },
  },
  created() {
    this.user_id = this.id;
    this.$root.$on('set-task-to-edit', (task) => this.setTaskToEdit(task));
    this.$root.$on('tasks-updated-from-calendar', this.getTaskList);
  },
  methods: {
    canUpdateTaskStatus(task) {
      if (task.user_id === this.id) {
        return true;
      }
      return false;
    },
    taskStatusIcon(task, status) {
      if (task.is_done) {
        return 'mdi-checkbox-marked-circle-outline';
      }
      if (!status) {
        return 'mdi-checkbox-blank-circle-outline';
      }
      return 'mdi-check';
    },

    updateTaskStatus(task) {
      axios
        .put(`api/task/${task.id}/status`, {
          is_done: !task.is_done,
        })
        .then(() => {
          this.afterTaskAddEdit();
        })
        .catch((error) => {
          error = error.response.data;
          if (error.errorCode) {
            this.$store.dispatch('openSnackbar', {
              color: 'error',
              message: this.$t(`tasks.${error.messageCode}`),
            });
            return;
          }
          this.$store.dispatch('openSnackbar', {
            color: 'error',
            message: this.$t('common.somethingWentWrong'),
          });
        });
    },

    onOpeningNavDrawer() {
      this.completedTasksPanel = null;
      this.fetchDefaultUsersTasks();
    },

    fetchDefaultUsersTasks() {
      this.user_id = this.id;
      this.getTaskList();
    },

    closeAddEditTaskPanel() {
      this.addEditTaskPanel = null;
      this.quitEditMode();
    },
    async getTaskList() {
      this.isProgressBarActive = true;
      this.incompleteTasks = await this.getIncompleteUserTasks();
      this.completedTasks = await this.getCompletedUserTasks();
      this.isProgressBarActive = false;
      this.assignHoverPropertyIncompleteTasks();
      this.assignHoverPropertyCompletedTasks();
    },
    assignHoverPropertyIncompleteTasks() {
      this.incompleteTasks.forEach((task) => {
        this.$set(task, 'hover', false);
      });
    },

    assignHoverPropertyCompletedTasks() {
      this.completedTasks.forEach((task) => {
        this.$set(task, 'hover', false);
      });
    },

    afterTaskAddEdit() {
      this.closeAddEditTaskPanel();
      this.getTaskList();
      this.$root.$emit('update-user-calendar', this.user_id);
    },

    setTaskToEdit(task) {
      this.tasksDrawer = true;
      this.addEditTaskPanel = 0;
      this.isEditing = true;
      this.taskToBeEdited = task;
      this.$nextTick(() => {
        this.user_id = task.user_id;
        this.getTaskList();
      });
    },
    quitEditMode() {
      this.isEditing = false;
      this.taskToBeEdited = null;
      this.addEditTaskPanel = null;
      this.$refs.addEditTask.resetFormValues();
    },
    getIncompleteUserTasks() {
      return axios
        .get(`api/user/${this.user_id}/tasks/incomplete`)
        .then((response) => response.data)
        .catch(() => {});
    },
    getCompletedUserTasks() {
      return axios
        .get(`api/user/${this.user_id}/tasks/completed`)
        .then((response) => response.data)
        .catch(() => {});
    },
    async updateUserSelection(userId) {
      if (!userId || !this.userData(userId)) {
        return;
      }
      this.user_id = userId;
      const calendarId = this.userData(this.user_id).default_calendar.id;
      await axios
        .get(`api/user/check-permission/calendar/${calendarId}/`)
        .then(() => {
          this.canWrite = true;
        })
        .catch((error) => {
          if (error.response.status == 403) {
            this.canWrite = false;
          }
        });
      this.getTaskList();
    },
    async deleteTask(task) {
      const confirmation = await this.$refs.confirm.open(
        this.$t('tasks.deleteTask'),
        this.$t('tasks.deleteTaskBody'),
      );
      if (confirmation === true) {
        axios
          .delete(`api/task/${task.id}`)
          .then(() => {
            this.getTaskList();
            this.$root.$emit('update-user-calendar', this.user_id);
          })
          .catch(() => {});
      }
    },
  },
};
</script>

<style>
.v-label.theme--light {
  font-size: 14px;
}

.v-expansion-panel-content__wrap {
  padding: 0px 0px 0px 0px !important;
}
</style>
