<template>
  <div class="text-center">
    <v-menu
      v-model="isMenuOpen"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-y
    >
      <template #activator="{ on }">
        <v-btn icon v-on="on">
          <v-badge
            overlap
            :value="unreadNotifications === 0 ? false : true"
            :content="unreadNotifications"
          >
            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>
      <TheNotificationsMenu
        :notifications="notifications"
        @click="handleNotificationAction"
        @click:toggle-read-status="toggleReadUnread"
        @click:mark-all-read="markAllAsRead"
        @mark-all-as-read="markAllAsRead"
      />
    </v-menu>

    <MeetingNotificationModal
      ref="meetingNotificationDialog"
      @meeting-not-found="openResourceNotFoundModel"
    />

    <DisplayInformation
      ref="displayInformation"
      :disable-actions="true"
    />

    <ResourceNotFoundModal
      v-model="isResourceNotFound"
      :message="$t('resourceNotFound.meetingDeletedText')"
    />

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { sendGoogleAnalyticsEvent } from '../../plugins/vue-gtag';
import {
  getNotifications,
  markAllNotificationsRead,
  markNotificationRead,
  markNotificationUnread,
} from '../../api/notifications.api';
import TheNotificationsMenu from './TheNotificationsMenu.vue';
import MeetingNotificationModal from './MeetingNotificationModal.vue';
import DisplayInformation from '../../pages/Informations/components/DisplayInformation.vue';
import ResourceNotFoundModal from '../ResourceNotFoundModal.vue';

export default {
  components: {
    TheNotificationsMenu,
    MeetingNotificationModal,
    DisplayInformation,
    ResourceNotFoundModal,
  },

  data() {
    return {
      notifications: [],
      isMenuOpen: false,
      isResourceNotFound: false,
    };
  },

  computed: {
    ...mapGetters(['id']),

    unreadNotifications() {
      return this.notifications.filter(
        (notification) => notification.read_at == null,
      ).length;
    },
  },

  watch: {
    id(value) {
      if (value === null) {
        this.handleUserLoggedOut();
      }
    }
  },

  created() {
    if (this.id) {
      this.fetchNotifications();
    }
    window.Echo.connector.pusher.connection.bind('connected', () => {});
    this.echoInit();
  },

  methods: {
    async fetchNotifications() {
      this.notifications = await getNotifications();
    },

    echoInit() {
      window.Echo.private(`App.User.${this.id}`).notification(
        (notification) => {
          if (notification.type !== 'App\\Notifications\\EventReminder') {
            this.fetchNotifications();
          }
        },
      );
    },

    async markAllAsRead() {
      await markAllNotificationsRead();
      this.fetchNotifications();
      sendGoogleAnalyticsEvent('markedAllAsRead');
    },

    async markRead(notification) {
      await markNotificationRead(notification.id);
      this.fetchNotifications();
    },

    async markUnread(notification) {
      await markNotificationUnread(notification.id);
      this.fetchNotifications();
    },

    toggleReadUnread(notification) {
      if (notification.read_at) {
        this.markUnread(notification);
      } else {
        this.markRead(notification);
        if (this.isInformationNotification(notification)) {
          this.$refs.displayInformation.markAsSeen(
            notification.data.notification.event.id,
          );
        }
      }
    },

    isInformationNotification(notification) {
      if (
        notification.type ===
          'App\\Notifications\\InformationUpdatedNotification' ||
        notification.type ===
          'App\\Notifications\\InformationPostedNotification'
      ) {
        return true;
      }
      return false;
    },

    handleNotificationAction(notification) {
      switch (notification.type) {
        case 'App\\Notifications\\EventDeletedNotification':
        case 'App\\Notifications\\MeetingDeletedNotification':
          break;
        case 'App\\Notifications\\EventCreatedNotification':
        case 'App\\Notifications\\EventUpdatedNotification':
          this.redirectToWeekView(notification);
          this.$emit('close');
          break;
        case 'App\\Notifications\\MeetingInviteNotification':
        case 'App\\Notifications\\MeetingUpdatedNotification':
        case 'App\\Notifications\\MeetingResponseNotification':
          this.openMeetingInfoDialog(notification.data.notification.event);
          this.$emit('close');
          break;
        case 'App\\Notifications\\InformationPostedNotification':
        case 'App\\Notifications\\InformationUpdatedNotification':
          this.openInformationDetailsDialog(
            notification.data.notification.event,
          );
          this.$emit('close');
          break;
        default:
          break;
      }
      if (!notification.read_at) {
        this.markRead(notification);
      }
    },

    redirectToWeekView(notification) {
      const start = notification.data.notification.event.start
        ? notification.data.notification.event.start
        : notification.data.notification.event.event_start;
      this.$store.dispatch('setCurrentDate', start.split(' ')[0]);
      const queryParams = this.computeQueryParameters(
        notification.data.notification.event,
      );

      this.$router.push({
        name: 'week',
        query: queryParams,
      });
    },

    computeQueryParameters(event) {
      switch (event.schedule_type) {
        case 'task':
          return {
            task: event.id,
          };
        case 'recurring_event':
          return {
            recurring_event: event.id,
          };
        default:
          return {
            event: event.id,
          };
      }
    },

    async openMeetingInfoDialog(notification) {
      const meetingId = notification.id;
      this.$refs.meetingNotificationDialog.open(meetingId);
    },

    openResourceNotFoundModel() {
      this.isResourceNotFound = true;
    },

    async openInformationDetailsDialog(information) {
      this.$store.dispatch('fetchAndSetDisplayInformation', information.id);
      this.$store.dispatch('markInformationAsSeen', information.id);
    },

    handleUserLoggedOut() {
      this.notifications = [];
    }
  },
};
</script>
