import { mapGetters } from 'vuex';

const meetingEventChip = {
  computed: {
    ...mapGetters(['normalizedEventTypes']),
  },
  methods: {
    getEventChipStyle(event, type = 'week') {
      const eventTypeId = event.event_type_id;
      const backgroundColor = this.getEventType(eventTypeId).color;
      const isAttendingStatusNotGoing = this.isAttendingStatusNotGoing(event);
      const isAttendingStatusMaybe = this.isAttendingStatusMaybe(event);
      if (isAttendingStatusMaybe || isAttendingStatusNotGoing) {
        return {
          '--shadow-color': backgroundColor,
          color: 'black',
          height: type === 'month' ? '20px' : '100%',
        };
      }
      const textColor = this.getTextColorForEventTypeId(eventTypeId);
      return { backgroundColor, color: textColor };
    },

    getEventType(id) {
      if (id && this.normalizedEventTypes[id]) {
        return this.normalizedEventTypes[id];
      }
      return { id, name: '', color: '#CFD8DC' };
    },

    getTextColorForEventTypeId(id) {
      const eventType = this.getEventType(id);
      if (eventType) {
        return this.$root.getTextColor(eventType.color);
      }
      return 'black';
    },

    isAttendingStatusNotGoing(event) {
      return this.isMeeting(event) && event.attending_status === 0;
    },

    isAttendingStatusMaybe(event) {
      return this.isMeeting(event) && event.attending_status === null;
    },

    isMeeting(event) {
      return event.meeting_id;
    },
  },
};

export default meetingEventChip;
