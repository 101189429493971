import httpClient from './httpClient';

const createMeeting = (
  subject,
  description,
  eventTypeId,
  start,
  end,
  members,
  facilities,
) => {
  const endpoint = 'meetings';
  return httpClient.post(endpoint, {
    subject,
    description,
    event_type_id: eventTypeId,
    start,
    end,
    members,
    facilities,
  });
};

const updateMeeting = (
  meetingId,
  subject,
  description,
  eventTypeId,
  start,
  end,
  members,
  facilities,
) => {
  const endpoint = 'meetings';
  return httpClient.patch(endpoint, {
    id: meetingId,
    subject,
    description,
    event_type_id: eventTypeId,
    start,
    end,
    members,
    facilities,
  });
};

const updateAttendance = (meeting, attendanceResponse) =>
  httpClient.patch(`meetings/${meeting.id}/attendance`, {
    attendanceResponse,
  });

const deleteMeeting = (meetingId) => httpClient.delete(`meetings/${meetingId}`);

export { updateAttendance, createMeeting, updateMeeting, deleteMeeting };
