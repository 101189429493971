<template>
  <v-menu offset-y offset-x min-width="260">
    <template #activator="{ on }">
      <div v-ripple style="cursor: pointer" class="ml-2" v-on="on">
        <v-badge
          bordered
          bottom
          :color="userStatus ? statusTypes[userStatus].color : 'grey'"
          dot
          offset-x="10"
          offset-y="10"
        >
          <v-avatar
            :size="$vuetify.breakpoint.xsOnly ? '22px' : '38px'"
            color="primary"
            class="white--text title"
          >
            {{ user.name.charAt(0) }}
          </v-avatar>
        </v-badge>
      </div>
    </template>
    <v-card width="260px">
      <v-card-text class="pa-0">
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-avatar color="primary" class="white--text title">
                {{ user.name.charAt(0) }}
              </v-avatar>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="text-wrap">
                {{ user.name }}
              </v-list-item-title>
              <v-list-item-subtitle class="text-wrap">
                {{ user.departmentName }}
                <StatusSelector v-if="userStatus" />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider />

          <v-list-item :href="accountManagementPage" target="_blank">
            <v-list-item-icon>
              <v-icon>mdi-account-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t('toolbar.accountManagement') }}
            </v-list-item-title>
          </v-list-item>
          <v-divider />

          <template v-if="doesUserBelongToMultipleTenants">
            <v-list-item @click.stop="openSwitchOrganizationModal">
              <v-list-item-icon>
                <v-icon>mdi-domain-switch</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('UserMenu.switchOrganization') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider />
          </template>

          <v-list-item ripple @click="$store.dispatch('logout')">
            <v-list-item-icon>
              <v-icon>mdi-exit-to-app</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('toolbar.logout') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
    <TheSwitchOrganizationModal ref="theSwitchOrganizationModal" />
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex';
import accountsAppBaseUrl from '../../accountsAppBaseUrl';
import StatusSelector from '../StatusSelector.vue';
import { sendGoogleAnalyticsEvent } from '../../plugins/vue-gtag';
import TheSwitchOrganizationModal from './TheSwitchOrganizationModal.vue';

export default {
  components: {
    StatusSelector,
    TheSwitchOrganizationModal,
  },

  computed: {
    ...mapGetters(['user', 'userStatus', 'statusTypes', 'currentTenant']),

    isPageWithCalendar() {
      return ['calendar', 'facilities'].includes(this.$route.meta.page);
    },

    accountManagementPage() {
      sendGoogleAnalyticsEvent('openAccountManagementPageFromUserMenu');
      return `${accountsAppBaseUrl}my-account`;
    },

    userManagementPage() {
      sendGoogleAnalyticsEvent('openUserManagementPageFromUserMenu');
      return `${accountsAppBaseUrl}admin/users`;
    },

    doesUserBelongToMultipleTenants() {
      return this.user.tenants.length > 1;
    },
  },

  created() {
    this.$store.dispatch('fetchAndSetUserStatus');
  },

  methods: {
    openSwitchOrganizationModal() {
      this.$refs.theSwitchOrganizationModal.open();
    },
  }
};
</script>
