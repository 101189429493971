<template>
  <DoubleClick @double-click="emitCellDoubleClick">
    <v-card
      :id="rowIndex + '-' + cellIndex"
      class="ma-0"
      :ripple="false"
      tile
      outlined
      :style="{
        'background-color': backgroundColor,
        'border-width': '0 0 1px 1px',
        cursor: 'default',
      }"
      :min-width="40"
      height="100%"
      width="100%"
    >
      <SkeletonEventChips v-if="isGridLoading" />
      <draggable
        v-else
        :id="[rowIndex, cellIndex]"
        :disabled="$vuetify.breakpoint.smAndDown"
        :list="cellData"
        :group="{ name: 'Schedules' }"
        :style="{ height: '100%' }"
        @mousedown.native="emitStartDrag"
        @mouseover.native="emitModifyDrag"
        @mouseup.native="emitEndDrag"
        @start="emitCellItemDragStart"
        @change="emitCellItemDropped"
      >
        <div v-for="(schedule, index) in cellData" :key="index">
          <div :id="[rowIndex, cellIndex]" :style="eventChipStyle(schedule)">
            <EventChip
              :schedule="schedule"
              @event-chip-clicked="emitEventClick(schedule, $event)"
            />
          </div>
        </div>
      </draggable>
    </v-card>
  </DoubleClick>
</template>

<script>
import { mapGetters } from 'vuex';
import { differenceInCalendarDays } from 'date-fns';
import draggable from 'vuedraggable';
import DoubleClick from '../../../components/DoubleClick.vue';
import SkeletonEventChips from '../../../components/Calendar/SkeletonEventChips.vue';
import EventChip from '../../../components/EventChip.vue';

export default {
  components: {
    draggable,
    DoubleClick,
    EventChip,
    SkeletonEventChips,
  },
  props: {
    cellData: {
      type: [Array, Object],
      required: true,
    },
    cellIndex: {
      type: Number,
      required: true,
    },
    rowIndex: {
      type: Number,
      required: true,
    },
    weekHeader: {
      type: Array,
      default: () => [],
    },
    isGridLoading: {
      type: Boolean,
      default: false,
    },
    isDragging: {
      type: Boolean,
      default: false,
    },
    dragRowIndex: {
      type: Number,
      default: null,
    },
    dragStartCellIndex: {
      type: Number,
      default: null,
    },
    dragEndCellIndex: {
      type: Number,
      default: null,
    },
    isHighlighted: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['isNationalHoliday']),
    breakpoint() {
      return this.$vuetify.breakpoint.name;
    },
    date() {
      return App.helpers.getISODateString(this.weekHeader[this.cellIndex]);
    },
    day() {
      return App.helpers.getDateObject(this.date).getDay();
    },
    isPastDate() {
      return this.date < App.helpers.getISODateString(new Date());
    },
    isSundayOrNationalHoliday() {
      return this.day === 0 || this.isNationalHoliday(this.date) !== undefined;
    },
    isSaturday() {
      return this.day === 6;
    },
    backgroundColor() {
      if (this.isHighlighted || this.isCellIncludedInDrag) {
        return '#BBDEFD';
      }
      if (this.isSundayOrNationalHoliday) {
        return this.isPastDate ? 'rgb(215,185,185)' : 'rgb(255,235,238)';
      }
      if (this.isSaturday) {
        return this.isPastDate ? 'rgb(194,218,225)' : 'rgb(225, 245, 254)';
      }
      return this.isPastDate ? 'rgb(249,249,249)' : '';
    },
    isCellIncludedInDrag() {
      if (this.isDragging === false || this.dragRowIndex !== this.rowIndex) {
        return false;
      }
      const startIndex = this.dragStartCellIndex;
      const endIndex = this.dragEndCellIndex;
      const minIndex = Math.min(startIndex, endIndex);
      const maxIndex = Math.max(startIndex, endIndex);
      return minIndex <= this.cellIndex && maxIndex >= this.cellIndex;
    },
    draggableHeight() {
      const row = document.getElementById(`row${this.rowIndex}`);
      return row === null ? '90px' : row.style.height;
    },
  },
  methods: {
    eventChipStyle(event) {
      let styleString = 'position:absolute;z-index:2;';
      const positionFromTop = (event.position - 1) * 21;
      const width = this.getEventWidth(event);
      styleString += `top:${positionFromTop}px; width:${width}%;`;

      this.$nextTick(() => {
        const elementRow = document.getElementById(`row${this.rowIndex}`);
        if (elementRow == null) {
          return;
        }
        if (parseInt(elementRow.style.height, 10) - positionFromTop < 50) {
          elementRow.style.height = `${positionFromTop + 50}px`;
        }
      });
      return styleString;
    },

    getEventWidth(event) {
      const singleCellEventWidth = 100;
      let width = singleCellEventWidth;
      if (event.multiday === true) {
        width = this.cellsOccupiedByEvent(event) * singleCellEventWidth;
      }
      return width - 3;
    },

    cellsOccupiedByEvent(event) {
      const eventEnd = event.end.split(' ')[0];
      const eventStart = event.start.split(' ')[0];
      const weekEnd = App.helpers.getISODateString(this.weekHeader[6]);
      const weekStart = App.helpers.getISODateString(this.weekHeader[0]);
      let start = eventStart < weekStart ? weekStart : eventStart;
      let end = eventEnd > weekEnd ? weekEnd : eventEnd;
      start = App.helpers.getDateObject(start);
      end = App.helpers.getDateObject(end);
      return differenceInCalendarDays(end, start) + 1;
    },

    emitStartDrag(mouseEvent) {
      this.$emit('start-drag', mouseEvent);
    },

    emitModifyDrag(mouseEvent) {
      this.$emit('modify-drag', mouseEvent);
    },

    emitEndDrag(mouseEvent) {
      this.$emit('end-drag', mouseEvent);
    },

    emitCellDoubleClick() {
      this.$emit('cell-double-click');
    },

    emitEventClick(schedule, nativeEvent) {
      this.$emit('event-click', schedule, nativeEvent);
    },

    emitCellItemDragStart(event) {
      this.$emit('cell-item-dragged', this.cellData[event.oldIndex]);
    },

    emitCellItemDropped(item) {
      if (item.added) {
        this.$emit('cell-item-dropped', item.added.element);
      }
    },
  },
};
</script>
