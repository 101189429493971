<template>
  <v-list-item-icon class="mr-2 py-auto my-auto">
    <v-icon size="20">{{ notificationIcon }}</v-icon>
  </v-list-item-icon>
</template>

<script>
export default {
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },

  computed: {
    notificationIcon() {
      switch (this.notification.data.notification.type) {
        case 'InformationUpdated':
        case 'InformationPosted':
          return 'mdi-bullhorn';
        case 'Deleted':
        case 'Updated':
        case 'Created':
          return 'mdi-calendar';
        case 'Invited':
        case 'Response':
        case 'Edited':
        case 'DeletedMeeting':
          return 'mdi-account-group';
        case 'BookingDeleted':
          return 'mdi-seat';
        default:
          return '';
      }
    },
  },
};
</script>
