import '../../globalAppObject';

const state = {
  nationalHolidays: [],
  isLoadingNationalHolidays: false,
};

const getters = {
  nationalHolidays: (state) => state.nationalHolidays,
  isLoadingNationalHolidays: (state) => state.isLoadingNationalHolidays,
  yearsWithNationalHolidays: (state) => {
    const years = new Set();
    state.nationalHolidays.forEach((holiday) => {
      const date = App.helpers.getDateObject(holiday.date);
      years.add(date.getFullYear());
    });
    return [...years];
  },
  isNationalHoliday: (state) => (date) =>
    state.nationalHolidays.find(
      (nationalHoliday) => nationalHoliday.date == date,
    ),
  nationalHolidaysAsEvents: (state) => {
    let nh = [];
    nh = JSON.parse(JSON.stringify(state.nationalHolidays));
    nh.forEach((nationalHoliday, index) => {
      nationalHoliday.isNationalHoliday = true;
      nationalHoliday.allDay = true;
      nationalHoliday.start = nationalHoliday.date;
      nationalHoliday.end = nationalHoliday.date;
      nh[index] = nationalHoliday;
    });
    return nh;
  },
};

const mutations = {
  setNationalHolidays: (state, payload) => {
    state.nationalHolidays = payload;
  },
  setIsLoadingNationalHolidays: (state, payload) => {
    state.isLoadingNationalHolidays = payload;
  },
};

const actions = {
  async fetchNationalHolidays(context) {
    context.commit('setIsLoadingNationalHolidays', true);
    const response = await axios.get('api/national-holidays');
    context.commit('setIsLoadingNationalHolidays', false);
    context.commit('setNationalHolidays', response.data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
