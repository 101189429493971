<template>
  <v-card width="500px">

    <TheNotificationsMenuTitle
      :notifications="notifications"
      @click:mark-all-read="$emit('click:mark-all-read')"
    />

    <v-divider />

    <v-card-text class="pa-0">

      <v-list v-if="notifications.length > 0">
        <div v-for="notification in notifications" :key="notification.id">
          <TheNotificationsMenuListItem
            :notification="notification"
            @click="$emit('click', notification)"
            @click:toggle-read-status="$emit('click:toggle-read-status', notification)"
          />
        </div>
      </v-list>

      <v-row v-else class="pa-8">
        <v-col>{{ $t('notifications.noNotification') }}</v-col>
      </v-row>

    </v-card-text>

  </v-card>
</template>

<script>
import TheNotificationsMenuTitle from './TheNotificationsMenuTitle.vue';
import TheNotificationsMenuListItem from './TheNotificationsMenuListItem.vue';


export default {
  components: {
    TheNotificationsMenuTitle,
    TheNotificationsMenuListItem,
  },

  props: {
    notifications: {
      type: Array,
      required: true,
    },
  }

};
</script>

<style scoped>
.v-list {
  max-height: 85vh;
  overflow-y: auto;
}
</style>
