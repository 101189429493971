import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import router from '../router';
import store from '../store/index';

const isProduction = process.env.NODE_ENV === 'production';

Sentry.init({
  Vue,
  dsn: 'https://95b7aa3493af4b64857474d8dcf0839b@o406554.ingest.sentry.io/6080437',
  logErrors: true,
  environment: isProduction ? 'production' : 'localhost',
  release: isProduction ? process.env.VUE_APP_APP_VERSION : '',
});

function getSubdomain() {
  return window.location.host.split('.')[0];
}

Sentry.setTag('tenant', getSubdomain());

export default function setUserInSentry() {
  Sentry.setUser({ email: store.getters.email });
}
