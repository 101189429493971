import httpClient from './httpClient';

const getNotifications = async () => {
  const response = await httpClient.get('/notifications');
  return response.data;
};

const markAllNotificationsRead = async () => {
  const response = await httpClient.patch('/notifications/mark-all-read');
  return response.data;
};

const markNotificationRead = async (notificationId) => {
  const response = await httpClient.patch(
    `/notifications/${notificationId}/mark-read`,
  );
  return response.data;
};

const markNotificationUnread = async (notificationId) => {
  const response = await httpClient.patch(
    `/notifications/${notificationId}/mark-unread`,
  );
  return response.data;
};

export {
  getNotifications,
  markAllNotificationsRead,
  markNotificationRead,
  markNotificationUnread,
};
