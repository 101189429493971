import linkifyStr from 'linkify-string';
import xss from 'xss';

const calendarAndBookings = {
  methods: {
    formatPlace(place) {
      if (place === null) {
        return null;
      }
      place = xss(place);
      place = linkifyStr(place, {
        defaultProtocol: 'https',
        target: '_blank',
      });
      return place;
    },

    formatDescription(description) {
      if (description === null) {
        return null;
      }
      description = xss(description);
      description = linkifyStr(description, {
        defaultProtocol: 'https',
        target: '_blank',
        nl2br: true,
      });
      return description;
    },

  },
};

export default calendarAndBookings;
