<template>
  <span />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      isNotificationEnabled: false,
      swreg: null,
      subscription: null,
    };
  },

  computed: {
    ...mapGetters(['preferences', 'id']),
    isNotificationSupported() {
      return 'Notification' in window && 'serviceWorker' in navigator;
    },
  },

  mounted() {
    /**
     * Global listener for enablepush subscription call from user preferences
     */
    this.$root.$on('enable-push-subscription', () =>
      this.enablePushSubscription(),
    );

    if (!this.isNotificationSupported) {
      console.log("browser doesn't support notifications.");
      return;
    }

    this.findSubscription().then((sub) => {
      if (this.preferences.reminder_type === 'push' && sub === null) {
        console.log('push notification needed from preferences and no sub');
        this.enablePushSubscription();
        return;
      }
      if (sub === null) {
        console.log('no sub and preferences is set to off, nothing to do here');
        this.isNotificationEnabled = false;
        this.subscription = null;
        return;
      }
      if (sub !== null) {
        console.log('existing subscription found');
        this.isNotificationEnabled = true;
        this.subscription = sub;
      }
    });
  },
  methods: {
    findSubscription() {
      return navigator.serviceWorker.ready.then((swreg) => {
        this.swreg = swreg;
        return this.getSubscription(swreg);
      });
    },

    getSubscription(swreg) {
      return swreg.pushManager.getSubscription();
    },

    /**
     * Checks if Notification is enabled for the browser, i.e, if there was a subscription created
     * for this browser.
     * If not,  it will request permission from the user, and based on the permission, will
     * create a new subscription. The new subscription is stored on the backend using an api request.
     * The store state is updated. A welcome notification is displayed.
     * If permission is denied, then on such browsers we will show error message like Google:
     * "Your browser blocks notifications"
     */

    enablePushSubscription() {
      if (!this.isNotificationSupported) {
        console.log('notifications not supported on browser');
        return;
      }
      if (this.isNotificationEnabled) {
        console.log('notifications already enabled');
        return;
      }
      Notification.requestPermission().then((result) => {
        if (result === 'granted') {
          console.log('creating new subscription');
          this.createSubscription()
            .then((sub) => {
              this.subscription = sub;
              console.log('store subs request');
              return axios.post('api/subscription', {
                subscription: sub,
              });
            })
            .then(() => {
              this.showNotification();
              this.isNotificationEnabled = true;
            });
          console.log('granting push notification success');
        } else {
          console.log('granting push notification failed');
        }
      });
    },

    createSubscription() {
      if (this.swreg === null) {
        return navigator.serviceWorker.ready
          .then((swreg) => {
            this.swreg = swreg;
            return this.subscribe(swreg);
          })
          .catch(() => {});
      }
      return this.subscribe(this.swreg);
    },

    subscribe(swreg) {
      const vapidPublicKey = process.env.VUE_APP_VAPID_PUBLIC_KEY;
      const convertedVapidPublicKey =
        this.urlBase64ToUint8Array(vapidPublicKey);
      return swreg.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: convertedVapidPublicKey,
      });
    },

    urlBase64ToUint8Array(base64String) {
      const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
      const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');
      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);
      for (let i = 0; i < rawData.length; i += 1) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    },

    showNotification() {
      this.swreg.showNotification('Notifications granted', {
        body: 'You have subscribed to push notifications from Nexta Scheduler',
        vibrate: [300, 200, 300],
      });
    },
  },
};
</script>
