<template>
  <v-dialog
    :value="isOpen"
    persistent
    width="500"
    @input="$emit('input', $event)"
  >
    <v-card v-if="isLoading">
      <v-card-title class="pt-1 pb-0 pr-5">
        <v-spacer />
        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-skeleton-loader type="article" />
    </v-card>
    <v-card v-else>
      <v-card-title class="pt-1 pb-0 pr-5">
        <v-spacer />
        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-title class="pt-1">
        <span class="headline">
          {{ details.subject }}
        </span>
        <v-spacer />
        <event-type-chip
          v-if="details.event_type_id != null"
          :id="details.event_type_id"
        />
      </v-card-title>
      <v-card-subtitle class="pb-0 pt-1">
        {{ meetingDateAndTime }}
      </v-card-subtitle>
      <v-card-text class="pb-0">
        <v-radio-group
          v-if="isCreatorAlsoParticipant"
          v-model="attendanceResponse"
          row
        >
          <v-radio :label="$t('meetingScheduler.going')" :value="1" />
          <v-radio :label="$t('meetingScheduler.notGoing')" :value="0" />
          <v-radio :label="$t('meetingScheduler.undecided')" :value="'null'" />
        </v-radio-group>
        <details-display-element
          v-if="details.description"
          :icon="'mdi-text'"
          :message="formatDescription(details.description)"
          :tooltip-text="$t('meetingScheduler.body')"
          :is-html-view="true"
        />
        <details-display-menu
          v-if="details.membersAndDecisions"
          icon="mdi-account-group"
          :details="details.membersAndDecisions"
          color="grey lighten-2"
          :tooltip-text="$t('meetingScheduler.members')"
        />
        <details-display-menu
          v-if="details.facilities && details.facilities.length !== 0"
          icon="mdi-seat"
          :details="details.facilities"
          color="orange lighten-4"
          :tooltip-text="$t('meetingScheduler.facilities')"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="attendanceResponse != originalAttendanceResponse"
          :disabled="isUpdatingAttendance"
          :loading="isUpdatingAttendance"
          color="primary"
          @click="updateAttendance"
        >
          {{ $t('common.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import EventTypeChip from '../EventTypeChip.vue';
import DetailsDisplayMenu from '../DetailsDisplayMenu.vue';
import DetailsDisplayElement from '../DetailsDisplayElement.vue';

import calendarAndBookings from '../../mixins/calendarAndBookings';
import meetingAttendanceResponse from '../../mixins/meetingAttendanceResponse';
import { updateAttendance } from '../../api/meetings.api';

export default {
  components: {
    EventTypeChip,
    DetailsDisplayMenu,
    DetailsDisplayElement,
  },

  mixins: [calendarAndBookings, meetingAttendanceResponse],

  data() {
    return {
      isOpen: false,
      attendanceResponse: null,
      originalAttendanceResponse: null,
      details: {},
      isLoading: false,
      isUpdatingAttendance: false,
    };
  },

  computed: {
    ...mapGetters(['id']),

    meetingDateAndTime() {
      const { start } = this.details;
      const { end } = this.details;
      if (start === undefined || end === undefined) {
        return '';
      }
      const date = App.helpers.getDateObject(start.slice(0, 10));
      return `${this.$d(date, 'allDayEvent')}, ${start.slice(11, 16)} ~
        ${end.slice(11, 16)}`;
    },

    isCreatorAlsoParticipant() {
      if (Object.keys(this.details).length === 0) {
        return false;
      }
      const user = this.details.membersAndDecisions.find(
        (member) => member.id === this.id,
      );
      if (user) {
        return true;
      }
      return false;
    },
  },

  methods: {
    open(meetingId) {
      this.isOpen = true;
      this.meetingId = meetingId;
      this.getMeetingDetails();
    },

    async getMeetingDetails() {
      this.isLoading = true;
      try {
        const { data } = await axios.get(`/api/meetings/${this.meetingId}`);
        this.details = data;
        this.getUserAttendance();
      } catch (error) {
        if (error.response.status === 404) {
          this.$emit('meeting-not-found');
        }
        this.closeDialog();
      }
      this.isLoading = false;
    },

    getUserAttendance() {
      if (!this.isCreatorAlsoParticipant) {
        return;
      }
      const userDecision = this.details.membersAndDecisions.find(
        (member) => member.id === this.id,
      );
      const attendingStatus = userDecision.attending_status;
      this.attendanceResponse =
        attendingStatus === null ? 'null' : attendingStatus;
      this.originalAttendanceResponse = this.attendanceResponse;
    },

    async updateAttendance() {
      this.isUpdatingAttendance = true;
      const decisionToSend = this.getMeetingAttendanceResponse(
        this.attendanceResponse,
      );
      try {
        await updateAttendance({ id: this.meetingId }, decisionToSend);
      } catch (e) {
        this.$store.dispatch('openSnackbar', {
          color: 'error',
          message: this.$t('common.somethingWentWrong'),
        });
        this.isUpdatingAttendance = false;
        return;
      }
      this.showSuccessSnackbar(decisionToSend);
      this.isUpdatingAttendance = false;
      this.closeDialog();
      this.$store.dispatch('updateIsRefreshRequired', true);
    },

    closeDialog() {
      this.isOpen = false;
      this.details = {};
    },
  },
};
</script>
