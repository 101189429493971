<template>
  <v-card-title>
    <span> {{ $t('notifications.allNotifications') }} </span>
    <v-spacer />
    <v-tooltip v-if="unreadNotifications > 0"  bottom>
      <template #activator="{ on }">
        <v-btn icon small class="mr-3" v-on="on" @click="$emit('click:mark-all-read')">
          <v-icon>mdi-check-all</v-icon>
        </v-btn>
      </template>
      <span> {{ $t('notifications.markAllAsRead') }} </span>
    </v-tooltip>
  </v-card-title>
</template>


<script>
export default {
  props: {
    notifications: {
      type: Array,
      required: true,
    },
  },

  computed: {
    unreadNotifications() {
      return this.notifications.filter(
        (notification) => notification.read_at == null,
      ).length;
    },
  }
};
</script>
