import '../globalAppObject';

const EVENT_DURATION_IN_MINS = 60;

const dateTimeHelpers = {
  methods: {
    getDefaultEndTimeAsPerUserPreference(startDateString) {
      const startTimestamp = App.helpers
        .getDateObject(startDateString)
        .getTime();
      const millisecondsToBeAdded = EVENT_DURATION_IN_MINS * 60 * 1000;
      const newTimestamp = startTimestamp + millisecondsToBeAdded;
      const newDateObject = new Date(newTimestamp);
      return this.formatToString(newDateObject);
    },
    formatToString(t) {
      const z = t.getTimezoneOffset() * 60 * 1000;
      let tLocal = t - z;
      tLocal = App.helpers.getDateObject(tLocal);
      let iso = tLocal.toISOString();
      iso = iso.slice(0, 16);
      iso = iso.replace('T', ' ');
      return iso;
    },
  },
};

export default dateTimeHelpers;
