import axios from 'axios';

const axiosCancelRequests = {
  data: () => ({
    requestsCancelTokens: [],
    cancelMessage: 'Only one of this axios request allowed at a time.',
    namedCancelTokens: {},
  }),
  methods: {
    cancelExistingRequests(name = null) {
      if (name) {
        this.cancelNamedRequest(name);
        return;
      }
      if (this.requestsCancelTokens.length === 0) {
        return;
      }
      this.requestsCancelTokens.forEach((axiosCall) => {
        axiosCall.cancel(this.cancelMessage);
      });
      this.requestsCancelTokens = [];
    },
    cancelNamedRequest(name) {
      if (!this.namedCancelTokens[name]) {
        return;
      }
      this.namedCancelTokens[name].cancel(this.cancelMessage);
      delete this.namedCancelTokens[name];
    },
    getCancelToken(name = null) {
      const axiosCall = axios.CancelToken.source();
      if (name) {
        this.namedCancelTokens[name] = axiosCall;
      } else {
        this.requestsCancelTokens.push(axiosCall);
      }
      return axiosCall.token;
    },
  },
};

export default axiosCancelRequests;
