const state = {
  isSendFeedbackModalVisible: false,
};

const getters = {
  isSendFeedbackModalVisible(state) {
    return state.isSendFeedbackModalVisible;
  },
};

const mutations = {
  showSendFeedbackModal(state) {
    state.isSendFeedbackModalVisible = true;
  },
  hideSendFeedbackModal(state) {
    state.isSendFeedbackModalVisible = false;
  },
};

export default {
  state,
  getters,
  mutations,
};
