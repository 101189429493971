var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-autocomplete',{attrs:{"value":_vm.value,"outlined":"","dense":"","multiple":"","item-text":"name","item-value":"id","prepend-icon":"mdi-seat","items":_vm.facilities,"label":_vm.$t('selection.facilitySelect'),"rules":[() => _vm.rules || _vm.$t('common.required')],"menu-props":{
    maxHeight: _vm.$vuetify.breakpoint.smAndDown ? 150 : 304,
    offsetY: true,
  }},on:{"change":function($event){return _vm.$emit('changed-facilities')},"input":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',{class:[
        'my-1',
        data.item.status == 'busy' ? 'red white--text' : 'grey lighten-2',
      ],attrs:{"small":"","close":""},on:{"click:close":function($event){return _vm.removeFacility(data.item.id)}}},[_vm._v(" "+_vm._s(data.item.name)+" ")])]}},{key:"item",fn:function(data){return [_c('v-list-item-content',[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(data.attrs.inputValue === true ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline')+" ")]),_vm._v(" "+_vm._s(data.item.name)+" ")],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }