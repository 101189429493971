import Vue from 'vue';
import VueGtag from 'vue-gtag';
import router from '../router';
import store from '../store/index';

Vue.use(
  VueGtag,
  {
    config: { id: 'UA-161810145-3' },
  },
  router,
);

function getSubdomain() {
  return window.location.host.split('.')[0];
}

const setUserInGoogleAnalytics = () => {
  const userId = getSubdomain() + store.getters.id;
  Vue.$gtag.set({
    'userId': userId,
    'dimension1': userId,
  });
};

// For variables' explanation, see:
// https://support.google.com/analytics/answer/1033068?hl=en#zippy=%2Cin-this-article

// Find events on:
// https://analytics.google.com/analytics/web/#/report/content-event-overview/a161810145w187455341p184470786/
const sendGoogleAnalyticsEvent = (
  eventAction,
  eventLabel = null,
  eventValue = null,
) =>
  Vue.$gtag.event(eventAction, {
    event_category: getSubdomain(),
    event_label: eventLabel,
    value: eventValue,
  });

// Find timings on:
// https://analytics.google.com/analytics/web/#/report/content-site-speed-user-timings/a161810145w187455341p184470786/
const sendGoogleAnalyticsTimings = (timingVar, timingValue) =>
  Vue.$gtag.time({
    name: timingVar,
    value: timingValue,
    event_category: getSubdomain(),
  });

export {
  setUserInGoogleAnalytics,
  sendGoogleAnalyticsEvent,
  sendGoogleAnalyticsTimings,
};
