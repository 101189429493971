import { mapGetters } from 'vuex';

const nationalHolidayDatePickerEvent = {
  computed: {
    ...mapGetters(['isNationalHoliday']),
  },
  methods: {
    getNationalHolidayEventColor(date) {
      if (this.isNationalHoliday(date)) {
        return 'red';
      }
      return '';
    },
  },
};

export default nationalHolidayDatePickerEvent;
