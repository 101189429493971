import axios from 'axios';

const state = {
  modalDisplay: {
    id: null,
    groupType: null,
    groupId: null,
    meetingId: null,
    start: null,
    end: null,
    all_day: null,
    eventTypeId: null,
    calendarId: null,
    place: null,
    subject: null,
    description: null,
    priority: null,
    createdBy: null,
    updatedBy: null,
  },
  meetingOrganizer: null,
  modalMembers: [],
  modalFacilities: [],
  facilitiesCancelSource: null,
  membersCancelSource: null,
  isLoadingMembers: false,
  isLoadingFacilities: false,
};

const getters = {
  modalDisplay: (state) => state.modalDisplay,
  meetingOrganizer: (state) => state.meetingOrganizer,
  modalMembers: (state) => state.modalMembers,
  modalFacilities: (state) => state.modalFacilities,
  isLoadingMembers: (state) => state.isLoadingMembers,
  isLoadingFacilities: (state) => state.isLoadingFacilities,
};

const mutations = {
  setModalDisplay: (state, payload) => {
    state.modalDisplay = { ...state.modalDisplay, ...payload };
  },
  setMeetingOrganizer: (state, payload) => {
    state.meetingOrganizer = payload;
  },
  updateModalMembers: (state, payload) => {
    state.modalMembers = payload;
  },
  updateModalFacilities: (state, payload) => {
    state.modalFacilities = payload;
  },
  setIsLoadingMembers: (state, payload) => {
    state.isLoadingMembers = payload;
  },
  setIsLoadingFacilities: (state, payload) => {
    state.isLoadingFacilities = payload;
  },
};

const actions = {
  setModalDisplay: ({ commit }, payload) => {
    commit('setModalDisplay', payload);
  },
  setMeetingOrganizer: ({ commit }, payload) => {
    commit('setMeetingOrganizer', payload);
  },
  async fetchAndSetModalFacilities(context, payload) {
    if (state.facilitiesCancelSource) {
      state.facilitiesCancelSource.cancel(
        'Only one of this axios request allowed at a time.',
      );
    }
    state.facilitiesCancelSource = axios.CancelToken.source();
    context.commit('setIsLoadingFacilities', true);
    const response = await axios.get(
      '/api/facility-booking/get-booking-extra-details/',
      {
        params: {
          groupType: payload.groupType,
          groupId: payload.groupId,
        },
        cancelToken: state.facilitiesCancelSource.token,
      },
    );
    context.commit('updateModalFacilities', response.data);
    context.commit('setIsLoadingFacilities', false);
  },
  async fetchAndSetModalMembers(context, payload) {
    if (state.membersCancelSource) {
      state.membersCancelSource.cancel(
        'Only one of this axios request allowed at a time.',
      );
    }
    state.membersCancelSource = axios.CancelToken.source();
    context.commit('setIsLoadingMembers', true);
    let members = [];
    if (payload != null) {
      const response = await axios.get(
        `/api/notifications/meeting-members-and-decision/${payload}`,
        {
          cancelToken: state.membersCancelSource.token,
        },
      );
      members = response.data;
    }
    context.commit('updateModalMembers', members);
    context.commit('setIsLoadingMembers', false);
  },
  resetMeetingOrganizerAndMembers(context) {
    context.commit('setMeetingOrganizer', null);
    context.commit('updateModalMembers', []);
  },
  resetFacilities(context) {
    context.commit('updateModalFacilities', []);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
