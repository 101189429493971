const dateTimeFormats = {
  en: {
    monthSelector: {
      month: 'long',
    },
    weekViewHeaderDay: {
      weekday: 'short',
    },
    weekViewHeaderWithMonth: {
      month: 'short',
      day: 'numeric',
    },
    allDayEvent: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
    },
    eventTime: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
    },
    yearMonth: {
      year: 'numeric',
      month: 'long',
    },
    timeOnly: {
      hour: 'numeric',
      minute: 'numeric',
    },
    currentDateTime: {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    },
  },
  ja: {
    monthSelector: {
      month: 'long',
    },
    weekViewHeaderDay: {
      weekday: 'short',
    },
    weekViewHeaderWithMonth: {
      month: 'short',
      day: 'numeric',
    },
    allDayEvent: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
    },
    eventTime: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    },
    yearMonth: {
      year: 'numeric',
      month: 'long',
    },
    timeOnly: {
      hour: 'numeric',
      minute: 'numeric',
    },
    currentDateTime: {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    },
  },
};

export default dateTimeFormats;
