import { differenceInMinutes, format, addMonths, addYears } from 'date-fns';

function getDefaultState() {
  return {
    eventStartEnd: null,
    eventMinutesDuration: null,
  };
}

const state = getDefaultState();

const getters = {
  recurringEventStart: (state) =>
    format(state.eventStartEnd.start, 'yyyy-MM-dd HH:mm:ss'),
  recurringEventMinutesDuration: (state) => state.eventMinutesDuration,
  isRecurrenceAllowed: (state) => state.eventMinutesDuration < 24 * 60,
  defaultIrregularRecurrence: () => ({ dates: [], groupIds: [] }),
  defaultRegularRecurrence: (state, getters) => ({
    freq: 'DAILY',
    until: getters.defaultUntilForFreq('DAILY'),
    count: null,
  }),
  defaultUntilForFreq: (state) => (freq) => {
    const date = state.eventStartEnd.start;
    const choices = {
      DAILY: format(addMonths(date, 1), 'yyyy-MM-dd'),
      WEEKLY: format(addMonths(date, 2), 'yyyy-MM-dd'),
      MONTHLY: format(addYears(date, 1), 'yyyy-MM-dd'),
      YEARLY: format(addYears(date, 5), 'yyyy-MM-dd'),
    };
    return choices[freq];
  },
  defaultCountForFreq: () => (freq) => {
    const choices = {
      DAILY: 30,
      WEEKLY: 8,
      MONTHLY: 12,
      YEARLY: 5,
    };
    return choices[freq];
  },
};

const mutations = {
  storeRecurringEventStartEnd(state, payload) {
    state.eventStartEnd = {
      start: payload.start,
      end: payload.end,
    };
  },
  storeRecurringEventMinutesDuration(state) {
    state.eventMinutesDuration = differenceInMinutes(
      state.eventStartEnd.end,
      state.eventStartEnd.start,
    );
  },
  resetRecurringDateSelector(state) {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  storeRecurringEventStartEnd(context, payload) {
    context.commit('storeRecurringEventStartEnd', payload);
    context.commit('storeRecurringEventMinutesDuration');
  },
  resetRecurringDateSelector(context) {
    context.commit('resetRecurringDateSelector');
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
