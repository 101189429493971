import { differenceInCalendarDays, addDays } from 'date-fns';
import { dragDropTask } from '../api/tasks.api';
import { dragDropRecurringEvent, dragDropEvent } from '../api/events.api';

const eventDragHandler = {
  methods: {
    removeEventFromExistingPosition(usersList, event, row, cell) {
      const events = usersList[row][cell + 1];
      const filteredEvents = events.filter((searchEvent) => {
        if (event.isTask) {
          if (searchEvent.isTask) {
            return !(event.id === searchEvent.id);
          }
          return true;
        }
        if (event.recurrence_id) {
          if (searchEvent.recurrence_id) {
            return !(
              event.recurrence_id === searchEvent.recurrence_id &&
              event.instance_index === searchEvent.instance_index
            );
          }
          return true;
        }
        return !(event.id === searchEvent.id);
      });
      usersList[row][cell + 1] = [...filteredEvents];
      return usersList;
    },

    injectEventIntoNewPosition(usersList, event, row, cell) {
      usersList[row][cell + 1].push(event);
      return usersList;
    },
    getToCalendar(info) {
      return info.type === 'user' ? info.defaultCalendar : info.calendars[0];
    },

    getNewEventDates(event, newStartDate) {
      const oldEventDurationInDays = differenceInCalendarDays(
        App.helpers.getDateObject(event.end),
        App.helpers.getDateObject(event.start),
      );
      let newEndDate = addDays(newStartDate, oldEventDurationInDays);
      newStartDate = App.helpers.getISODateString(newStartDate);
      newEndDate = App.helpers.getISODateString(newEndDate);
      return { startDate: newStartDate, endDate: newEndDate };
    },

    async handleDragTask(event, info, newDate) {
      const { type } = info;
      const toCalendar = this.getToCalendar(info);
      const fromCalendar = this.userData(event.user_id).default_calendar;

      if (type !== 'user') {
        await this.handleDragTaskToNonUser(toCalendar, fromCalendar);
      } else if (event.is_private && info.id !== this.id) {
        await this.handleDragPrivateTask(toCalendar, fromCalendar);
      } else {
        const { startDate, endDate } = this.getNewEventDates(event, newDate);
        const { data } = await dragDropTask(info, event, startDate, endDate);
        await this.updateCalendarsInUsersList(data.calendars);
      }
    },

    async handleDragTaskToNonUser(toCalendar, fromCalendar) {
      const color = 'error';
      const message = this.$t('common.cannotMoveTasks');
      this.$store.dispatch('openSnackbar', { color, message });
      await this.updateCalendarsInUsersList([toCalendar.id, fromCalendar.id]);
    },

    async handleDragPrivateTask(toCalendar, fromCalendar) {
      const color = 'error';
      const message = this.$t('common.cannotMovePrivateTasksToOthers');
      this.$store.dispatch('openSnackbar', { color, message });
      await this.updateCalendarsInUsersList([toCalendar.id, fromCalendar.id]);
    },

    async handleDragMeetingEvent(event, info) {
      const toCalendar = this.getToCalendar(info);
      const color = 'error';
      const message = this.$t('common.cannotMoveMeetings');
      this.$store.dispatch('openSnackbar', { color, message });
      await this.updateCalendarsInUsersList([toCalendar.id, event.calendar_id]);
    },

    async handleDragRecurringEvent(event, info, newStartDate) {
      const toCalendar = this.getToCalendar(info);
      const { startDate, endDate } = this.getNewEventDates(event, newStartDate);

      try {
        const { data } = await dragDropRecurringEvent(
          info,
          event,
          startDate,
          endDate,
        );
        await this.updateCalendarsInUsersList(data.calendars);
      } catch (error) {
        if (error.response.data && error.response.data.messageCode) {
          this.$store.dispatch('openSnackbar', {
            color: 'error',
            message: this.$t(`common.${error.response.data.messageCode}`),
          });
          await this.updateCalendarsInUsersList(error.response.data.calendars);
          return;
        }
        this.$store.dispatch('openSnackbar', {
          color: 'error',
          message: this.$t('event.cannotAdd'),
        });
        await this.updateCalendarsInUsersList([
          toCalendar.id,
          event.calendar_id,
        ]);
      }
    },

    async handleDragEvent(event, info, newStartDate) {
      const toCalendar = this.getToCalendar(info);
      const { startDate, endDate } = this.getNewEventDates(event, newStartDate);

      try {
        const { data } = await dragDropEvent(info, event, startDate, endDate);

        await this.updateCalendarsInUsersList(data.calendars);
      } catch (error) {
        if (error.response.data && error.response.data.messageCode) {
          this.$store.dispatch('openSnackbar', {
            color: 'error',
            message: this.$t(`common.${error.response.data.messageCode}`),
          });
          await this.updateCalendarsInUsersList(error.response.data.calendars);
          return;
        }
        this.$store.dispatch('openSnackbar', {
          color: 'error',
          message: this.$t('event.cannotAdd'),
        });
        await this.updateCalendarsInUsersList([
          toCalendar.id,
          event.calendar_id,
        ]);
      }
    },
  },
};

export default eventDragHandler;
