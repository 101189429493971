const departmentState = {
  selectedGroup: null,
  groups: [],
};

const getters = {
  selectedGroup: (state) => state.selectedGroup,
  starredGroups: (_, getters) => {
    const { groups } = getters;
    return groups.filter((group) => group.isStarred);
  },
  teams: (_, getters) => {
    const { groups } = getters;
    return groups.filter((group) => group.type === 'team');
  },
  departments: (_, getters) => {
    const { groups } = getters;
    return groups.filter((group) => group.type === 'department');
  },
  groups: (state) => state.groups,
  getGroupByTypeAndId: (_, getters) => (type, id) =>
    getters.groups.find((group) => group.type === type && group.id === id),
  getLoggedInUserDepartment: (_, getters, __, rootGetters) =>
    getters.groups.find(
      (group) =>
        group.type === 'department' && group.id === rootGetters.department,
    ),
};

const mutations = {
  setSelectedGroup: (state, payload) => {
    state.selectedGroup = payload;
  },
  SET_GROUPS: (state, payload) => {
    state.groups = payload;
  },
};

const actions = {
  setSelectedGroup: ({ commit }, payload) => {
    commit('setSelectedGroup', payload);
  },
  fetchGroups: async ({ commit }) => {
    const { data } = await axios.get('api/groups');
    commit('SET_GROUPS', data);
  },
  markGroupAsStarred: async ({ dispatch }, payload) => {
    await axios.post(`api/user/preferences/mark/${payload.type}`, {
      id: payload.id,
      starred: true,
    });
    dispatch('fetchGroups');
  },
  markGroupAsNotStarred: async ({ dispatch }, payload) => {
    await axios.post(`api/user/preferences/mark/${payload.type}`, {
      id: payload.id,
      starred: false,
    });
    dispatch('fetchGroups');
  },
  resetSelectedGroup({ dispatch, rootGetters }) {
    const { defaultGroup } = rootGetters;
    if (defaultGroup === null) {
      dispatch('setSelectedGroup', getters.getLoggedInUserDepartment);
      return;
    }
    dispatch('setSelectedGroup', defaultGroup);
  },
};

export default {
  namespaced: true,
  state: departmentState,
  getters,
  mutations,
  actions,
};
