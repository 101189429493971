<template>
  <v-app>
    <TheToolbar class="d-print-none" />
    <app-nav-drawer class="d-print-none" />
    <task-nav-drawer class="d-print-none" />
    <v-main>
      <v-divider v-if="$vuetify.breakpoint.smAndUp" />
      <router-view />
    </v-main>
    <snackbar />
    <ServiceWorkerComponent />
    <SendFeedbackModal />
    <add-users-reminder
      :is-reminder-shown="isReminderShown"
      @close="isReminderShown = false"
    />
  </v-app>
</template>

<script>
import TheToolbar from './components/Toolbar/TheToolbar.vue';
import AppNavDrawer from './components/AppNavDrawer.vue';
import TaskNavDrawer from './components/Toolbar/TaskNavDrawer.vue';
import Snackbar from './components/Snackbar.vue';
import ServiceWorkerComponent from './components/ServiceWorkerComponent.vue';
import SendFeedbackModal from './components/SendFeedbackModal.vue';
import AddUsersReminder from './components/AddUsersReminder.vue';
import appVersionHelpers from './mixins/appVersionHelpers';

export default {
  name: 'AppUi',
  components: {
    TheToolbar,
    AppNavDrawer,
    TaskNavDrawer,
    Snackbar,
    ServiceWorkerComponent,
    SendFeedbackModal,
    AddUsersReminder,
  },
  mixins: [appVersionHelpers],
  data: () => ({
    verified: false,
    redirectUrl: '',
    isReminderShown: false,
  }),
  created() {
    this.redirectUrl = this.$route.query.continue;
    this.displayReminderIfSingleUserTenant();
  },
  mounted() {
    if (this.isappRecentlyUpdated()) {
      this.showAppUpdatedSnackbar();
    }
    this.updateAppVersion();
  },
  methods: {
    async displayReminderIfSingleUserTenant() {
      if (Object.keys(this.$store.getters.normalizedUsers).length === 1) {
        this.isReminderShown = true;
      }
    },
  },
};
</script>
