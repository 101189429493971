const MeetingAttendanceResponses = {
  GOING: 'going',
  UNDECIDED: 'undecided',
  NOT_GOING: 'not_going',
};

const meetingAttendanceResponse = {
  methods: {
    getMeetingAttendanceResponse(response) {
      switch (response) {
        case 1:
          return MeetingAttendanceResponses.GOING;
        case 0:
          return MeetingAttendanceResponses.NOT_GOING;
        default:
          return MeetingAttendanceResponses.UNDECIDED;
      }
    },

    showSuccessSnackbar(response) {
      const message = this.getMessage(response);
      this.$store.dispatch('openSnackbar', {
        color: 'success',
        message,
      });
    },

    getMessage(response) {
      switch (response) {
        case MeetingAttendanceResponses.GOING:
          return this.$t('messages.successfullyMarkedAsGoing');
        case MeetingAttendanceResponses.NOT_GOING:
          return this.$t('messages.successfullyMarkedAsNotGoing');
        default:
          return this.$t('messages.successfullyMarkedAsUndecided');
      }
    },
  },
};

export default meetingAttendanceResponse;
