<template>
  <v-autocomplete
    v-model="selectedUsers"
    item-text="name"
    dense
    hide-details
    :search-input.sync="search"
    :items="usersChoices"
    :label="isOnTeamPage ? $t('team.addMembers') : $t('team.selectUsers')"
    :menu-props="{
      top: isOnTeamPage,
      offsetY: true,
    }"
    :multiple="true"
    :prepend-inner-icon="!isOnTeamPage ? 'mdi-account-multiple-plus' : ''"
    :prepend-icon="isOnTeamPage ? 'mdi-account-multiple-plus' : ''"
    :style="`width:${width};`"
    :outlined="!isOnTeamPage"
    :return-object="true"
    :filter="filterWithNameOrEmail"
  >
    <template #prepend-inner>
      <v-chip v-if="isOnTeamPage && !isEditing" class="mt-1" small>
        {{ userData(id).name }}
      </v-chip>
    </template>
    <template #selection="{ item }">
      <v-chip class="my-1" small close @click:close="removeUser(item)">
        {{ item.name }}
      </v-chip>
    </template>
    <template #item="data">
      <v-list-item-content>
        <v-list-item-title>
          <v-icon class="mr-2">
            {{
              data.attrs.inputValue === true
                ? 'mdi-checkbox-marked'
                : 'mdi-checkbox-blank-outline'
            }}
          </v-icon>
          {{ data.item.name }}
        </v-list-item-title>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { toAscii } from 'japanese-string-utils';
import { mapGetters } from 'vuex';

export default {
  props: {
    isEditing: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '100%',
    },
    isOnTeamPage: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    search: null,
  }),

  computed: {
    ...mapGetters({
      usersSelectorChoices: 'departmentUsersSelectorChoices',
      id: 'id',
      userData: 'userData',
      selectedUsersFromStore: 'selectedUsers',
    }),

    usersChoices() {
      if (this.isEditing) {
        return this.usersSelectorChoices;
      }
      return this.usersSelectorChoices.filter((user) => user.id !== this.id);
    },

    selectedUsers: {
      get() {
        return this.selectedUsersFromStore;
      },
      set(value) {
        this.search = '';
        this.$emit('user-selected', value);
        this.$store.dispatch('setSelectedUsersState', value);
      },
    },
  },

  methods: {
    setSelectionToEmpty() {
      if (this.selectedUsers != null) {
        this.selectedUsers = [];
      }
    },

    removeUser(removeUser) {
      this.selectedUsers = this.selectedUsersFromStore.filter(
        (user) => user.id !== removeUser.id,
      );
    },

    filterWithNameOrEmail(user, queryText) {
      if (!queryText) {
        return true;
      }
      if (!('id' in user)) {
        return false;
      }
      const queryTextHalfWidth = toAscii(queryText).toLowerCase();
      const userNameHalfWidth = toAscii(user.name).toLowerCase();
      const userEmail = user.email.toLowerCase();
      return (
        userNameHalfWidth.indexOf(queryTextHalfWidth) > -1 ||
        userEmail.indexOf(queryTextHalfWidth) > -1
      );
    },
  },
};
</script>
