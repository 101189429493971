<template>
  <v-menu
    :close-on-content-click="true"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template #activator="{ on }">
      <v-text-field
        v-model="textFieldDate"
        :style="icon !== '' ? `max-width: 120px` : `max-width: 90px`"
        :prepend-icon="icon"
        v-on="on"
        @input="inputDate"
        @change="formatDate"
      />
    </template>
    <v-date-picker
      v-model="pickerDate"
      no-title
      first-day-of-week="1"
      :locale="$i18n.locale"
      scrollable
      :events="getNationalHolidayEventColor"
    />
  </v-menu>
</template>

<script>
import { getDaysInMonth } from 'date-fns';
import nationalHolidayDatePickerEvent from '../../mixins/nationalHolidayDatePickerEvent';

export default {
  mixins: [nationalHolidayDatePickerEvent],
  props: {
    value: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    textFieldDate: null,
  }),
  computed: {
    pickerDate: {
      get() {
        const validity = this.checkDateValidityForPicker(this.textFieldDate);
        if (validity !== false) {
          return validity;
        }
        return this.value;
      },
      set(value) {
        this.formatDate(value);
      },
    },
  },
  watch: {
    value() {
      this.textFieldDate = this.value;
      this.pickerDate = this.value;
    },
  },
  created() {
    this.textFieldDate = this.value;
    this.pickerDate = this.value;
  },
  methods: {
    inputDate(date) {
      this.textFieldDate = this.checkAndUpdateDate(date);
    },
    checkAndUpdateDate(dateString) {
      const japNumbersRegex = /[\uFF10-\uFF19]/g;
      const japHyphenRegex = /ー/g;
      let japNumbersAndHypenReplacedString = dateString.replace(
        japNumbersRegex,
        (ch) => String.fromCharCode(ch.charCodeAt(0) - 65248),
      );
      japNumbersAndHypenReplacedString =
        japNumbersAndHypenReplacedString.replace(japHyphenRegex, (ch) => '-');
      return japNumbersAndHypenReplacedString;
    },
    formatDate(date) {
      this.textFieldDate = this.formatAndUpdateDate(date);
      this.$emit('input', this.textFieldDate);
      this.$emit('change', this.textFieldDate);
    },
    formatAndUpdateDate(dateString, defaultDate = null) {
      const notNumbersRegex = /[^0-9]/g;
      const numbersString = dateString.replace(notNumbersRegex, (ch) => '');
      let substituteDate = defaultDate;
      if (substituteDate === null || substituteDate.length !== 10) {
        substituteDate = App.helpers.getISODateString(new Date());
      }
      const year = this.generateYear(
        numbersString.slice(0, 4),
        substituteDate.slice(0, 4),
      );
      const month = this.generateMonth(
        numbersString.slice(4, 6),
        substituteDate.slice(5, 7),
      );
      let day = this.generateDay(
        numbersString.slice(6, 8),
        substituteDate.slice(8, 10),
      );
      const maxDayInMonth = getDaysInMonth(new Date(year, month - 1));
      day = day > maxDayInMonth ? maxDayInMonth : day;

      let date = month < 10 ? `${year}-0${month}` : `${year}-${month}`;
      date = day < 10 ? `${date}-0${day}` : `${date}-${day}`;
      return date;
    },
    generateYear(year, defaultYear) {
      return parseInt(`${defaultYear.slice(0, 4 - year.length)}${year}`, 10);
    },
    generateMonth(month, defaultMonth) {
      if (month.length === 0) {
        return parseInt(defaultMonth, 10);
      }
      let generatedMonth = parseInt(month, 10);
      generatedMonth = generatedMonth === 0 ? 1 : generatedMonth;
      generatedMonth = generatedMonth > 12 ? 12 : generatedMonth;
      return generatedMonth;
    },
    generateDay(day, defaultDay) {
      if (day.length === 0) {
        return parseInt(defaultDay, 10);
      }
      return parseInt(day, 10) === 0 ? 1 : parseInt(day, 10);
    },

    checkDateValidityForPicker(initialDate) {
      if (this.checkIfDateStringContainsInvalidCharacters(initialDate)) {
        return false;
      }
      const notNumbersRegex = /[^0-9]/g;
      const date = initialDate.replace(notNumbersRegex, (ch) => '');
      const { length } = date;
      const year = parseInt(date.slice(0, 4), 10);
      const month = parseInt(date.slice(4, 6), 10);
      const day = parseInt(date.slice(6, 8), 10);
      let dateToSend = '';
      if (length >= 5) {
        if (month > 0 && month <= 12) {
          dateToSend = month < 10 ? `${year}-0${month}` : `${year}-${month}`;
          if (length >= 7) {
            if (day > 0 && day <= getDaysInMonth(new Date(year, month - 1))) {
              return day < 10
                ? `${dateToSend}-0${day}`
                : `${dateToSend}-${day}`;
            }
          }
          return dateToSend;
        }
      }

      return false;
    },
    checkIfDateStringContainsInvalidCharacters(dateString) {
      const regex = /[0-9-/]/g;
      const characters = dateString.replace(regex, (ch) => '');
      return characters.length !== 0;
    },
  },
};
</script>
