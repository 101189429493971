<template>
  <div class="d-none d-print-block">
    <div class="text-h5">
      {{ formattedSelectedWeek }}
    </div>
    <div class="text-h6">
      {{ selectedGroup ? selectedGroup.name : '' }}
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns';
import { mapGetters } from 'vuex';

export default {
  props: {
    week: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapGetters('groups', ['selectedGroup']),

    formattedSelectedWeek() {
      const start = this.week[0];
      const end = this.week[this.week.length - 1];
      const startFormatted = format(start, 'yyyy/M/d');
      const endFormatted = format(end, 'yyyy/M/d');
      return `${startFormatted}~${endFormatted}`;
    },
  },
};
</script>
