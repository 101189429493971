<template>
  <div>
    <v-text-field
      v-model="queryText"
      :placeholder="$t('search.search')"
      class="expanding-search"
      :class="{ 'closed-search': isSearchClosed }"
      hide-details
      solo
      dense
      @blur="handleBlur()"
      @keydown.enter="getSearchResults()"
    >
      <template #prepend-inner>
        <v-btn small icon :ripple="false" @click="toggleSearchBarVisibility()">
          <v-icon>
            {{ isSearchClosed ? 'mdi-magnify' : 'mdi-close' }}
          </v-icon>
        </v-btn>
      </template>
    </v-text-field>
  </div>
</template>

<script>
import { sendGoogleAnalyticsEvent } from '../../plugins/vue-gtag';

export default {
  data() {
    return {
      isSearchClosed: true,
      queryText: '',
    };
  },
  watch: {
    $route(to) {
      if (to.meta.page === 'search-schedules') {
        this.isSearchClosed = false;
        this.queryText = this.$route.query.search;
        return;
      }
      this.collapseSearchBar();
    },
  },
  created() {
    if (
      this.$route.meta.page === 'search-schedules' &&
      this.$route.query.search
    ) {
      this.$emit('expand');
      this.isSearchClosed = false;
      this.queryText = this.$route.query.search;
      // return;
    }
  },
  methods: {
    handleBlur() {
      if (this.$route.meta.page === 'search-schedules') {
        return;
      }
      this.collapseSearchBar();
    },
    toggleSearchBarVisibility() {
      if (this.isSearchClosed) {
        this.expandSearchBar();
        return;
      }
      this.collapseSearchBar();
    },
    expandSearchBar() {
      this.isSearchClosed = false;
      this.$emit('expand');
    },
    collapseSearchBar() {
      this.isSearchClosed = true;
      this.queryText = '';
      this.$emit('collapse');
    },
    getSearchResults() {
      sendGoogleAnalyticsEvent('search', this.queryText);

      this.$router.push({
        name: 'search-schedules',
        query: { search: this.queryText },
      });
    },
  },
};
</script>

<style lang="scss">
.expanding-search {
  transition: max-width 0.2s;
  &.closed-search {
    max-width: 30px;
    .v-input__slot {
      box-shadow: none !important;
    }
  }
}
</style>
